import { Fragment } from 'react';
import {
  Box,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Card,
  Typography,
  styled,
  IconButton,
  ListItemButton,
  alpha,

} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';

import Scrollbar from 'src/components/Scrollbar';


import { useTranslation } from 'react-i18next';




const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;

    &:hover {
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.white[100]};
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        
    }
  `
);

function ScenariosListAdmin({ sliderMapValueHandler, scenarios, deleteScenario, setToUpdate }) {
  // const { i18n,t } = useTranslation();
  const { t } = useTranslation();



  const sliderHandler = (scenarioID) => {


    sliderMapValueHandler(scenarios.get(scenarioID).sliderIDs, scenarios.get(scenarioID).sliderValues, scenarioID)



  }

  const createScenariosList = () => {

    let scenariosList = []


    scenarios.forEach(function (value) {
      scenariosList.push(
        <Fragment key={value._id}>
          <Divider />
          <ListItemButtonWrapper

            sx={{
              display: { xs: 'block', sm: 'flex' },
              py: 1,
              px: 1.5
            }}
          >
            <ListItemAvatar
              sx={{
                minWidth: 'auto',
                mr: 1,
                mb: { xs: 1, sm: 0 }
              }} />
            <ListItemText
              disableTypography
              primary={<Typography color="text.primary" variant="h7">

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="left"
                >
                  {t(value["title"]["en"])}
                  {/* {t(
                          (Object.prototype.hasOwnProperty.call(value["title"],i18n.language)) ?
                            value["title"][i18n.language] :
                              value["title"]["en"]
                  )} */}
                </Box>

              </Typography>}
              secondary={<>
                <Typography noWrap variant="subtitle2">

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                  >                    {
                    value["category"]
                    }
                  </Box>

                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="left"
                >
                  <Typography>
                  { t(value["description"]["en"])}
                    {/* t(
                    (Object.prototype.hasOwnProperty.call(value["description"],i18n.language)) ?
                      value["description"][i18n.language] :
                        value["description"]["en"]
                    )} */}
                  </Typography>
                </Box>
              </>} />


            <IconButton color="primary" onClick={() => {
              sliderHandler(value._id);
              setToUpdate("true");
            }}>

              <LaunchTwoToneIcon fontSize="small" />
              <Typography variant="body2"> {t('Update')} </Typography>

            </IconButton>

            <IconButton color="error" onClick={() => {
              deleteScenario(value._id)
            }}>

              <DeleteTwoToneIcon fontSize="small" />
              <Typography variant="body2"> {t('Delete')} </Typography>

            </IconButton>


          </ListItemButtonWrapper>


        </Fragment>
      );
    })
    return scenariosList
  }

  return (
    <Card
      sx={{
        overflow: 'visible'
      }}
    >
      <Box
        sx={{
          textAlign: 'center'
        }}
        p={2}
      >
        <Typography
          variant="caption"
          fontWeight="bold"
        />
        {/* <Typography variant="h7">{t('Scenarios')}   </Typography> */}
        
        <Typography gutterBottom variant="h4">{t('Scenarios')}   </Typography>
        </Box> 
      <Box
        sx={{
          textAlign: 'center'
        }}
        p={1}
      >
        <Typography variant="h7"> {t('Scenario Explanation')} </Typography>
      </Box>
      {/*
       <div className="scrollable-div">
      */}
      <Box
        sx={{
          height: 565 // box length = chart length
        }}
      >
        <Scrollbar>

          <List disablePadding>

            {createScenariosList()}




            <Divider />


          </List>
        </Scrollbar>
      </Box>

      {/*
      </div>  
      */}
    </Card>
  );
}

export default ScenariosListAdmin;
