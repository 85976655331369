import React from 'react';

import {Button, Grid, FormControl, Select, MenuItem, Box, InputLabel, TextField} from "@mui/material"
import { useTranslation } from 'react-i18next';

const Demographic = ({ toNextTab, toPrevTab, formData, setFormData }) => {

  const selectCity = (c) => {
    setFormData({ ...formData, city: c });
  };

  const { t } = useTranslation();

  const allCities = [
    { country: 'greece', name: 'athens' },
    { country: 'greece', name: 'Other city or village' },
    { country: 'germany', name: 'berlin' },
    { country: 'germany', name: 'Other city or village' },
    { country: 'bulgaria', name: 'plovdiv' },
    { country: 'bulgaria', name: 'sofia' },
    { country: 'bulgaria', name: 'Other city or village' },
    { country: 'belgium', name: 'brussels' },
    { country: 'belgium', name: 'Other city or village' },
    // ... Other cities
  ];

  const filteredCities = allCities.filter((city) => city.country === formData.country);

  return (
    <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray" }} backgroundColor="white">
      <Grid container justifyContent="center" spacing={2}>
        
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          <h5>{t("Demographic Characteristics")}</h5>
        </Grid>
  
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          <h6>{t("By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.")}</h6>
        </Grid>

        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          {t("City Question")}
        </Grid>

          <FormControl fullWidth>
            <InputLabel id="selectLabel">{t("City Option")}</InputLabel>
            <Select
              labelId="selectLabel"
              defaultValue={''}
              value={formData.city}
              onChange={(e) => selectCity(e.target.value)}
              style={{ textAlign: 'center' }}
            >
              {filteredCities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {t(city.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
  
        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          {t("Age Question")}
        </Grid>
          <FormControl fullWidth>
            <InputLabel id="selectlabel">{t("Age Option")}</InputLabel>
            <Select
              labelId="selectLabel"
              defaultValue={''}
              value={formData.age}
              onChange={(e) => setFormData({ ...formData, age: e.target.value })}
              style={{ textAlign: 'center' }}
            >
              <MenuItem value={"Below 18"}> {t('Below 18')} </MenuItem>
              <MenuItem value={"18-24"}>18-24</MenuItem>
              <MenuItem value={"25-34"}>25-34</MenuItem>
              <MenuItem value={"35-44"}>35-44</MenuItem>
              <MenuItem value={"45-54"}>45-54</MenuItem>
              <MenuItem value={"55-65"}>55-65</MenuItem>
              <MenuItem value={"Above 65"}> {t('Above 65')} </MenuItem>
            </Select>
          </FormControl>
        </Grid>
  
        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          {t("Gender Question")}
        </Grid>
          <FormControl fullWidth>
            <InputLabel id="selectlabel">{t("Gender Option")}</InputLabel>
            <Select
              labelId="selectLabel"
              defaultValue={''}
              value={formData.gender}
              onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
              style={{ textAlign: 'center' }}
            >
              <MenuItem value={"Male"}>{t("Male")}</MenuItem>
              <MenuItem value={"Female"}>{t("Female")}</MenuItem>
              <MenuItem value={"Non-binary"}>{t("Non-binary")}</MenuItem>
              <MenuItem value={"Do not wish to specify"}>{t("Do not wish to specify")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
  
        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
        <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
          {t("Social Aid Question")}
        </Grid>
          <FormControl fullWidth>
            <InputLabel id="selectlabel">{t("Social Aid Option")}</InputLabel>
            <Select
              labelId="selectLabel"
              defaultValue={''}
              value={formData.socialAid}
              onChange={(e) => setFormData({ ...formData, socialAid: e.target.value })}
              style={{ textAlign: 'center' }}
            >
              <MenuItem value={"Yes"}>{t("Yes")}</MenuItem>
              <MenuItem value={"No"}>{t("No")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
  

          <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
              {t("Education Level Question")}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="selectlabel">{t("Education Level Option")}</InputLabel>
                <Select
                  labelId="selectLabel"
                  defaultValue={''}
                  value={formData.educationLevel}
                  onChange={(e) => setFormData({ ...formData, educationLevel: e.target.value })}
                  style={{ textAlign: 'center' }}
                >
                  <MenuItem value={"primary"}>{t("Primary Education")}</MenuItem>
                  <MenuItem value={"secondary"}>{t("Secondary Education")}</MenuItem>
                  <MenuItem value={"university"}>{t("University Education")}</MenuItem>
                  <MenuItem value={"postgraduate"}>{t("Postgraduate Education")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {formData.city === 'athens' && (
            <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                {t("Municipal Communities Question")}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="selectlabel">{t("Municipal Communities Option")}</InputLabel>
                  <Select
                    labelId="selectLabel"
                    defaultValue={''}
                    value={formData.municipalCommunity}
                    onChange={(e) => setFormData({ ...formData, municipalCommunity: e.target.value })}
                    style={{ textAlign: 'center' }}
                  >
                    <MenuItem value={"Πλάκα"}>Πλάκα</MenuItem>
                    <MenuItem value={"Κολωνάκι"}>Κολωνάκι</MenuItem>
                    <MenuItem value={"Σύνταγμα"}>Σύνταγμα</MenuItem>
                    <MenuItem value={"Ομόνοια"}>Ομόνοια</MenuItem>
                    <MenuItem value={"Μοναστηράκι"}>Μοναστηράκι</MenuItem>
                    <MenuItem value={"Εξάρχεια"}>Εξάρχεια</MenuItem>
                    <MenuItem value={"Ιλίσια"}>Ιλίσια</MenuItem>
                    <MenuItem value={"Νεάπολη"}>Νεάπολη</MenuItem>
                    <MenuItem value={"Μετς"}>Μετς</MenuItem>
                    <MenuItem value={"Νέος Κόσμος"}>Νέος Κόσμος</MenuItem>
                    <MenuItem value={"Άγιος Αρτέμιος"}>Άγιος Αρτέμιος</MenuItem>
                    <MenuItem value={"Παγκράτι"}>Παγκράτι</MenuItem>
                    <MenuItem value={"Βοτανικός"}>Βοτανικός</MenuItem>
                    <MenuItem value={"Μεταξουργείο"}>Μεταξουργείο</MenuItem>
                    <MenuItem value={"Γκάζι"}>Γκάζι</MenuItem>
                    <MenuItem value={"Πετράλωνα"}>Πετράλωνα</MenuItem>
                    <MenuItem value={"Ρουφ"}>Ρουφ</MenuItem>
                    <MenuItem value={"Θησείο"}>Θησείο</MenuItem>
                    <MenuItem value={"Ακαδημία Πλάτωνος"}>Ακαδημία Πλάτωνος</MenuItem>
                    <MenuItem value={"Κολοκυνθού"}>Κολοκυνθού</MenuItem>
                    <MenuItem value={"Κολωνός"}>Κολωνός</MenuItem>
                    <MenuItem value={"Σεπόλια"}>Σεπόλια</MenuItem>
                    <MenuItem value={"Άγιος Νικόλαος"}>Άγιος Νικόλαος</MenuItem>
                    <MenuItem value={"Πλατεία Αττικής"}>Πλατεία Αττικής</MenuItem>
                    <MenuItem value={"Ριζούπολη"}>Ριζούπολη</MenuItem>
                    <MenuItem value={"Πατήσια"}>Πατήσια</MenuItem>
                    <MenuItem value={"Άγιος Ελευθέριος"}>Άγιος Ελευθέριος</MenuItem>
                    <MenuItem value={"Πλατεία Κολιάτσου"}>Πλατεία Κολιάτσου</MenuItem>
                    <MenuItem value={"Πλατεία Βικτωρίας"}>Πλατεία Βικτωρίας</MenuItem>
                    <MenuItem value={"Σταθμός Λαρίσης"}>Σταθμός Λαρίσης</MenuItem>
                    <MenuItem value={"Πεδίον του Άρεως"}>Πεδίον του Άρεως</MenuItem>
                    <MenuItem value={"Πατήσια"}>Πατήσια</MenuItem>
                    <MenuItem value={"Κυψέλη"}>Κυψέλη</MenuItem>
                    <MenuItem value={"Πολύγωνο"}>Πολύγωνο</MenuItem>
                    <MenuItem value={"Γκύζη"}>Γκύζη</MenuItem>
                    <MenuItem value={"Νέα φιλοθέη"}>Νέα φιλοθέη</MenuItem>
                    <MenuItem value={"Αμπελόκηποι"}>Αμπελόκηποι</MenuItem>
                    <MenuItem value={"Ελληνορώσων"}>Ελληνορώσων</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

        
        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}> 
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("How many times per week on average do you use the metro?")}
          </Grid>
            <FormControl fullWidth>
              {/* <InputLabel id="selectlabel">{t("Age Option")}</InputLabel> */}
              <TextField
                    required
                    label={t("Required")}
                    defaultValue={0}
                    variant="outlined"
                    type="number"
                    value={formData.metroFrequency}
                    onChange={(e) => setFormData({ ...formData, metroFrequency: e.target.value })}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    fullWidth
                  />
            </FormControl>
        </Grid>
  
        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}> 
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("How many times per week on average do you use the bus?")}
          </Grid>
            <FormControl fullWidth>
              {/* <InputLabel id="selectlabel">{t("Age Option")}</InputLabel> */}
              <TextField
                    required
                    label={t("Required")}
                    defaultValue={0}
                    variant="outlined"
                    type="number"
                    value={formData.busFrequency}
                    onChange={(e) => setFormData({ ...formData, busFrequency: e.target.value })}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    fullWidth
                  />
            </FormControl>
        </Grid>


        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}> 
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("How many times per week on average do you use the tram?")}
          </Grid>
            <FormControl fullWidth>
              {/* <InputLabel id="selectlabel">{t("Age Option")}</InputLabel> */}
              <TextField
                    required
                    label={t("Required")}
                    defaultValue={0}
                    variant="outlined"
                    type="number"
                    value={formData.tramFrequency}
                    onChange={(e) => setFormData({ ...formData, tramFrequency: e.target.value })}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    fullWidth
                  />
            </FormControl>
        </Grid>


        <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}> 
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("How many times per week on average do you use a bicycle?")}
          </Grid>
            <FormControl fullWidth>
              {/* <InputLabel id="selectlabel">{t("Age Option")}</InputLabel> */}
              <TextField
                    required
                    label={t("Required")}
                    defaultValue={0}
                    variant="outlined"
                    type="number"
                    value={formData.bicycleFrequency}
                    onChange={(e) => setFormData({ ...formData, bicycleFrequency: e.target.value })}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    fullWidth
                  />
            </FormControl>
        </Grid>


      <Grid container >

      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toPrevTab(e)} fullWidth>
          {t("Previous")}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toNextTab(e)} fullWidth>
          {t("Next")}
        </Button>
      </Grid>


</Grid>
</Grid>


    </Box>
  );

};

export default Demographic;
