import React from "react";
import ActionAdder2 from "./ActionAdder2";
import SliderListAdmin from "./SliderListAdmin"
import { Grid } from "@mui/material"
import { useState, useEffect } from "react";
import axios from "axios"


const Actions = () => {


  const client = axios.create({
    // baseURL: "http://localhost:3002/co2apidev"
     baseURL: "https://co2.dev.wecompair.eu/co2apidev/"
  });

  // const tempSlidersMap = new Map()
  const [slidersMap, setSliders] = useState(new Map())
  const [disabledCategoryField, disableCategoryField] = useState(false)
  const [errorPercentages, setPercentageError] = useState([false, false, false, false])
  const [emptyFieldsError, setEmptyFieldsError] = useState(false)

  const [userCountry] = useState('Greece')
  const [toUpdate, setToUpdate] = useState('false');
  const [newActionTranslatableValues, setNewActionTranslatableValues] = useState(new Map())
  const [prefilledValues, setPrefilledValues] = useState(["", "", "", "", "", "", "", "", "", ""])
  const [translationLanguage, setTlanguage] = useState("en")
  const [clearButton, hideClearButton] = useState(true)
  const [actionToBeUpdatedID, setActionID] = useState("")

  /*
  const [action,setAction] = useState({
      "category": "",
      "name": {
        "en": ""
      },
      "titles": {},
      "value": "0",
      "countriesData": {
        userCountry: {
          "percentages": [],
          "contribution" : ""
        }
      },
  }  )
  */


  useEffect(() => {
    client.get("/actions")
      .then((response) => {
        response.data.forEach(function (slider) {
          setSliders(new Map(slidersMap.set(slider._id, slider)))
          // tempSlidersMap.set(slider.id,slider)
        },
          // setSliders(new Map(tempSlidersMap))
        );
      });
  }, []);





  const clearTranslatableValues = () => {
    setPrefilledValues([
      "",
      prefilledValues[1],
      prefilledValues[2],
      "",
      prefilledValues[4],
      "",
      prefilledValues[6],
      "",
      prefilledValues[8]
    ])
  }

  const changeTranslationLanguage = (newLanguage) => {

    if (newLanguage !== "en") {
      disableCategoryField(true)
    } else {
      disableCategoryField(false)
    }

    if(newActionTranslatableValues.has(newLanguage)){
      let data = newActionTranslatableValues.get(newLanguage)

      setPrefilledValues([
        data[0],
        prefilledValues[1],
        prefilledValues[2],
        data[1],
        prefilledValues[4],
        data[2],
        prefilledValues[6],
        data[3],
        prefilledValues[8]
      ])
    }else{
      console.log("no")
    }

    setTlanguage(newLanguage)

  }


  const presaveAction = () => {

    if (stringIsEmpty(prefilledValues[0]) ||
      stringIsEmpty(prefilledValues[3]) ||
      stringIsEmpty(prefilledValues[5]) ||
      stringIsEmpty(prefilledValues[7])
    )
    // empty translation fields
    {

      setEmptyFieldsError(true)

    } else
    // correct input, saves data - handles fields - changes to english
    {
      let translatables = []
      translatables.push(prefilledValues[0], prefilledValues[3], prefilledValues[5], prefilledValues[7])

      setNewActionTranslatableValues(newActionTranslatableValues.set(translationLanguage, translatables))
      console.log(newActionTranslatableValues)
    }

  


}


const clearPrefilledValues = () => {
  setPrefilledValues(["", "", "", "", "", "", "", "", "", ""])
}
const sliderValueHandler = (newState, sliderID) => {

  let tempSliderObject = slidersMap.get(sliderID)
  tempSliderObject.value = newState

  setSliders(new Map(slidersMap.set(sliderID, tempSliderObject)))

}


const updateAction = () => {

 
  setEmptyFieldsError(false)

    presaveAction()

  let template = {
    "category": {},
    "name": {},
    "titles": {},
    "value": "0",
    "countriesData": {},
  }


  newActionTranslatableValues.forEach(function (translatables, language) {
    let titles = []
    titles.push("")
    titles.push(translatables[1])
    titles.push(translatables[2])
    titles.push(translatables[3])
    template["titles"][language] = titles
    template["name"][language] = translatables[0]
  })

  template["category"] = prefilledValues[1]

  let percentages = []
  percentages.push("")
  percentages.push(prefilledValues[4])
  percentages.push(prefilledValues[6])
  percentages.push(prefilledValues[8])

  template["countriesData"][userCountry] = {}
  template["countriesData"][userCountry]["percentages"] = percentages

  template["countriesData"][userCountry]["contribution"] = prefilledValues[2]

  console.log(template["category"])
  console.log(template["titles"])
  console.log(template["name"])
  console.log(template["value"])
  console.log(template["countriesData"])
  client.patch("/actions/"+actionToBeUpdatedID, {
    category: template["category"],
    titles: template["titles"],
    name: template["name"],
    value: template["value"],
    countriesData: template["countriesData"]
  })

  /*
      setSliders(new Map(slidersMap.set(currentValues[9], {
        id: currentValues[9],
        category: currentValues[1],
        name: currentValues[0],
        titles: ["", currentValues[3], currentValues[5], currentValues[7]],
        value: 0,
        contribution: currentValues[2],
        percentages: ["", currentValues[4], currentValues[6], currentValues[8]]
      })))
      */

  reset()

}

const switchLanguage = (newLanguage) => {

  // to get out of english, fields must be correct
  if (translationLanguage === 'en') {

    if (!checkPercentages() ||
      stringIsEmpty(prefilledValues[0]) ||
      stringIsEmpty(prefilledValues[1]) ||
      stringIsEmpty(prefilledValues[2]) ||
      stringIsEmpty(prefilledValues[3]) ||
      stringIsEmpty(prefilledValues[4]) ||
      stringIsEmpty(prefilledValues[5]) ||
      stringIsEmpty(prefilledValues[6]) ||
      stringIsEmpty(prefilledValues[7]) ||
      stringIsEmpty(prefilledValues[8])
    )
    // wrong input raises errors
    {

      setEmptyFieldsError(true)

    } else
    // correct input, saves data - handles fields 
    {
      presaveAction()
      clearTranslatableValues()
      changeTranslationLanguage(newLanguage)
    }

  }

  // swithcing between other languages behaviour
  if (translationLanguage !== "en") {

    changeTranslationLanguage(newLanguage)
    disableCategoryField(false)
    setEmptyFieldsError(false)

  }
}

const reset = () => {
    changeTranslationLanguage("en")

  clearPrefilledValues()
  setNewActionTranslatableValues(new Map())
  disableCategoryField(false)
  setPercentageError([false, false, false, false])
  setEmptyFieldsError(false)
  hideClearButton(true)
}

const checkPercentages = () => {
  return errorPercentages.every(error => error === false)
}

const stringIsEmpty = (field) => {
  return field.length === 0
}

const addAction = () => {

  if (!checkPercentages() ||
    stringIsEmpty(prefilledValues[0]) ||
    stringIsEmpty(prefilledValues[1]) ||
    stringIsEmpty(prefilledValues[2]) ||
    stringIsEmpty(prefilledValues[3]) ||
    stringIsEmpty(prefilledValues[4]) ||
    stringIsEmpty(prefilledValues[5]) ||
    stringIsEmpty(prefilledValues[6]) ||
    stringIsEmpty(prefilledValues[7]) ||
    stringIsEmpty(prefilledValues[8])) {
    setEmptyFieldsError(true)
  } else {

    setEmptyFieldsError(false)

    if (newActionTranslatableValues.size === 0) {
      presaveAction()
    }
    let template = {
      "category": {},
      "name": {},
      "titles": {},
      "value": "0",
      "countriesData": {},
    }


    newActionTranslatableValues.forEach(function (translatables, language) {
      let titles = []
      titles.push("")
      titles.push(translatables[1])
      titles.push(translatables[2])
      titles.push(translatables[3])
      template["titles"][language] = titles
      template["name"][language] = translatables[0]
    })

    template["category"] = prefilledValues[1]

    let percentages = []
    percentages.push("")
    percentages.push(prefilledValues[4])
    percentages.push(prefilledValues[6])
    percentages.push(prefilledValues[8])

    template["countriesData"][userCountry] = {}
    template["countriesData"][userCountry]["percentages"] = percentages

    template["countriesData"][userCountry]["contribution"] = prefilledValues[2]

    client.post("/actions", {
      category: template["category"],
      titles: template["titles"],
      name: template["name"],
      value: template["value"],
      countriesData: template["countriesData"]
    })


    console.log(template)

    reset()

  }

}

/*
    let currentValues = [...prefilledValues]
 
    client.post("/co2api/actions",{
      category: currentValues[1],
      name: currentValues[0],
      titles: ["", currentValues[3], currentValues[5], currentValues[7]],
      value: 0,
      contribution: currentValues[2],
      percentages: ["", currentValues[4], currentValues[6], currentValues[8]]
    })
      .then((response) => {
          // response.data.forEach(function (slider) {
          // setSliders(new Map(slidersMap.set(slider.id,slider)))
          // tempSlidersMap.set(slider.id,slider)
          console.log(response)
        },
        // setSliders(new Map(tempSlidersMap))
        );
 
        client.get("/co2api/actions")
      .then((response) => {
         response.data.forEach(function (slider) {
          setSliders(new Map(slidersMap.set(slider._id,slider)))
          // tempSlidersMap.set(slider.id,slider)
        },
        // setSliders(new Map(tempSlidersMap))
        );
      });
 
    /*
    setSliders(new Map(slidersMap.set(currentValues[9], {
      id: currentValues[9],
      category: currentValues[1],
      name: currentValues[0],
      titles: ["", currentValues[3], currentValues[5], currentValues[7]],
      value: 0,
      contribution: currentValues[2],
      percentages: ["", currentValues[4], currentValues[6], currentValues[8]]
    })))
    */



const deleteAction = (actionID) => {

  client.delete("/actions/" + actionID)
    .then((response) => {
      // response.data.forEach(function (slider) {
      // setSliders(new Map(slidersMap.set(slider.id,slider)))
      // tempSlidersMap.set(slider.id,slider)
      console.log("!!!!", response.message)
    },
      // setSliders(new Map(tempSlidersMap))
    );

  client.get("/actions")
    .then((response) => {
      response.data.forEach(function (slider) {
        setSliders(new Map(slidersMap.set(slider._id, slider)))
        // tempSlidersMap.set(slider.id,slider)
      },
        // setSliders(new Map(tempSlidersMap))
      );
    });


  let temp = new Map(slidersMap)

  temp.delete(actionID)

  setSliders(new Map(temp))

}

const handlePrefilledValues = (text, index) => {
  let currentValues = [...prefilledValues]
  currentValues[index] = text
  setPrefilledValues(currentValues)
}



const loadPrefilledValues = (actionID) => {

  let currentAction = slidersMap.get(actionID)
  setActionID(actionID)
  

  let translations = Object.keys(currentAction["titles"])

  translations.forEach(function(language){

    let translatables = []
    translatables.push(currentAction["name"][language], currentAction["titles"][language][1],currentAction["titles"][language][2],currentAction["titles"][language][3])
    setNewActionTranslatableValues(newActionTranslatableValues.set(language, translatables))
  })

  let tempprefilledValues = [...prefilledValues]
  tempprefilledValues[1] = currentAction["category"]
  tempprefilledValues[0] = currentAction["name"][translationLanguage]
  tempprefilledValues[3] = currentAction["titles"][translationLanguage][1]
  tempprefilledValues[5] = currentAction["titles"][translationLanguage][2]
  tempprefilledValues[7] = currentAction["titles"][translationLanguage][3]
  tempprefilledValues[2] = currentAction["countriesData"][userCountry]["contribution"]
  tempprefilledValues[4] = currentAction["countriesData"][userCountry]["percentages"][1]
  tempprefilledValues[6] = currentAction["countriesData"][userCountry]["percentages"][2]
  tempprefilledValues[8] = currentAction["countriesData"][userCountry]["percentages"][3]
  setPrefilledValues(tempprefilledValues)

}




return (
  <>
    <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4}>

      <Grid item xs={10} md={8} lg={5.5} >

        <SliderListAdmin
          sliders={slidersMap}
          sliderValueHandler={sliderValueHandler}
          updateAction={updateAction}
          deleteAction={deleteAction}
          toUpdate={toUpdate}
          setToUpdate={setToUpdate}
          loadPrefilledValues={loadPrefilledValues}
        />

      </Grid>
      <Grid item xs={10} md={8} lg={5.5} >

        <ActionAdder2
        reset={reset}
      clearButton={clearButton}
      hideClearButton={hideClearButton}
          switchLanguage={switchLanguage}
          emptyFieldsError={emptyFieldsError}
          errorPercentages={errorPercentages}
          setPercentageError={setPercentageError}
          disabledCategoryField={disabledCategoryField}
          translationLanguage={translationLanguage}
          presaveAction={presaveAction}
          clearPrefilledValues={clearPrefilledValues}
          addAction={addAction}
          prefilledValues={prefilledValues}
          handlePrefilledValues={handlePrefilledValues}
          toUpdate={toUpdate}
          setToUpdate={setToUpdate}
          updateAction={updateAction}
          deleteAction={deleteAction}
        />

      </Grid>
    </Grid>
  </>
)
}

export default Actions;
