import {
    Card, List, Divider, Grid, Typography, Box, IconButton, TextField, Button, FormControl, InputLabel, Select, MenuItem,


} from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';

import { Fragment } from 'react';
import { useState } from "react";
import axios from 'axios'

// import { useTranslation } from 'react-i18next';
import enJSON from 'src/i18n/translations/en';
import grJSON from 'src/i18n/translations/gr';



const Translation = () => {

    // const { i18n } = useTranslation();

    // const currentUserLanguage = i18n.language;
    
    const translations= {
    "English" : enJSON,
    "Greek" : grJSON
  };


    const [translationLanguage, setTlanguage] = useState("English")

    
    const client = axios.create({
        // baseURL: "http://localhost:3002/co2apidev/"
        baseURL: "https://co2.dev.wecompair.eu/co2apidev/"
    });

    /*

    useEffect(() => {

        client.get("/translations/English")
            .then((response) => {
                setEN(response.data)
            });
            client.get("/translations/English") // default second language 
            .then((response) => {
                setTranslation(response.data)
            });

    }, []);
    */


    const changeLanguage = (newLanguage) => {

        setTlanguage(newLanguage)
        /*
        client.get("/translations/" + newLanguage)
            .then((response) => {
                setTlanguage(newLanguage)
                setTranslation(response.data)
            });
            */

    }




    const [update, setUpdate] = useState("false")
    const [text, setText] = useState("")
    const [currentLabel, setCurrentLabel] = useState("")


    const save = () => {

        translations[translationLanguage][currentLabel] = text

        client.patch("/translations/" + translationLanguage, {
            label: currentLabel,
            text: text
        }).then(() => {
            
            /*
            i18n.reloadResources()
            client.get("/translations/" + translationLanguage)
                .then((response) => {
                    setTranslation(response.data)
                });
                */
        });

    }





    const loadTranslation = () => {


        let actionsList = []

        Object.keys(translations["English"]).forEach(function (label) {
            
            actionsList.push(

                <Fragment key={label} >

                    <Grid item xs={3.5} zeroMinWidth>
                        <Typography textAlign="right">{translations["English"][label]}</Typography>
                    </Grid>
                    <Grid item xs={3.5} zeroMinWidth >
                        <Typography textAlign="center">{label}</Typography>
                    </Grid>
                    <Grid item xs={1.5} zeroMinWidth>
                        <Box display="flex"
                            alignItems="center"
                            justifyContent="center">

                            <IconButton color="primary" onClick={() => {
                                setUpdate("true")
                                setText(translations[translationLanguage][label])
                                setCurrentLabel(label)
                            }}>

                                <LaunchTwoToneIcon fontSize="small" />
                                <Typography variant="body2">Update</Typography>


                            </IconButton>
                        </Box>


                    </Grid>
                    <Divider />
                    <Grid item xs={3.5}>
                        <Typography>{translations[translationLanguage][label]}</Typography>
                    </Grid>
                    <Divider />
                    <Grid item xs={12}>
                        <Box>
                            <Divider />
                        </Box>
                    </Grid>




                </Fragment>
            )
        });



        return actionsList
    }






    return (
        <>
            <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4}>

                <Grid item xs={10} md={8} lg={8} >
                    {update === 'true' && (
                        <>
                            <Card
                                sx={{
                                    overflow: 'visible'
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={0.5} label="filler" />
                                    <Grid item xs={9}>
                                        <TextField id="standard-basic"
                                            label="Translation"
                                            variant="standard"
                                            value={text}
                                            onChange={(e) => { setText(e.target.value) }}
                                            multiline
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={2.2} zeroMinWidth>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            onClick={() => {
                                                setText()
                                                setUpdate("false")
                                                setCurrentLabel("")
                                            }}
                                        >
                                            cancel
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            onClick={() => {
                                                setUpdate("false")
                                                save()
                                            }}
                                        >
                                            update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </>
                    )}


                    <Card
                        sx={{
                            overflow: 'visible'
                        }}
                    >

                        <List disablePadding>
                            <Grid container >
                                <Grid item xs={4} zeroMinWidth>
                                    <Typography noWrap variant="h4" textAlign="center">English</Typography>
                                </Grid>
                                <Grid item xs={2.5} zeroMinWidth>
                                    <Typography noWrap variant="h4" textAlign="center">Label</Typography>
                                </Grid>
                                <Grid item xs={2.5} marginLeft={20}>
                                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Select Language</InputLabel>
                                        <Select
                                            label="Translation selector"
                                            variant="standard"
                                            name="category"
                                            value={translationLanguage}
                                            onChange={(e) => {
                                                changeLanguage(e.target.value)
                                                loadTranslation()
                                            }}
                                        >
                                            <MenuItem value={"English"}>English</MenuItem>
                                            <MenuItem value={"Greek"}>Greek</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Divider />
                                <Grid item xs={12}>
                                    <Box>
                                        <Divider />
                                    </Box>
                                </Grid>


                                {loadTranslation()}



                            </Grid>
                        </List>

                    </Card>
                </Grid>
            </Grid>

        </>
    );

}

export default Translation;
