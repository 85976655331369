import { useState, useEffect, useRef } from "react";
import UserScenariosList from './UserScenariosList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faEarthAmericas,
  faCity
} from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import axios from "axios";
import Results from './Results';
import {
  Box,
  FormControl,
  InputLabel,
  Select, 
  MenuItem,   
  Button,  
  Modal,   
} from "@mui/material"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { grey } from "@mui/material/colors";
import jwtDecode  from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
// import {ReactPDF, Document} from '@react-pdf/renderer';
import generatePDF from 'react-to-pdf';

const Account = () => {

  const [userCalculation, setUserCalculation] = useState([])

  useEffect(() => {
    setUserCalculation({
      date: " ",
      country : " ",
      startDate: " ",
      endDate: " ",
      city: " ",
      age: " ",
      gender: " ",
      maritalStatus: " ",
      carDistance: " ",
      carLitersPerKm: " ",
      carFuelType: " ",
      usePublicTransport: " ",
      useElectricCar: " ",
      domesticFlights: " ",
      shortFlights: " ",
      mediumFlights: " ",
      longFlights: " ",
      trainTrips: " ",
      trainAvgDistance: " ",
      buildingFuel: " ",
      buildingFuelType: " ",
      buildingElectricity: " ",
      buildingUseSolarThermal: " ",
      buildingUseHeatPump: " ",
      buildingRefrigerator: " ",
      buildingWashingMachine: " ",
      buildingDishwasher: " ",
      buildingOven: " ",
      wasteRecycling: " ",
      wasteComposting: " ",
      transportationResults: 0,
      flightResults: 0,
      trainResults: 0,
      buildingResults: 0,
      wasteResults: 0,
      transportationCountryAverage: 0,
      flightCountryAverage: 0,
      trainCountryAverage: 0,
      buildingCountryAverage: 0,
      wasteCountryAverage: 0,
      transportationEUAverage: 0,
      flightEUAverage: 0,
      trainEUAverage: 0,
      buildingEUAverage: 0,
      wasteEUAverage: 0
    });
}, []);

const [formData, setFormData] = useState(null);

// eslint-disable-line
// const [formData, setFormData] = useState({
  useEffect(() => {
    // This effect will run whenever userCalculation changes
    const updatedFormData = updateFormData();
    setFormData(updatedFormData);
    // updateFormData();
  }, [userCalculation]);

  const updateFormData = () =>{
    let formData1 = {
      // welcome fields
      'country': userCalculation.country,
      'startDate': userCalculation.startDate,
      'endDate': userCalculation.endDate,
    
      // demographic fields
      'city': userCalculation.city,
      'age': userCalculation.age,
      'gender': userCalculation.gender,
      'marital': userCalculation.marital,
    
      // new car fields
      'car_distance': userCalculation.carDistance, 
      'liters': userCalculation.carLitersPerKm, 
      'fuel': userCalculation.carFuelType, 
      'public': userCalculation.usePublicTransport,
    
      // flights fields
      'domestic': userCalculation.domesticFlights,
      'short': userCalculation.shortFlights,
      'medium': userCalculation.mediumFlights,
      'long': userCalculation.longFlights,
    
      // trains fields
      'trips': userCalculation.trainTrips,
      'train_distance': userCalculation.trainAvgDistance,
    
      // buildings fields
      'building_fuel': userCalculation.buildingFuel,
      'building_fuel_type': userCalculation.buildingFuelType,
      'building_elec': userCalculation.buildingElectricity,
      'grid': " ",
      'solar': userCalculation.buildingUseSolarThermal,
      'pump': userCalculation.buildingUseHeatPump,
      'buildingRefrigerator': userCalculation.buildingRefrigerator,
      'buildingWashingMachine': userCalculation.buildingWashingMachine,
      'buildingDishwasher': userCalculation.buildingDishwasher,
      'buildingOven': userCalculation.buildingOven,
    
      // waste fields
      'recycling': userCalculation.wasteRecycling,
      'composting': userCalculation.wasteComposting,

      // results
      'transportationResults': userCalculation.transportationResults,
      'flightResults': userCalculation.flightResults,
      'trainResults': userCalculation.trainResults,
      'buildingResults': userCalculation.buildingResults,
      'wasteResults': userCalculation.wasteResults
    // }); 
    };

    return formData1;
  }

  // const headers = [
  //   {label: 'Title', key: 'title'},
  //   {label: 'Value', key: 'value'}
  // ];

  // const data = [
  //   {
  //     title: 'Vehicle Kilometers / Year',
  //     value: userCalculation.carDistance
  //   },
  //   {
  //     title: 'Vehicle Liters / 100Km',
  //     value: userCalculation.carLitersPerKm
  //   },
  //   {
  //     title: 'Vehicle Type of Fuel',
  //     value: userCalculation.carFuelType
  //   },
  //   // {
  //   //   title: 'Use of Public Transport',
  //   //   value: userCalculation.usePublicTransport
  //   // },
  //   {
  //     title: 'Electric Vehicle',
  //     value: userCalculation.useElectricCar
  //   },
  //   {
  //     title: 'Number of Domestic Flights',
  //     value: userCalculation.domesticFlights
  //   },
  //   {
  //     title: 'Number of Short Haul Flights',
  //     value: userCalculation.shortFlights
  //   },
  //   {
  //     title: 'Number of Medium Haul Flights',
  //     value: userCalculation.mediumFlights
  //   },
  //   {
  //     title: 'Number of Long Haul Haul Flights',
  //     value: userCalculation.longFlights
  //   },
  //   {
  //     title: 'Number of Train Trips',
  //     value: userCalculation.trainTrips
  //   },
  //   {
  //     title: 'Average Distance of Train Trip',
  //     value: userCalculation.trainAvgDistance
  //   },
  //   {
  //     title: 'Heating Fuel per Year',
  //     value: userCalculation.buildingFuel
  //   },
  //   {
  //     title: 'Type of Heating Fuel',
  //     value: userCalculation.buildingFuelType
  //   },
  //   {
  //     title: 'Building Electricity (kWh per Year)',
  //     value: userCalculation.buildingElectricity
  //   },
  //   {
  //     title: 'Use of Solar Thermal',
  //     value: userCalculation.buildingUseSolarThermal
  //   },
  //   {
  //     title: 'Use of Heat Pump',
  //     value: userCalculation.buildingUseHeatPump
  //   } ,
  //   {
  //     title: 'Energy Efficiency of Regrigerator',
  //     value: userCalculation.buildingRefrigerator
  //   },
  //   {
  //     title: 'Energy Efficiency of Washing Machine',
  //     value: userCalculation.buildingWashingMachine
  //   },
  //   {
  //     title: 'Energy Efficiency of Dishwasher',
  //     value: userCalculation.buildingDishwasher
  //   },
  //   {
  //     title: 'Energy Efficiency of Oven',
  //     value: userCalculation.buildingOven
  //   },
  //   {
  //     title: 'Recycling',
  //     value: userCalculation.wasteRecycling
  //   },
  //   {
  //     title: 'Composting',
  //     value: userCalculation.wasteComposting
  //   },
  //   {
  //     title: 'Transportation Retults',
  //     value: userCalculation.transportationResults
  //   },
  //   {
  //     title: 'Flights Retults',
  //     value: userCalculation.flightResults
  //   },
  //   {
  //     title: 'Trains Retults',
  //     value: userCalculation.trainResults
  //   },
  //   {
  //     title: 'Buildings Retults',
  //     value: userCalculation.buildingResults
  //   },
  //   {
  //     title: 'Waste Management Retults',
  //     value: userCalculation.wasteResults
  //   },
  //   {
  //     title: 'Country Transportation Average',
  //     value: userCalculation.transportationCountryAverage
  //   },
  //   {
  //     title: 'Country Flights Average',
  //     value: userCalculation.flightCountryAverage
  //   },
  //   {
  //     title: 'Country Trains Average',
  //     value: userCalculation.trainCountryAverage
  //   },
  //   {
  //     title: 'Country Buildings Average',
  //     value: userCalculation.buildingCountryAverage
  //   },
  //   {
  //     title: 'Country Waste Management Average',
  //     value: userCalculation.wasteCountryAverage
  //   },

  //   {
  //     title: 'EU Transportation Average',
  //     value: userCalculation.transportationEUAverage
  //   },

  //   {
  //     title: 'EU Flights Average',
  //     value: userCalculation.flightEUAverage
  //   },

  //   {
  //     title: 'EU Trains Average',
  //     value: userCalculation.trainEUAverage
  //   },
  //   {
  //     title: 'EU Buildings Average',
  //     value: userCalculation.buildingEUAverage
  //   },
  //   {
  //     title: 'EU Waste Management Average',
  //     value: userCalculation.wasteEUAverage
  //   }

  // ];

  const transformDataForCSV = () => {
    const csvData = [];

    const excludedKeys = [
      "_id",
      "__v",
      "userID",
      "country",
      "city",
      "age",
      "gender",
      "socialAid",
      "educationLevel",
      "municipalCommunity",
      "usePublicTransport",
      "transportationCountryAverage",
      "flightCountryAverage",
      "trainCountryAverage",
      "buildingCountryAverage",
      "wasteCountryAverage",
      "transportationEUAverage",
      "flightEUAverage",
      "trainEUAverage",
      "buildingEUAverage",
      "wasteEUAverage"
      // Add more keys as needed
  ];

  const titleMappings = {
    startDate: "Start Date",
    endDate: "End Date",
    carDistance: "Car Distance (km)",
    carLitersPerKm: "Vehicle Liters/100km or kWh/100km:",
    carFuelType: "Vehicle Fuel Type",
    domesticFlights: "Domestic Flights",
    shortFlights: "Short Flights",
    mediumFlights: "Medium Flights",
    longFlights: "Long Flights",
    trainTrips: "Number of Train Trips",
    trainAvgDistance: "Average Train Trip Distance (km)",
    buildingFuel: "Building Heating Fuel Consumption",
    buildingFuelType: "Building Heating Fuel Type",
    buildingElectricity: "Building Electricity Consumption",
    buildingUseSolarThermal: "Use of Solar for Water Heating",
    buildingUseHeatPump: "Use of Heat Pump",
    buildingRefrigerator: "Energy Efficiency of Regrigerator",
    buildingWashingMachine: "Energy Efficiency of Washing Machine",
    buildingDishwasher: "Energy Efficiency of Dishwasher",
    buildingOven: "Energy Efficiency of Oven",
    wasteRecycling: "Recycling",
    transportationResults: "Transportation Results",
    flightResults: "Flight Results",
    trainResults: "Train Results",
    buildingResults: "Building Results",
    wasteComposting: "Composting Results",
    wasteResults: "Waste Results",
    busFrequency: "Bus Weekly Use",
    tramFrequency: "Tram Weekly Use",
    metroFrequency: "Metro Weekly Use",
    bicycleFrequency: "Bicycle Weekly Use"
    // Add more mappings as needed
};

    // Iterate over keys in userCalculation
    Object.keys(userCalculation).forEach(key => {

      if (excludedKeys.includes(key)) {
        return;
    }

        const value = userCalculation[key];
        const title = titleMappings[key] || key; // Use mapped title or default to the original key

        // If value is an array, add each item as a separate row
        if (Array.isArray(value) && value.length > 0) {
            value.forEach((item, index) => {
                csvData.push({ title: `${key} ${index + 1}`, value: item });
            });
        } else {
            // csvData.push({ title: key, value });
            csvData.push({ title, value });
        }
    });

    return csvData;
};

const csvData = transformDataForCSV();


const client = axios.create({
   baseURL: "https://co2.dev.wecompair.eu/co2apidev"
});


const accessToken = localStorage.getItem('accessToken');   
let sub = null;
if (accessToken != null) {
  const decoded = jwtDecode(accessToken);
   sub = decoded.sub;
}

const [userCalculations, setUserCalculations] = useState([]);
const [userCalculationMap, setUserCalculationMap] = useState(new Map());
const [selectedUserCalculation, setSelectedUserCalculation] = useState(userCalculation);
const [calculationDate, setCalculationDate] = useState("");
const [calculationSelected, setCalculationSelected] = useState(false);

useEffect(() => {
  client.get(`/userCalculation/${sub}`)
    .then((response) => {
        setUserCalculations(response.data)

        response.data.forEach(function (ucalculation) {
            setUserCalculationMap(new Map(userCalculationMap.set(ucalculation._id, ucalculation)))
        })
    });       
}, []);


const loadCalculations = () =>{
                  
  let calculationList = []

  userCalculations.forEach((calculation) => {
              
      let timestamp = calculation._id.toString().substring(0,8)
      let date = new Date( parseInt( timestamp, 16 ) * 1000 )

      calculationList.push(
          <MenuItem value={calculation._id}>{date.toString()}</MenuItem>
      )    
  })

  return calculationList
}


const handleUserCalculation = (e) => {
  let calculation = userCalculationMap.get(e.target.value)

  setSelectedUserCalculation(e.target.value)
  setUserCalculation(calculation)

  let timestamp = calculation._id.toString().substring(0,8)
  let date = new Date( parseInt( timestamp, 16 ) * 1000 )

  setCalculationDate(date.toString())
  updateFormData();

  // Check if a calculation has been selected
  setCalculationSelected(true);
}

const { t } = useTranslation();

const carFields = [];

if (userCalculation.carDistance && userCalculation.carDistance.length > 0) {
  for (let i = 0; i < userCalculation.carDistance.length; i++) {
    carFields.push(
      <>
      <h6 className="card-header bg-primary text-white text-center  mb-2"> {t("Vehicle")} {"  "} {i+1} </h6>  
      <div className="row">
        <div className="col-sm-6">
          <p className="mb-0"> {t("Kilometers/Year")}:</p> 
        </div>

        <div className="col-sm-6">
          <p className="text-muted mb-0">{userCalculation.carDistance[i]}</p>
          
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm-6">
          <p className="mb-0">{t("Liters/100km or kWh/100km")}:</p>
        </div>

        <div className="col-sm-6">
          <p className="text-muted mb-0">{userCalculation.carLitersPerKm[i]}</p>
        </div>
      </div>
    
      <hr />

      <div className="row">
        <div className="col-sm-6">
          <p className="mb-0">{t("Type of Fuel Question")}:</p>
        </div>

        <div className="col-sm-6">
          <p className="text-muted mb-0">{userCalculation.carFuelType[i]}</p>
        </div>
      </div>
    
      <hr />

      </>
    );
  }
  // console.log("Length of carDistance array:", userCalculation.carDistance.length);
} else {
  // console.log("carDistance array is empty");
}

const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => {
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

const renderModalContent = () => {
  return (
    <div className="modal-content" style={{
      display: 'flex',
      alignItems: 'flex',
      justifyContent: 'flex',
      height: '100%',
    }}>

<div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        overflow: 'auto',  // Make the content scrollable
        maxHeight: '80vh',  // Limit the maximum height to 80% of the viewport height
        maxWidth: '95vw',  // Increase the maximum width to 95% of the viewport width
      }}>
        {/* {console.log(formData)} */}
      <Results
            country={userCalculation.country}
            carC={userCalculation.transportationCountryAverage}
            trainC={userCalculation.trainCountryAverage}
            buildingC={userCalculation.buildingCountryAverage}
            wasteC={userCalculation.wasteCountryAverage}
            car={userCalculation.transportationResults}
            flight={userCalculation.flightResults}
            train={userCalculation.trainResults}
            building={userCalculation.buildingResults}
            waste={userCalculation.wasteResults}
            formData={formData}
            carE={userCalculation.transportationEUAverage}
            trainE={userCalculation.trainEUAverage}
            buildingE={userCalculation.buildingEUAverage}
            wasteE={userCalculation.wasteEUAverage}
            start={userCalculation.startDate}
            end={userCalculation.endDate}
            flightC={userCalculation.flightCountryAverage}
            flightE={userCalculation.flightEUAverage}
          />
    </div>
    </div>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw', // 95% of the viewport width

  '@media (min-width: 768px)': {
    width: '70vw', // Reduce width for screens wider than 768px
  },

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const targetRef = useRef();

// AccountBoxIcon
  return (
    <>

    <Helmet>
      <title>Profile - Carbon Footprint Simulation Dashboard - CompAir</title>
    </Helmet>

    <div ref={targetRef}>
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-4">
          <div className="card mb-4">
            <div className="card-body text-center">
              <Box>
                <AccountBoxIcon sx={{ fontSize: 320, color: grey[500]}}/>
              </Box>
              <div className="d-flex justify-content-center mb-2">

              <Box>
                <FormControl variant="standard" sx={{ minWidth: 120, maxWidth: 120}}>
                    <InputLabel id="demo-simple-select-standard-label"> {t("Calculations List Title")} </InputLabel>
                    <Select
                        label="calculation"
                        variant="standard"
                        name="calculation"
                        defaultValue = ' '
                        value={selectedUserCalculation}
                        onChange={(e) => { 
                            handleUserCalculation(e) 
                        }}
                        >
                            {loadCalculations()}
                        
                    </Select>
                </FormControl>
            </Box>


            <div className="d-flex justify-content-center mb-1">

                <Button variant="contained" color="primary" >
                  {/* <CSVLink
                    className="text-white"
                    style={{ textDecoration: 'none' }}
                    data={data}
                    headers={headers}
                    separator={';'}
                  >
                    {t("Export")}
                    </CSVLink> */}
                    

                  <CSVLink 
                    className="text-white"
                    style={{ textDecoration: 'none' }}
                    data={csvData} 
                    filename={"calculation_results.csv"}
                  >
                    {t("Export")} CSV 
                  </CSVLink>
                </Button>

                <Button 
                  variant="contained" color="primary" 
                  style={{ color: 'white' }}
                  onClick={() => generatePDF(targetRef, {filename: 'page.pdf'})} 
                >
                  {t("Export")} PDF 
                </Button>

                      {/* Your existing component JSX */}
      {/* <div className="row mb-3">
        <div className="col-md-6">
          <Button variant="contained" color="primary" onClick={downloadPDF} style={{ color: 'white' }}>
                  Export PDF
          </Button>
        </div>
      </div> */}
      


            {/* Render the button only if a calculation has been selected */}
            {calculationSelected && (
                <Button variant="contained" color="primary" onClick={openModal} style={{ color: 'white' }}>
                  {t("More Info")}
                </Button>
            )}
              <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                    {renderModalContent()} 
                </Box>
              </Modal>


            </div>
              </div>
            </div>
          </div>

          
            <div className="card mb-4">
              <div className="card-body p-0">
                <ul className="list-group list-group-flush rounded-3">
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      icon={faEarthAmericas}
                      className="fa-lg text-primary"
                    />
                    <p className="mb-0" >{t(userCalculation.country)}</p>
                    
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      icon={faCity}
                      className="fa-lg text-secondary"
                    />
                    <p className="mb-0">{t(userCalculation.city)}</p>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className="fa-lg text-success"
                    />
                    <p className="mb-0">{userCalculation.startDate} - {userCalculation.endDate}</p>
                  </li>
                </ul>
              </div>
            </div>




          <div className="card mb-4">
            <div className="card-body">
              <p className="mb-4">
                <span
                  className="font-italic me-1"
                  style={{ color: 'rgb(255, 99, 132)' }}
                >
                  {t("You")}
                </span>{' '}
                ( {t("tons CO2 per domain")} )
              </p>
              <p className="mb-1" style={{ fontSize: '.77rem' }}>
                {t("Vehicles")} <span className="text-primary">{userCalculation.transportationResults}</span> {t("tons CO2")}
              </p>
              <div className="progress rounded" style={{ height: '5px' }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: userCalculation.transportationResults * 100 + '%' }}
                  aria-valuenow={userCalculation.transportationResults * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="label"
                />
              </div>
              <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                {t("Flights")} <span className="text-danger">{userCalculation.flightResults}</span> {t("tons CO2")}
              </p>
              <div className="progress rounded" style={{ height: '5px' }}>
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{ width: userCalculation.flightResults * 100 + '%' }}
                  aria-valuenow={userCalculation.flightResults * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="label"
                />
              </div>
              <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                {t("Trains")} <span className="text-success">{userCalculation.trainResults}</span> {t("tons CO2")}
              </p>
              <div className="progress rounded" style={{ height: '5px' }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: userCalculation.trainResults * 100 + '%' }}
                  aria-valuenow={userCalculation.trainResults * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="label"
                />
              </div>
              <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                {t("Buildings")}<span className="text-warning">{userCalculation.buildingResults}</span> {t("tons CO2")}
              </p>
              <div className="progress rounded" style={{ height: '5px' }}>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{ width: userCalculation.buildingResults * 100 + '%' }}
                  aria-valuenow={userCalculation.buildingResults * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="label"
                />
              </div>
              <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                {t("Waste Management")} <span className="text-info">{userCalculation.wasteResults}</span> {t("tons CO2")}
              </p>
              <div className="progress rounded mb-2" style={{ height: '5px' }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: userCalculation.wasteResults * 100 + '%' }}
                  aria-valuenow={userCalculation.wasteResults * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="label"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="card mb-4">
            <div className="card-body">
              {/* <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("Full Name")}:</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{ name }</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("e-mail")}:</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{ email }</p>
                </div>
              </div>
              <hr /> */}
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("Calculator Results")}:</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-primary mb-0"> {userCalculation.date}</p>
                  <p className="text-primary mb-0"> {calculationDate}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card mb-4">
                <h6 className="card-header bg-warning text-white text-center">
                  {t("Buildings")}
                </h6>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Fuel per Year")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{userCalculation.buildingFuel}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Type of Fuel")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{userCalculation.buildingFuelType}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Electricity")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{userCalculation.buildingElectricity}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Solar Thermal")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{String(userCalculation.buildingUseSolarThermal)}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Heat Pump")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{String(userCalculation.buildingUseHeatPump)}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Energy Efficient Refrigerator")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{t(userCalculation.buildingRefrigerator)}</p>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Energy Efficient Washing Machine")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{t(userCalculation.buildingWashingMachine)}</p>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Energy Efficient Dishwasher")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{t(userCalculation.buildingDishwasher)}</p>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Energy Efficient Oven")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{t(userCalculation.buildingOven)}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-4">
                <h6 className="card-header bg-info text-white text-center">
                  {t("Waste Management")}
                </h6>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Recycling")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{userCalculation.wasteRecycling}</p>
                    </div>
                  </div>

                  <hr />

                  <div className="row">
                    <div className="col-sm-6">
                      <p className="mb-0">{t("Composting")}:</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="text-muted mb-0">{userCalculation.wasteComposting}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className="col-md-6">
              <div className="card mb-4">
                <h6 className="card-header bg-primary text-white text-center"> {t("Vehicles")} </h6>  
                <div className="card-body">

                  {carFields}

                  {/* <div className="row"> */}
                    {/* <div className="col-sm-6">
                      <p className="mb-0">{t("Public Transport Question")}:</p>
                    </div> */}

                    {/* <div className="col-sm-6">
                      <p className="text-muted mb-0">{String(userCalculation.usePublicTransport)}</p>
                  </div> */}
                {/* </div> */}

              </div>
            </div>  

            <div className="card mb-4" >
              <h6 className="card-header bg-danger text-white text-center">
                {t("Flights")}
              </h6>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <p className="mb-0">{t("Domestic")}:</p>
                  </div>
                  <div className="col-sm-6">
                    <p className="text-muted mb-0">{userCalculation.domesticFlights}</p>
                  </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-0">{t("Short Haul")}:</p>
                </div>
                <div className="col-sm-6">
                  <p className="text-muted mb-0">{userCalculation.shortFlights}</p>
                </div>
              </div>
              
              <hr />

              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-0">{t("Medium Haul")}:</p>
                </div>
                <div className="col-sm-6">
                  <p className="text-muted mb-0">{userCalculation.mediumFlights}</p>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-0">{t("Long Haul")}:</p>
                </div>
                <div className="col-sm-6">
                  <p className="text-muted mb-0">{userCalculation.longFlights}</p>
                </div>
              </div>
              
            </div>
          </div>

          <div className="card mb-4">
            <h6 className="card-header bg-success text-white text-center">
            {t("Trains")}
            </h6>          
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-0">{t("Number of Trips")}:</p>
                </div>
                <div className="col-sm-6">
                  <p className="text-muted mb-0">{userCalculation.trainTrips}</p>
                </div>
              </div>
                  
              <hr />

              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-0">{t("Avg Trip Distance")}:</p>
                </div>

                <div className="col-sm-6">
                  <p className="text-muted mb-0">{userCalculation.trainAvgDistance}</p>
                </div>

              </div>
            </div>
          </div>

        </div>

          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <p className="mb-4">
                    <span
                      className="font-italic me-1"
                      style={{ color: 'rgb(54, 162, 235)' }}
                    >
                      {t(userCalculation.country)}
                    </span>{' '}
                    ({t("tons CO2 per domain")})
                  </p>
                  <p className="mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Vehicles")} <span className="text-primary">{userCalculation.transportationCountryAverage}</span> {t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: userCalculation.transportationCountryAverage * 100 + '%' }}
                      aria-valuenow={ userCalculation.transportationCountryAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Flights")}  <span className="text-danger">{userCalculation.flightCountryAverage}</span> {t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: userCalculation.flightCountryAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.flightCountryAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Trains")}  <span className="text-success">{userCalculation.trainCountryAverage}</span> {t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: userCalculation.trainCountryAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.trainCountryAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Buildings")}  <span className="text-warning">{userCalculation.buildingCountryAverage}</span> {t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: userCalculation.buildingCountryAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.buildingCountryAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Waste Management")} <span className="text-info">{userCalculation.wasteCountryAverage}</span>{' '} {t("tons CO2")}
                  </p>
                  <div
                    className="progress rounded mb-2"
                    style={{ height: '5px' }}
                  >
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: userCalculation.wasteCountryAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.wasteCountryAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-4 mb-md-0">
                <div className="card-body">
                  <p className="mb-4">
                    <span
                      className="font-italic me-1"
                      style={{ color: 'rgb(0, 204, 0)' }}
                    >
                      {t("Europe")}
                    </span>{' '}
                    ({t("tons CO2 per domain")})
                  </p>
                  <p className="mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Vehicles")} <span className="text-primary">{userCalculation.transportationEUAverage}</span> {t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: userCalculation.transportationEUAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.transportationEUAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Flights")} <span className="text-danger">{userCalculation.flightEUAverage}</span> tons CO2{t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: userCalculation.flightEUAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.flightEUAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Trains")} <span className="text-success">{userCalculation.trainEUAverage}</span> tons CO2{t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: userCalculation.trainEUAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.trainEUAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Buildings")} <span className="text-warning">{userCalculation.buildingEUAverage}</span> tons{t("tons CO2")}
                  </p>
                  <div className="progress rounded" style={{ height: '5px' }}>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: userCalculation.buildingEUAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.buildingEUAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                  <p className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                    {t("Waste Management")} <span className="text-info">{userCalculation.wasteEUAverage}</span>{' '}{t("tons CO2")}
                  </p>
                  <div
                    className="progress rounded mb-2"
                    style={{ height: '5px' }}
                  >
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: userCalculation.wasteEUAverage * 100 + '%' }}
                      aria-valuenow={userCalculation.wasteEUAverage * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-label="label"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserScenariosList />
    </div>
    </div>
    </>
  );
};

export default Account;
