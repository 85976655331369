import React from 'react';

import {Button, Grid, FormControl, Select, MenuItem, Box, InputLabel} from "@mui/material"
// import TextField from '@mui/material/TextField';
// import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
// import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import { useMediaQuery } from '@mui/material';

const Welcome = ({
  toNextTab,
  handleCountry,
  handleStartDate,
  handleEndDate,
  formData,
  setFormData,
  locked
}) => {

  const { t } = useTranslation();


  const selectCountry = (c) => {
    handleCountry(c);
    setFormData({ ...formData, country: c });
  };

  const selectStartDate = (s) => {
    handleStartDate(s);
    setFormData({ ...formData, startDate: s });
  };

  const selectEndDate = (e) => {
    handleEndDate(e);
    setFormData({ ...formData, endDate: e });
  };

  // const selectCountry = (c) => {
  //   handleCountry(c)
  //   setFormData({...formData, country: c});
  //   console.log(formData)
  //  }

  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  // const [pxValue, setPxValue] = useState(isMobile ? 1 : 4);
  // const [xsValue, setXsValue] = useState(isMobile ? 5 : 3);
  
  // const handleResize = () => {
  //   setIsMobile(window.innerWidth <= 600);
  // };
  
  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  
  // useEffect(() => {
  //   setPxValue(isMobile ? 1 : 4);
  //   setXsValue(isMobile ? 5 : 3);
  // }, [isMobile]);

  // const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>CO2 Calculator - Carbon Footprint Simulation Dashboard - CompAir</title>
      </Helmet>
  
      <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray" }} backgroundColor="white">
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            <h5>{t("CO2 Calculator")}</h5>
          </Grid>
  
            
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Country Question")}
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>

          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>

              <FormControl fullWidth>
                <InputLabel id="selectlabel">{t("Country Option")}</InputLabel>
                <Select
                  labelId="selectLabel"
                  defaultValue={''}
                  value={formData.country}
                  onChange={(e) => { selectCountry(e.target.value); }}
                  style={{ textAlign: 'center' }} 
                >
                  <MenuItem value={"greece"}>{t("Greece")}</MenuItem>
                  <MenuItem value={"germany"}>{t("Germany")}</MenuItem>
                  <MenuItem value={"bulgaria"}>{t("Bulgaria")}</MenuItem>
                  <MenuItem value={"belgium"}>{t("Belgium")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Date Explanation")}
          </Grid>
  

          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
              <label className="form-label">{t("From")}</label>
              <input
                type="date"
                className="form-control"
                value={formData.startDate}
                onChange={(e) => { selectStartDate(e.target.value); }}
                style={{ textAlign: 'center' }}
              />
            </Grid>

            <Grid item xs={12} md={4} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
              <label className="form-label" style={{ marginRight: '8px' }}>{t("To")}</label>
              <input
                type="date"
                className="form-control"
                value={formData.endDate}
                onChange={(e) => { selectEndDate(e.target.value); }}
                style={{ textAlign: 'center' }}
              />
            </Grid>
          </Grid>
  
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Next Steps")}
          </Grid>
        </Grid>
  
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 2.5 }}>
            <Button disabled={locked} onClick={(e) => toNextTab(e)} fullWidth>
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );


  // return (
  //   <>
  //   <Helmet>
  //     <title>CO2 Calculator - Carbon Footprint Simulation Dashboard - CompAir</title>
  //   </Helmet>

  //   <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
  //   <Grid container justifyContent="center">
  //     <Grid item sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
  //     <h5>{t("CO2 Calculator")}</h5>
      
  //     </Grid>
  //     <Grid item xs={12} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>{t("Country Question")}</Grid>
  //     <Grid item  xs={xsValue} sx={{px: {pxValue}, py: 0.5, lineHeight: 1.5,}}>
  //       <FormControl fullWidth>
  //         <InputLabel id="selectlabel"> {t("Country Option")} </InputLabel>
  //         <Select labelId="selectLabel" defaultValue={''} value={formData.country} onChange={(e) => {selectCountry(e.target.value);}}>
  //           <MenuItem value={"greece"}> {t("Greece")} </MenuItem>
  //           <MenuItem value={"germany"}> {t("Germany")} </MenuItem>
  //           <MenuItem value={"bulgaria"}> {t("Bulgaria")} </MenuItem>
  //           <MenuItem value={"belgium"}> {t("Belgium")} </MenuItem>
  //         </Select>
  //       </FormControl>
  //     </Grid>
  //     <Grid item xs={9}> </Grid>
  //     <Grid item xs={12} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
  //              {t("Date Explanation")}
  //     </Grid>
  //     <Grid item xs={3} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
     
  //     <label className="form-label"> {t("From")} </label>
  //             <input
  //               type="date"
  //               className="form-control"
  //               value={formData.startDate}
  //               onChange={(e) => {
  //                 selectStartDate(e.target.value);
  //               }}
  //             />
             
  //     </Grid>
  //     <Grid item xs={9}> </Grid>
  //     <Grid item xs={3} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
      
  //     <label className="form-label"> {t("To")} </label>
  //             <input
  //               type="date"
  //               className="form-control"
  //               value={formData.endDate}
  //               onChange={(e) => {
  //                 selectEndDate(e.target.value);
  //               }}
                
  //             />
             
  //     </Grid>
  //     <Grid item xs={9}> </Grid>

  //     <Grid item xs={12} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
  //       {t("Next Steps")}
  //     </Grid>
  //     </Grid>
  //     <Grid container justifyContent="right">
  //     <Grid item sx={{px: 4, py: 0.5, lineHeight: 2.5,}}>
  //       <Button disabled={locked} onClick={(e) => toNextTab(e)}>
  //         {t("Next")}
  //       </Button>
  //     </Grid>
  //   </Grid>
  // </Box>
  // </>
  // );
    // <div className="card">
    //   <div className="card-body">
    //     <h5 className="card-title text-center">CO2 Calculator</h5>
    //     <div>
    //       <div className="col-md-4 py-2">
    //         <label className="form-label">
    //           First, please tell us where do you live?
    //         </label>
    //         <select
    //           className="form-select"
    //           onChange={(e) => {
    //             selectCountry(e.target.value);
    //           }}
    //           defaultValue={formData.country}
    //         >
    //           <option disabled value="">
    //             Choose...
    //           </option>
    //           <option value="greece">Greece</option>
    //           <option value="germany">Germany</option>
    //           <option value="bulgaria">Bulgaria</option>
    //           <option value="belgium">Belgium</option>

    //         </select>
    //       </div>
    //       <div className="col-12 py-2">
    //         <span>
    //           Carbon footprint calculations are typically based on annual
    //           emissions from the previous 12 months.If you would like to
    //           calculate your carbon footprint for a different period use the
    //           calendar boxes below:
    //         </span>
    //       </div>
    //       <div className="row">
    //         <div className="col-md-3">
    //           <label className="form-label">From</label>
    //           <input
    //             type="date"
    //             className="form-control"
    //             value={formData.startDate}
    //             onChange={(e) => {
    //               console.log("Date");
    //               console.log(e.target.value)
    //               selectStartDate(e.target.value);
    //             }}
    //           />
    //         </div>
    //         <div className="col-md-3 py-2 py-md-0">
    //           <label className="form-label">To</label>
    //           <input
    //             type="date"
    //             className="form-control"
    //             value={formData.endDate}
    //             onChange={(e) => {
    //               selectEndDate(e.target.value);
    //             }}
    //           />
    //         </div>
    //       </div>
    //       <div className="col-12 py-2">
    //         <span>
    //           Next, select the appropriate tab above to calculate the part of
    //           your lifestyle you are most interested in, e.g. your flights.Or,
    //           visit each of the tabs above to calculate your full carbon
    //           footprint.Following your calculation, you can offset / neutralise
    //           your emissions through one of our climate-friendly projects.
    //         </span>
    //       </div>
    //       <div className="col-12 py-2">
    //         <div className="d-flex justify-content-end">
    //           <button
    //             onClick={(e) => toNextTab(e)}
    //             className="btn btn-primary"
    //             type="button"
    //             disabled={locked}
    //           >
    //             Demographic
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>


    // );
};

export default Welcome;
