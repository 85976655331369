const bgJSON = {
    Blueprints: 'Plans',

    // Scenario Simulation
    "Scenario Simulation": "Симулация на сценарии",
    "Emission text":"Целта на Европейската комисия e 55% нетно намаление на емисиите на парникови газове до 2030 г.",
    "% CO2 reduction" : "% Намаяване на СО2",
    "You've reached the end of the list!" : "Стигнахте до края на списъка!",
    "Save Button Label" : "Запази",
    "Empty Tabs Text" : "Изберете един от разделите, за да продължите",
    "Citizen Tab Text" : "Действия, които сте готови да предприемете като гражданин",
    "Government Tab Text" : "Действия от правителството, които сте готови да приемете",
    "Local Government Tab Text" : "Действия на местната власт, коити сте готови да приемете",
    "Local Government" : "Местна власт",
    "Current" : "Текущо",
    "Target" : "Цел",
    "Example Scenarios Tooltip" : "Тук можете да избирате между примерни сценарии. От най-малкото усилие (сценарий с минимални действия) до прекомерно усилие (сценарий максимални действия) за намаляване на вашия въглероден отпечатък.",
    "Emission Tooltip" : "Изберете действия, които сте готови да предприемете, както и действия от страна на правителството, които сте готови да приемете, за да намалите въглеродните си емисии",
    "Optimistic Scenario" : "Оптимистичен сценарий",
    "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly." : "Оптимистичен сценарий е този, при който гражданите променят ежедневните си навици, за да бъдат по-устойчиви и щадящи към околната среда.",
    "Pessimistic Scenario" : "Песимистичен сценарий",
    "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist." : "Песимистичен сценарий е този, при който гражданите не променят ежедневните си навици, отрицателните въздействия върху околната среда ще продължат да съществуват.",
    "Realistic Scenario" : "Реалистичен сценарий",
    "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist." : "При реалистичния сценарий гражданите ще се опитат да променят ежедневните си навици, за да постигнат устойчивост и да подобрят околната среда, като същевременно осъзнават ограниченията и предизвикателствата, които могат да съществуват.",


    // Landing Page
    "Landing Page Title": "Табло за симулация на въглероден отпечатък",
    "Landing Page Subtitle": "Заедно можем да постигнем чист въздух за всички. Използвайте таблото за симулация на въглероден отпечатък и действайте днес.",
    "Get Started Button": "Да започваме",
    "Explanation Title": "Какво е Табло за симулация на въглероден отпечатък",
    "Explanation Paragraph 1": "Таблото за симулацията на въглероден отпечатък се състои от два инструмента. Първият е калкулаторът за въглероден отпечатък. Този инструмент има за цел да помогне на гражданите да разберат как влияят върху своя въглероднен отпечатък чрез ежедневните си дейности, както и да предостави препоръки за неговото намаляване. ",    
    "Explanation Paragraph 2": "Вторият инструмент е Табло за симулация на сценарии. Този инструмент позволява на гражданите да участват в изготвянето на политики. Представя набор от действия, които те желаят да предприемат, както и действия, които са готови да приемат от правителството с цел намаляване на въглеродните емисии. Гражданите могат да избират кои действия смятат, че са готови да предприемат и това създава сценарий, който те могат да представят и от който политиците могат да получат информация за създаване на бъдещи политики. ",
    "Keep track of your carbon footprint by signing up.": "Следете въглеродния си отпечатък, като се регистрирате.",
    "Sign Up Now": "Регистрирайте се сега",
    "Sign In": "Влезте в акаунта си",


    // CO2 Calculator - Tab Names
    "Welcome": "СО2 калкулатор",
    "Demographic": "Общо",
    "Cars": "Коли",
    "Flights": "Полети",
    "Trains": "Влакове",
    "Buildings": "Сгради",
    "Waste Management": "Управление на отпадъците",
    "Results": "Резултати",

    // CO2 Calculator - Welcome
    "CO2 Calculator": "СО2 калкулатор",
    "Country Question": "Първо, кажете ни къде живеете",
    "Country Option": "Държава",
    "Greece": "Гърция",
    "greece": "Гърция",
    "GREECE": "Гърция",
    "Germany": "Германия",
    "germany": "Германия",
    "GERMANY": "Германия",
    "Belgium": "Белгия",
    "belgium": "Белгия",
    "BELGIUM": "Белгия",
    "Bulgaria": "България",
    "bulgaria": "България",
    "BULGARIA": "България",
    "From": "От",
    "To": "До",
    "Date Explanation": "Изчисленията на въглеродния отпечатък обикновено се основават на годишните емисии от предходните 12 месеца. Ако искате да изчислите своя въглероден отпечатък за различен период, използвайте календарните полета по-долу:",
    "Next Steps": "След това изберете подходящия раздел по-горе, за да изчислите частта за начина си на живот, която ви интересува най-много, напр. вашите полети. Или посетете всеки от разделите по-горе, за да изчислите пълния си въглероден отпечатък. След вашето изчисление можете да компенсирате/неутрализирате емисиите си чрез някои от нашите щадящи климата предложения.",

    // CO2 Calculator - Demographic
    "Demographic Characteristics": "Обща информация",
    "City Question": "В кой град живеете",
    "City Option": "Град",
    "Athens": "Атина",
    "athens": "Атина",
    "Brussels": "Брюксел",
    "brussels": "Брюксел",
    "Berlin": "Берлин",
    "berlin": "Берлин",
    "Plovdiv": "Пловдив",
    "plovdiv": "Пловдив",
    "Sofia": "София",
    "sofia": "София",
    "Other city or village": "Друг град или село",
    "Age Question": "На колко години сте?",
    "Age Option": "Възраст",
    // translate the age options
    "Below 18": "Под 18 години",
    "Above 65": "Над 65 години",
    "Gender Question": "От какъв пол сте?",
    "Gender Option": "Пол",
    // translate the age options
    "Male": "Мъж",
    "Female": "Жена",
    "Non-binary": "Небинарен",
    "Do not wish to specify": "Не желая да посоча",
    "Marital Status Question": "Какъв е семейният ви статус?",
    "Marital Status Option": "Marital Status",
    // add the marital status options
    "Social Aid Question": "Получавате ли някаква форма на социално подпомагане?",
    "Social Aid Option": "Социално подпомагане",
    "Education Level Question": "Каква е Вашата образователна степен?",
    "Education Level Option": "Ниво на образование",
    "Primary Education": "Основно образование",
    "Secondary Education": "Средно образование",
    "University Education": "Бакалавър",
    "Postgraduate Education": "Магистър",
    "Municipal Communities Question": "В кой район живеете?",
    "Municipal Communities Option": "Район/ квартал",
    "Previous": "Предходен",
    "Next": "Следващ",
    "How many times per week on average do you use the metro?": "Колко пъти на седмица средно използвате метрото?",
    "How many times per week on average do you use the bus?": "Средно колко пъти седмично използвате автобус?",
    "How many times per week on average do you use the tram?": "Средно колко пъти седмично използвате трамвай?",
    "How many times per week on average do you use a bicycle?": "Средно колко пъти седмично карате велосипед?", 
    "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.": "разберем как фактори като възраст, пол и образование влияят върху ежедневните дейности, които допринасят за въглеродния отпечатък. Тези данни не са предназначени да се разглеждат поотделно.", 

    // CO2 Calculator - Cars
    "CO2 from Cars": "СО2 от автомобили",
    "Vehicles Info Text": "Пътният транспорт е отговорен за 1/5 от общите емисии на CO2 в ЕС. Знаете ли, че леките автомобили са отговорни за 75% от тях?",
    "Distance Question": "Изминато разстояние (км за избрания период)",
    "Liters Question": "Разход / на 100км",
    "Type of Fuel Question": "Тип гориво", // used for Buildings as well
    // "Type of Fuel Option": "Fuel Type", // used for Buildings as well
    "Type of Fuel Option": "Тип гориво", // used for Buildings as well
    "Diesel": "Дизелово гориво",
    "Petrol": "Бензин",
    "LPG": "Пропан - бутан",
    "CNG": "Метан",
    "Petrol + E10": "Бензин +Е10",
    "Public Transport Question": "За всекидневното си придвижване използвате ли градски транспорт?",
    "Electric Car Question": "Електроавтомобил",
    "Liters/100km or kWh/100km": "л/100 км или кВтч/100 км",
    "Electricity": "Електричество",
    "Add Vehicle": "Добавяне на превозно средство",
    "Remove Last Vehicle": "Премахване на превозно средство",
    "CO2 from Vehicles": "CO2 от превозни средства",
    "Vehicle": "Превозно средство",
    "Vehicles": "Превозни средства",
    "Required": "Задължителен",


    // CO2 Calculator - Flights
    "CO2 from Flights": "СО2 от полети",
    "Flights Info Text": "Колко вътрешни полета, полета на къси, средни или дълги разстояния сте предприели за избрания период?",
    "Flight Description": "Описание (продължителност на полета)",
    "Number of Flights": "Брой полети",
    "Avg Flight Distance": "Средна дължина на полета (в км)",
    "Domestic": "Вътрешни",
    "Short Haul Flight": "Кратки разстояния (<3 часа)",
    "Medium Haul Flight": "Средни разстояния (3-6 часа)",
    "Long Haul Flight": "Дълги разстояния (6-12 часа)",
    "Flights Info Text 2": "Свързващи полети и полети за връщане се броят като отделни полети",


    // CO2 Calculator - Trains
    "CO2 from Trains": "СО2 от влакове",
    "Trains Info Text": "Колко често пътувате с влак?",
    "Number of Trips": "Брой пътувания",
    "Avg Trip Distance": "Средно разстояние при пътуване (км)",
    
    
    // CO2 Calculator - Buildings
    "CO2 from Buildings": "СО2 от сгради",
    "Fuel per Year": "Какво е Вашето потребление за гориво за определения период?",
    "Fuel per Year Tooltip": "Какво е Вашето потребление за гориво за определения период?",
    "Type of Fuel": "Тип гориво за отопление или затопляне на вода",
    "Type of Fuel Tooltip": "Какво гориво използвате в дома си?",
    "Heating Oil": "Нафта",
    "Coal": "Въглища",
    "Biomass": "Биомаса",
    "Natural Gas": "Природен газ",
    "Not using any": "Не използвам",
    "Electricity Buildings": "Електричество (кВтч за определения период)",
    "Solar Thermal": "Използване на слънчева енергия за затоляне на вода",
    "Heat Pump": "Термопомпи",
    "Is your refrigerator energy efficient?": "Хладилникът ви енергийно ефективен ли е?",
    "Is your washing machine energy efficient?": "Пералнята ви енергийно ефективна ли е?",
    "Is your dishwasher energy efficient?": "Миялната ви машина енергийно ефективна ли е?",
    "Is your oven energy efficient?": "Фурната ви енергийно ефективна ли е?",
    "No": "Не",
    "Semi-efficient": "Полу-ефективна",
    "Yes": "Да",
    "I don't know": "Не знам",
    "I don't own one": "Не притежавам",
    "Regarding Energy Efficiency Labels": "Относно етикетите за енергийна ефективност",
    "Match table explanation": "На 1 март 2021 г. етикетите за енергийна ефективност на уредите бяха променени. По-долу можете да намерите таблица за съответствие, която да ви помогне да намерите енергийния клас на вашия уред, ако е бил закупен преди 1 март 2021 г.",
    "Refrigerator": "Хладилник",
    "Before 2021": "Преди 2021",
    "After 2021": "След 2021",
    "Washing Machine": "Пералня",
    "Dishwasher": "Съдомиялна",
    "Oven": "Фурна",
    "The energy classes for ovens remain the same": "Енергийните класове на фурните остават същите",

    // CO2 Calculator - Waste Management
    "CO2 from Waste Management": "СО2 от управление на отпадъците",
    "The average citizen in": "Средно населението в",
    "recycles": "рециклира",
    "Kgs of waste": "кг отпадъци",
    "and composts": "и компостира",
    "Recycle Tooltip": "Колко хартия, стъкло, пластмаса, метални кутии рециклирате?",
    "Compost Tooltip": "Какво количество кухненски отпадъци компостирате?",
    "Choose": "Изберете",
    "Recycling": "Рециклиране",
    "No recycling": "Без рециклиране",
    "Less than average recycling": "Рециклиране по- ниско от средното ",
    "Same as average recycling": "Рециклирането съвпада със средното ",
    "More than average recycling": "Рециклиране по- високо от средното ",
    "Composting": "Компостиране",
    "No composting": "Не компостирам",
    "Less than average composting": "Компостирам по- малко от средното",
    "Same as average composting": "Компостирането съвпада със средното",
    "More than average composting": "Компостирам повече от средното",
    "Calculate": "Изчисли",
    "That is the equivalent of": "Това е еквивалентът на",
    "30lt trash bags": "броя от 30 л торби за смет",
    "and": "и",

    // CO2 Calculator - Completion Alerts
    "Calculated successfully": "Изчислението е успешно!",
    "Check Results": "Можете да проверите раздела Резултати, за да видите приложените промени.",
    "Uncompleted fields": "Има непопълени полета", 
    "Fill out fields": "Моля, попълнете всички задължителни полета, за да изчислите резултатите",

    
    // CO2 Calculator - Results
    "CO2 Calculator Results": "Резултати на СО2 калкулатора",
    "Calculator Results": "Резултати от калкулатора",
    "Save": "Запази",
    "Total": "общото",
    "User": "Потребител",
    "Waste": "Отпадъци",
    "Transportation": "Транспорт",
    "For more information": "За повече информация",
    "Your results compared to your country's average": "Вашите резултати сравнени със средните стойности за страната",
    "You": "Вие",
    "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.": "След като вече знаете какъв е вашият въглероден отпечатък, споделете мнението си за това как да намалите емисиите.",

    // Profile
    "Calculations List Title": "Изчисления",
    "Your Calculator Results": "Резултати от калкулатора",
    "tons CO2 per domain": "тонове CO2 на зона",
    "tons CO2": "тонове CO2 ",
    "Kilometers/Year": "Километри/год",
    "Liters/100 Km": "Литри/100",
    "Full Name": "Пълно име",
    "e-mail": "email",
    "Short Haul": "Кратки разстояния",
    "Medium Haul": "Средни разстояния",
    "Long Haul": "Дълги разстояния",
    "Europe": "Европа",
    "Export": "Експортиране",
    "Scenario": "Сценарий",
    "Energy Efficient Refrigerator": "Енергийно ефективен хладилник",
    "Energy Efficient Washing Machine": "Енергийно ефективна пералня",
    "Energy Efficient Dishwasher": "Енергийно ефективна миялна машина",
    "Energy Efficient Oven": "Енергийно ефективна фурна",
    "Car": "Кола",
    "More Info": "Повече информация",


    // Admin Page - 
    "Percentage": "Процент",
    "Recommendations": "Препоръки",
    "Scenarios": "Сценарии",
    "Actions": "Действия",
    "Statistics": "Статистика",

    // Admin Page - Scenarios
    "Scenario Explanation": "На тази страница можете да създадете собствени сценарии, като изберете действия от съседната колона. Можете също да актуализирате съществуващите сценарии.",
    "Select slider action": "Изберете действие с плъзгача",    
    "Please select the appropriate levels of the actions for your scenario.": "Моля, изберете подходящите нива на действията за вашия сценарий.",
    "Add Scenario": "Добавете сценарий",
    "Cancel": "Отказ",
    "Update": "Update",
    "Delete": "Delete",
    "Please enter a title and a description for your scenario.": "Моля, въведете заглавие и описание на вашия сценарий",
    "Citizen": "Гражданин",
    "Government": "Правителство",
    "Scenario Title": "Име на сценария",
    "Scenerio Description": "Описание на сценария",


    // Admin Page - Actions
    "Actions Title Text" : "Действия",
    "Actions Title Text secondary" : "Изберете действие",
    "Action Label" : "Име на действието",
    "Action Contribution Admin Page Text": "0 <= input <= 1",
    "Action Title 1 Admin Page Text" : "",
    "Action Title 2 Admin Page Text" : "",
    "Action Title 3 Admin Page Text" : "",
    "Action Percentage 1 Admin Page Text" : "",
    "Action Percentage 2 Admin Page Text" : "",
    "Action Percentage 3 Admin Page Text" : "",
    "Empty Fields Error Text" : "Полетата не може да са празни",
    "Clear" : "Изчистване",
    "Category" : "Категория",
    "Contribution" : "Принос",
    "Title" : "Заглавие",
    "Add action" : "Добавете действие",
    "No Action" : "Без действие",
    "First Action" : "Първо дейстие",
    "Second Action" : "Второ действие",
    "Third Action" : "Трето действие",
    "Please choose the percentage of the implementation of your action" : "Моля, изберете процента на изпълнение на вашето действие",
    "You have to enter a number between 0 and 1 that represents a percentage" : "Трябва да въведете число между 0 и 1, което представлява процент",
    "Please choose the percentage of the implementation of the first stage of your action" : "Моля, изберете процента на изпълнение на първия етап от вашето действие",
    "Please choose the percentage of the implementation of the second stage of your action" : "Моля, изберете процента на изпълнение на втория етап от вашето действие",
    "Please choose the percentage of the implementation of the third stage of your action" : "Моля, изберете процента на изпълнение на третия етап от вашето действие",
    "Please add the title of the new action.": "Моля, добавете заглавие на новото действие",

    // Admin Page - Recommendations
    "Add Link" : "Добавете линк",
    "Remove Last Link" : "Премахнете последния линк",
    "Link" : "Линк",
    "Please enter the text for your new recommendation." : "Моля въведете текста на Вашата нова препоръка",
    "Recommendation Text" : "Препоръчителен текст",
    "Add Case" : "Добавете случай",
    "Add Recommendation" : "Добавете препоръка",
    "Operator" : "Оператор",
    "Value" : "Стойност",
    "Country" : "Държава",


  // Admin Page - Statistics
  "Stage" : "Етап ",
  "Stage 0" : "Етап  0",
  "Stage 1" : "Етап  1",
  "Stage 2" : "Етап  2",
  "Stage 3" : "Етап  3",
  "Select Country" : "Изберете държава",
  "Select City" : "Изберете град",
  "Select Municipal Region" : "Изберете община",
  "Select Age" : "Изберете възраст",
  "Select Gender" : "Изберете пол",
  "Select Education Level" : "Изберете ниво на образование",
  "Select Social Aid" : "Изберете социално подпомагане",
  "Scenario Simulation Statistics" : "Статистика от симулирания сценарий",
  "Carbon Footprint Calculator Statistics" : "Статистика от калкулатора за въглероден отпечатък",
  "Average" : "Средно",


    // Recommendations
    "Consider replacing old fluorescent lighting with LEDs" : "Обмислете замяна на старо флуоресцентно осветление със светодиодно",
    "Consider switching to Energy Efficient appliances" : "Обмислете преминаване към енергийно ефективни уреди",
    "Consider using smart power strips" : "Обмислете използването на интелигентни разклонители",
    "Consider turning down the heating by 1 degree Celsius" : "Обмислете намаляване на отоплението с 1 градус по Целзий",
    "Consider unpluging electronical equipment when fully charged" : "Изключвайте електрическото оборудване, когато е напълно заредено",
    "Consider insulating your home" : "Помислете за изолация на дома си",
    "Consider increasing cycling by 30% (micromobility)" : "Обмислете да увеличите колоезденето с 30% (микромобилност)",
    "Consider using the train for medium length distances (insted of the car)" : "Използвайте влак за средни разстояния (вместо автомобил)",
    "Consider carpooling to work" : "Помислете за споделено пътуване до работа",
    "Consider using public transport in your daily routine" : "Обмислете използването на обществен транспорт в ежедневието си",
    "Consider replacing all business and first class trips by economy class trips" : "Обмислете замяна на всички пътувания в бизнес и първа класа с пътувания в икономична класа",
    "Consider replacing indirect flights with direct flights" : "Използвайте директни полети",
    "Consider reducing air travel by using trains or ferries" : "Намалете използването на въздушен транспорт, като използвате влакове или фериботи",
    "Consider using a reusable bad to the market" : "Използвайте чанта за многократна употреба при пазаруване",
    "Consider shopping at a bulk foods store" : "Пазарувайте в по-големи количества",
    "Good job!" : "Браво!",
    "Consider reducing the use of single plastics!" : "Намалете използването на пластмасата!",
    "Consider using public transport" : "Използвайте обществен транспорт",
    "Consider car sharing" : "Помислете за пътуване с една кола",
    "Consider replacing your washing machine with an energy efficient one" : "Помислете за подмяна на пералнята ви с енергийно ефективна",
    "Consider replacing your oven with an energy efficient one" : "Помислете за подмяна на фурната ви с енергийно ефективна",
    "Consider replacing your refrigerator with an energy efficient one" : "Помислете за подмяна на хладилника ви с енергийно ефективен",
    "Consider replacing your dishwasher with an energy efficient one" : "Помислете за подмяна на миялната ви машина с енергийно ефективна",
    "Consider reducing the use of single plastics":"Помислете за намаляване на употребата на единични пластмасови изделия",
    "Consider using a reusable bag to the market":"Помислете за използване на чанта за многократна употреба, когато пазарувате",
    "Consider purchasing preused items and/or materials":"Обмислете закупуването на предмети и/или материали втора употреба",
    "Consider circular products instead of single use":"Обмислете използването на продукти за многократна употреба вместо такива за еднократна употреба",
    "Consider products made of regenerated material sources": "Обмислете използването на продукти, изработени от материали от възобновяеми източници",
    "Consider less use of air-condition": "Помислете за намаляване на използването на климатици",
    "Consider using your own thermo for coffee/tea/water takeaway": "Обмислете използването на собствени чаши/бутилки за кафе/чай/вода за вкъщи, когато сте навън",
    "Consider using the composting bins of the city or your own": "Помислете дали да не използвате градските контейнери за компост или ваши собствени такива. ",
    "Consider performing maintenance on your heating system": "Обмислете извършването на поддръжка на отоплителната си система",
    "Consider upgrading your heating system": "Обмислете модернизиране на отоплителната си система",
    "Consider using a more efficient vehicle": "Обмислете използването на по-ефективен автомобил",
    "Consider donating surplus production out of your garden or field": "Обмислете да дарите излишната продукция от вашата градина или нива",
    "Consider sharing formulas instead of purchasing (shared vehicles, machines...)": "Обмислете използването на споделени продукти/услуги вместо закупуване (споделени превозни средства, машини...)",

  // Actions - Citizen
  "Improve Transportation Habits" : "Подобряване на навиците за пътуване",
  "20% increased Carpoolling" : "20% Увеличаване на споделеното пътуване",
  "50% increased Carpoolling" : "50% Увеличаване на споделеното пътуване",
  "70% increased Carpoolling" : "50% Увеличаване на споделеното пътуване",

  "Change Flying Habits" : "Промяна в навиците за избор на полети",
  "20% Replace business class with economy" : "20% Заменете бизнес класата с икономична",
  "50% Replace business class with economy" : "50% Заменете бизнес класата с икономична",
  "90% Replace business class with economy" : "90% Заменете бизнес класата с икономична",

  "Improve Flying Habits" : "Подобряване на навиците за избор на полети",
  "20% Replace indirect flights with direct flights" : "20% Използвайте директни полети вместо такива с прекачване",
  "50% Replace indirect flights with direct flights" : "50% Използвайте директни полети вместо такива с прекачване",
  "90% Replace indirect flights with direct flights" : "90% Използвайте директни полети вместо такива с прекачване",
  
  "Change Travelling Habits" : "Промяна на навиците за пътуване",
  "20% Train use for medium length distances" : "20% Използвайте на влак за средни разстояния",
  "50% Train use for medium length distances" : "50% Използвайте на влак за средни разстояния",
  "90% Train use for medium length distances" : "90% Използвайте на влак за средни разстояния",
  
  "Lighting Habits" : "Навици за осветление",
  "20% Replace old fluorescent lighting with LEDs" : "20% Заменете старото луминесцентно осветление с LED",
  "50% Replace old fluorescent lighting with LEDs" : "50% Заменете старото луминесцентно осветление с LED",
  "100% Replace old fluorescent lighting with LEDs" : "100% Заменете старото луминесцентно осветление с LED",
  
  "Appliances Habits" : "Навици за използване на уреди",
  "20% Energy Efficient appliances" : "20% Енергийно ефективни уреди",
  "50% Energy Efficient appliances" : "50% Енергийно ефективни уреди",
  "100% Energy Efficient appliances" : "100% Енергийно ефективни уреди",
  
  "Insulation Habits" : "Ниво на изолация",
  "20% Insulation Thickness" : "20% Дебелина на изолацията",
  "50% Insulation Thickness" : "50% Дебелина на изолацията",
  "100% Insulation Thickness" : "100% Дебелина на изолацията",

  "Improve Recycling Habits" : "Подобряване на навиците за рециклиране",
  "20% Increased recycling of plastics + paper + metal cans + glass" : "20% Увеличете рециклирането на пластмаса, хартия, метал и стъкло",
  "40% Increased recycling of plastics + paper + metal cans + glass" : "40% Увеличете рециклирането на пластмаса, хартия, метал и стъкло",
  "70% Increased recycling of plastics + paper + metal cans + glass" : "70% Увеличете рециклирането на пластмаса, хартия, метал и стъкло",

  "Introduce composting in everyday life": "Въвеждане на компостирането в ежедневието",
  "20% compost of organic waste by placing a bin": "20% компост от органични отпадъци чрез поставяне на контейнер",
  "40% compost of organic waste by placing a bin": "40% компост от органични отпадъци чрез поставяне на контейнер",
  "70% compost of organic waste by placing a bin": "70% компост от органични отпадъци чрез поставяне на контейнер",

  "Unplug devices that are not used or when on vacation": "Изключете от електрическата мрежа устройствата, които не се използват, или когато сте на почивка",
  "20% Unplug devices that are not operating": "20% Изключете от електрическата мрежа устройствата, които не работят",
  "40% Unplug devices that are not operating": "40% Изключете от електрическата мрежа устройствата, които не работят",
  "70% Unplug devices that are not operating": "70% Изключете от електрическата мрежа устройствата, които не работят",

  "Improve everyday mobility": "Подобряване на ежедневната мобилност",
  "20% Increase everyday's cycling": "20% Увеличете ежедневното колоездене",
  "40% Increase everyday's cycling": "40% Увеличете ежедневното колоездене",
  "70% Increase everyday's cycling": "70% Увеличете ежедневното колоездене",

  // Actions - Government
  "Capture and storage of CO2 from fossil fuel combustion" : "Улавяне и съхранение на CO2 от изгарянето на изкопаеми горива",
  "40% Capture and storage of CO2" : "40% Улавяне и съхранение на CO2",
  "60% Capture and storage of CO2" : "60% Улавяне и съхранение на CO2",
  "100% Capture and storage of CO2" : "100% Улавяне и съхранение на CO2",

  "Photovoltaic parks at petrol stations" : "Фотоволтаични паркове (инсталации) в бензиностанциите",
  "30% Installed photovoltaic parks at petrol stations" : "30% Инсталирани фотоволтаични паркове (инсталации) в бензиностанциите",
  "60% Installed photovoltaic parks at petrol stations" : "60% Инсталирани фотоволтаични паркове (инсталации) в бензиностанциите",
  "100% Installed photovoltaic parks at petrol stations" : "100% Инсталирани фотоволтаични паркове (инсталации) в бензиностанциите",

  "Wind Energy" : "Енергия генерирана от вятъра",
  "30% penetration from wind energy" : "30% проникване на вятърната енергия",
  "60% penetration from wind energy" : "60% проникване на вятърната енергияς",
  "90% penetration from wind energy" : "90% проникване на вятърната енергия",
  
  "Replacing cars with electric ones": "Замяна на автомобилите с електрически",
  "40% Replacing cars with electric ones": "40% Замяна на автомобилите с електрически",
  "60% Replacing cars with electric ones": "60% Замяна на автомобилите с електрически",
  "100% Replacing cars with electric ones": "100% Замяна на автомобилите с електрически",

  "Establish a speed limit of 30 km/h in cities": "Въвеждане на ограничение на скоростта от 30 км/ч в градовете",
  "20% Establish a speed limit of 30 km/h in cities": "20% Въвеждане на ограничение на скоростта от 30 км/ч в градовете",
  "60% Establish a speed limit of 30 km/h in cities": "60% Въвеждане на ограничение на скоростта от 30 км/ч в градовете",
  "100% Establish a speed limit of 30 km/h in cities": "100% Въвеждане на ограничение на скоростта от 30 км/ч в градовете",

  "Ιncreasing bike lanes in the city": "Увеличаване на велосипедните алеи в града",
  "20% Ιncreasing bike lanes in the city": "20% Увеличаване на велосипедните алеи в града",
  "40% Ιncreasing bike lanes in the city": "40% Увеличаване на велосипедните алеи в града",
  "100% Ιncreasing bike lanes in the city": "100% Увеличаване на велосипедните алеи в града",

  "Create green ports": "Зелени пристанища ",
  "20% of cities ports become Green ports": "20 % от градските пристанища да станат зелени пристанища",
  "40% of cities ports become Green ports": "40% от градските пристанища да станат зелени пристанища",
  "100% of cities ports become Green ports": "100% от градските пристанища да станат зелени пристанища",

  "Financial Assistance Program for Home Insulation Applications": "Програма за финансова помощ за изолация на жилища",
  "20% Improve Home Insulation": "20% подобрение на изолацията на домовете",
  "50% Improve Home Insulation": "50% подобрение на изолацията на домовете",
  "100% Improve Home Insulation": "100% подобрение на изолацията на домовете",

  "Financial Support Program for Replacing Domestic Heating Appliances": "Програма за финансова помощ за подмяна на отоплителните уреди на домакинствата",
  "20% Replacing fossil fuel boilers with heat pumps": "20% подмяна на котли на изкопаеми горива с термопомпи",
  "40% Replacing fossil fuel boilers with heat pumps": "40% подмяна на котли на изкопаеми горива с термопомпи",
  "100% Replacing fossil fuel boilers with heat pumps": "100% подмяна на котли на изкопаеми горива с термопомпи",

  // Actions - Local Government
  "Improvement in rail service quality (frequency,speed)": "Подобряване на качеството на железопътните услуги (честота, скорост)",
  "20% Increase in rail usage": "20% увеличение на използването на железопътния транспорт",
  "40% Increase in rail usage": "40% увеличение на използването на железопътния транспорт",
  "90% Increase in rail usage": "90% увеличение на използването на железопътния транспорт",

  "Improving the energy efficiency of existing buildings (goverment support programme)": "Подобряване на енергийната ефективност на съществуващите сгради (правителствена програма за подпомагане)",
  "20% Improving the energy efficiency of existing buildings": "20% Подобряване на енергийната ефективност на съществуващите сгради",
  "40% Improving the energy efficiency of existing buildings": "40% Подобряване на енергийната ефективност на съществуващите сгради",
  "90% Improving the energy efficiency of existing buildings": "90% Подобряване на енергийната ефективност на съществуващите сгради",

  "Increase Compostable stations": "Увеличаване на броя на местата за компостиране",
  "20% Increase Compostable stations": "20% Увеличаване на броя на местата за компостиране",
  "40% Increase Compostable stations": "40% Увеличаване на броя на местата за компостиране",
  "90% Increase Compostable stations": "90% Увеличаване на броя на местата за компостиране",

  "Bike-sharing programs at convenient locations": "Програми за споделени велосипеди на удобни места",
  "20% Increase bike usage": "20% Увеличаване на използването на велосипеди",
  "40% Increase bike usage": "40% Увеличаване на използването на велосипеди",
  "70% Increase bike usage": "70% Увеличаване на използването на велосипеди",

  "Start creating bicycle lanes": "Изграждане наповече велосипедни алеи",
  "20% expand bicycle lanes": "20% увеличаване на велосипедните алеи",
  "40% expand bicycle lanes": "40% увеличаване на велосипедните алеи",
  "90% expand bicycle lanes": "90% увеличаване на велосипедните алеи",

};

export default bgJSON;