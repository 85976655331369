const enJSON = {
    Blueprints: 'Plans',

    // Scenario Simulation
    "Scenario Simulation": "Scenario Simulation",
    "Emission text":"The European Commission's target of a 55% net reduction in greenhouse gas emissions by 2030",
    "% CO2 reduction" : "% CO2 reduction",
    "You've reached the end of the list!" : "You've reached the end of the list!",
    "Save Button Label" : "Save",
    "Empty Tabs Text" : "Select one of the tabs to continue",
    "Citizen Tab Text" : "Actions you are willing to make as a citizen",
    "Government Tab Text" : "Actions you are willing to accept from the government",  
    "Local Government Tab Text" : "Actions you are willing to accept from the local government",
    "Local Government" : "Local Government",
    "Current" : "Current",
    "Target" : "Target",
    "Example Scenarios Tooltip" : "Here, you can choose between example scenarios. From the slightest  effort ( min action scenario) to excessive effort (max action scenario) in reducing your carbon footprint.",
    "Emission Tooltip" : "Choose actions that you are willing to make, as well as actions that you are willing to accept from the government in order to reduce your carbon emissions",
    "Optimistic Scenario" : "Optimistic Scenario",
    "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly." : "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly.",
    "Pessimistic Scenario" : "Pessimistic Scenario",
    "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist." : "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist.",
    "Realistic Scenario" : "Realistic Scenario",
    "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist." : "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist.",


  // Landing Page
  "Landing Page Title": "Carbon Footprint Simulation Dashboard",
  "Landing Page Subtitle": "Together we can achieve clean air for all. Use the Carbon Footprint Simulation Dashboard and take action today.",
  "Get Started Button": "Get Started",
  "Explanation Title": "What is this Carbon Footprint Simulation Dashboard",
  "Explanation Paragraph 1": "The Carbon Footprint Simulation Dashboard consists of two tools. The first one is the Carbon Footprint Calculator. This tool is meant to help citizens understand how they affect their carbon footprint through their daily activities, as well as provide recommendations on how they can reduce their carbon footprint.",    
  "Explanation Paragraph 2": "The second tool is the Scenario Simulation Dashboard. This tool allows citizens to participate in policy making. It presents a set if actions they are willing to make, as well as actions they are willing to accept from the government with the purpose of reducing carbon emissions. Citizens can choose which actions they believe they are willing to adopt and that creates a scenario they can submit, which policy makers can gain insight from.",
  "Keep track of your carbon footprint by signing up.": "Keep track of your carbon footprint by signing up.",
  "Sign Up Now": "Sign Up Now",
  "Sign In": "Sign In",


  // CO2 Calculator - Tab Names
  "Welcome": "Welcome",
  "Demographic": "General",
  "Cars": "Cars",
  "Flights": "Flights",
  "Trains": "Trains",
  "Buildings": "Buildings",
  "Waste Management": "Waste Management",
  "Results": "Results",

  // CO2 Calculator - Welcome
  "CO2 Calculator": "CO2 Calculator",
  "Country Question": "First, please tell us where do you live?",
  "Country Option": "Country",
  "Greece": "Greece",
  "greece": "Greece",
  "GREECE": "Greece",
  "Germany": "Germany",
  "germany": "Germany",
  "GERMANY": "Germany",
  "Belgium": "Belgium",
  "belgium": "Belgium",
  "BELGIUM": "Belgium",
  "Bulgaria": "Bulgaria",
  "bulgaria": "Bulgaria",
  "BULGARIA": "Bulgaria",
  "From": "From",
  "To": "To",
  "Date Explanation": "Carbon footprint calculations are typically based on annual emissions from the previous 12 months. If you would like to calculate your carbon footprint for a different period use the calendar boxes below:",
  "Next Steps": "Next, select the appropriate tab above to calculate the part of your lifestyle you are most interested in, e.g. your flights. Or, visit each of the tabs above to calculate your full carbon footprint. Following your calculation, you can offset / neutralise your emissions through one of our climate-friendly projects",

  // CO2 Calculator - Demographic
  "Demographic Characteristics": "General Information",
  "City Question": "In which city do you live?",
  "City Option": "City",
  "Athens": "Athens",
  "athens": "Athens",
  "Brussels": "Brussels",
  "brussels": "Brussels",
  "Berlin": "Berlin",
  "berlin": "Berlin",
  "Plovdiv": "Plovdiv",
  "plovdiv": "Plovdiv",
  "Sofia": "Sofia",
  "sofia": "Sofia",
  "Other city or village": "Other city or village",
  "Age Question": "What is your age?",
  "Age Option": "Age",
  // add the age options
  "Below 18": "Below 18",
  "Above 65": "Above 65",
  "Gender Question": "What is your gender?",
  "Gender Option": "Gender",
  // add the age options
  "Male": "Male",
  "Female": "Female",
  "Non-binary": "Non-binary",
  "Do not wish to specify": "Do not wish to specify",
  "Marital Status Question": "What is your marital status?",
  "Marital Status Option": "Marital Status",
  // add the marital status options
  "Social Aid Question": "Are you receiving some sort of social aid?",
  "Social Aid Option": "Social Aid",
  "Education Level Question": "What is your education level?",
  "Education Level Option": "Education Level",
  "Primary Education": "Primary Education",
  "Secondary Education": "Secondary Education",
  "University Education": "University Education",
  "Postgraduate Education": "Postgraduate Education",
  "Municipal Communities Question": "In which Municipal Community do you live?",
  "Municipal Communities Option": "Municipal Community",
  "Previous": "Previous",
  "Next": "Next",
  "How many times per week on average do you use the metro?": "How many times per week on average do you use the metro",
  "How many times per week on average do you use the bus?": "How many times per week on average do you use the bus?",
  "How many times per week on average do you use the tram?": "How many times per week on average do you use the tram?",
  "How many times per week on average do you use a bicycle?": "How many times per week on average do you use a bicycle?", 
  "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.": "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.", 


  // CO2 Calculator - Cars
  "CO2 from Cars": "CO2 from Cars",
  "Vehicles Info Text": "Road transport is responsible for 1/5 of the EU's total CO2 emissions. Did you know that passenger cars are responsible for 75% of them?",
  "Distance Question": "Distance Travelled (km per designated period)",
  "Liters Question": "Liters/100 KM",
  "Type of Fuel Question": "Type of Fuel", // used for Buildings as well
  "Type of Fuel Option": "Type of Fuel", // used for Buildings as well
  // "Type of Fuel Option": "Fuel Type", // used for Buildings as well
  "Diesel": "Diesel",
  "Petrol": "Petrol",
  "LPG": "LPG",
  "CNG": "CNG",
  "Petrol + E10": "Petrol + E10",
  "Public Transport Question": "Public Transportation",
  "Electric Car Question": "Electric Car",
  "Liters/100km or kWh/100km": "Liters/100km or kWh/100km",
  "Electricity": "Electricity",
  "Add Vehicle": "Add Vehicle",
  "Remove Last Vehicle": "Remove Last Vehicle",
  "CO2 from Vehicles": "CO2 from Vehicles",
  "Vehicle": "Vehicle",
  "Vehicles": "Vehicles",
  "Required": "Required",


  // CO2 Calculator - Flights
  "CO2 from Flights": "CO2 from Flights",
  "Flights Info Text": "How many domestic, short, medium or long haul flights have you taken for the chosen period?",
  "Flight Description": "Description (Flight Duration)",
  "Number of Flights": "Number of Flights",
  "Avg Flight Distance": "Average distance of flight (km)",
  "Domestic": "Domestic",
  "Short Haul Flight": "Short Haul (<3 hours)",
  "Medium Haul Flight": "Medium Haul (3-6 hours)",
  "Long Haul Flight": "Long Haul (6-12 hours)",
  "Flights Info Text 2": "Connecting flights and return flights count as a flight on their own.",


  // CO2 Calculator - Trains
  "CO2 from Trains": "CO2 from Trains",
  "Trains Info Text": "How often do you travel by train?",
  "Number of Trips": "Number of Trips",
  "Avg Trip Distance": "Average Distance of Trip (km)",
  
  
  // CO2 Calculator - Buildings
  "CO2 from Buildings": "CO2 from Buildings",
  "Fuel per Year": "What is your fuel consumption for the designated period?",
  "Fuel per Year Tooltip": "What is your fuel consumption for the designated period?",
  "Type of Fuel": "Type of Fuel",
  "Type of Fuel Tooltip": "What type of heating source do you use in your house?",
  "Heating Oil": "Heating Oil",
  "Coal": "Coal",
  "Biomass": "Biomass",
  "Natural Gas": "Natural Gas",
  "Not using any": "Not using any",
  "Electricity Buildings": "Electricity (kWh per designated period)",
  "Solar Thermal": "Use of solar for water heating",
  "Heat Pump": "Heat Pump",
  "Is your refrigerator energy efficient?": "Is your refrigerator energy efficient?",
  "Is your washing machine energy efficient?": "Is your washing machine energy efficient?",
  "Is your dishwasher energy efficient?": "Is your dishwasher energy efficient?",
  "Is your oven energy efficient?": "Is your oven energy efficient",
  "No": "No",
  "Semi-efficient": "Semi-efficient",
  "Yes": "Yes",
  "I don't know": "I don't know",
  "I don't own one": "I don't own one",
  "Regarding Energy Efficiency Labels": "Regarding Energy Efficiency Labels",
  "Match table explanation": "On the 1st of March 2021, the Energy Efficiency Labels for appliances have changed. Below you can find a simple matching table to help you match your appliance's energy class if it has been bought before the 1st of March 2021.",
  "Refrigerator": "Refrigerator",
  "Before 2021": "Before 2021",
  "After 2021": "After 2021",
  "Washing Machine": "Washing Machine",
  "Dishwasher": "Dishwasher",
  "Oven": "Oven",
  "The energy classes for ovens remain the same": "The energy classes for ovens remain the same",

  // CO2 Calculator - Waste Management
  "CO2 from Waste Management": "CO2 from Waste Management",
  "The average citizen in": "The average citizen in",
  "recycles": "recycles",
  "Kgs of waste": "Kgs of waste",
  "and composts": "and composts",
  "Recycle Tooltip": "How many paper, glass, plastic , tin cans do you recycle?",
  "Compost Tooltip": "How much kitchen waste do you compost?",
  "Choose": "Choose",
  "Recycling": "Recycling",
  "No recycling": "No recycling",
  "Less than average recycling": "Less than average recycling",
  "Same as average recycling": "Same as average recycling",
  "More than average recycling": "More than average recycling",
  "Composting": "Composting",
  "No composting": "No composting",
  "Less than average composting": "Less than average composting",
  "Same as average composting": "Same as average composting",
  "More than average composting": "More than average composting",
  "Calculate": "Calculate",
  "That is the equivalent of": "That is the equivalent of",
  "30lt trash bags": "30lt trash bags",
  "and": "and",

  // CO2 Calculator - Completion Alerts
  "Calculated successfully": "Calculated successfully!",
  "Check Results": "Calculated successfully! You can check the Results tab to see the applied changes.",
  "Uncompleted fields": "Uncompleted fields!", 
  "Fill out fields": "Please fill out all of the required fields in order to calculate the results",

  
  // CO2 Calculator - Results
  "CO2 Calculator Results": "CO2 Calculator Results",
  "Calculator Results": "Calculator Results",
  "Save": "Save",
  "Total": "Total",
  "User": "User",
  "Waste": "Waste",
  "Transportation": "Transportation",
  "For more information": "For more information",
  "Your results compared to your country's average": "Your results compared to your country's average",
  "You": "You",
  "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.": "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.",

  // Profile
  "Calculations List Title": "Calculations",
  "Your Calculator Results": "Your Calculator Results",
  "tons CO2 per domain": "tons CO2 per domain",
  "tons CO2": "tons CO2",
  "Kilometers/Year": "Kilometers/Year",
  "Liters/100 Km": "Liters/100 Km",
  "Full Name": "Full Name",
  "e-mail": "e-mail",
  "Short Haul": "Short Haul",
  "Medium Haul": "Medium Haul",
  "Long Haul": "Long Haul",
  "Europe": "Europe",
  "Export": "Export",
  "Scenario": "Scenario",
  "Energy Efficient Refrigerator": "Energy Efficient Refrigerator",
  "Energy Efficient Washing Machine": "Energy Efficient Washing Machine",
  "Energy Efficient Dishwasher": "Energy Efficient Dishwasher",
  "Energy Efficient Oven": "Energy Efficient Oven",
  "Car": "Car",
  "More Info": "More Info",
  
  
  // Admin Page - 
  "Percentage": "Percentage",
  "Recommendations": "Recommendations",
  "Scenarios": "Scenarios",
  "Actions": "Actions",
  "Statistics": "Statistics",

  // Admin Page - Scenarios
  "Scenario Explanation": "In this page you can create your own scenarios, by choosing actions from the adjacent column. You can also update the existing scenarios.",
  "Select slider action": "Select slider action",    
  "Please select the appropriate levels of the actions for your scenario.": "Please select the appropriate levels of the actions for your scenario.",
  "Add Scenario": "Add Scenario",
  "Cancel": "Cancel",
  "Update": "Update",
  "Delete": "Delete",
  "Please enter a title and a description for your scenario.": "Please enter a title and a description for your scenario.",
  "Citizen": "Citizen",
  "Government": "Government",
  "Scenario Title": "Scenario Title",
  "Scenerio Description": "Scenerio Description",
  

  // Admin Page - Actions
  "Actions Title Text" : "Actions",
  "Actions Title Text secondary" : "Select action",
  "Action Label" : "Action Label",
  "Action Contribution Admin Page Text": "0 <= input <= 1",
  "Action Title 1 Admin Page Text" : "",
  "Action Title 2 Admin Page Text" : "",
  "Action Title 3 Admin Page Text" : "",
  "Action Percentage 1 Admin Page Text" : "",
  "Action Percentage 2 Admin Page Text" : "",
  "Action Percentage 3 Admin Page Text" : "",
  "Empty Fields Error Text" : "Fields cannot be empty",
  "Clear" : "Clear",
  "Category" : "Category",
  "Contribution" : "Contribution",
  "Title" : "Title",
  "Add action" : "Add action",
  "No Action" : "No Action",
  "First Action" : "First Action",
  "Second Action" : "Second Action",
  "Third Action" : "Third Action",
  "Please choose the percentage of the implementation of your action" : "Please choose the percentage of the implementation of your action",
  "You have to enter a number between 0 and 1 that represents a percentage" : "You have to enter a number between 0 and 1 that represents a percentage",
  "Please choose the percentage of the implementation of the first stage of your action": "Please choose the percentage of the implementation of the first stage of your action",
  "Please choose the percentage of the implementation of the second stage of your action": "Please choose the percentage of the implementation of the second stage of your action",
  "Please choose the percentage of the implementation of the third stage of your action": "Please choose the percentage of the implementation of the third stage of your action",
  "Please add the title of the new action.": "Please add the title of the new action.",

  // Admin Page - Recommendations
  "Add Link" : "Add Link",
  "Remove Last Link" : "Remove Last Link",
  "Link" : "Link",
  "Please enter the text for your new recommendation." : "Please enter the text for your new recommendation.",
  "Recommendation Text" : "Recommendation Text",
  "Add Case" : "Add Case",
  "Add Recommendation" : "Add Recommendation",
  "Operator" : "Operator",
  "Value" : "Value",
  "Country" : "Country",
    

  // Admin Page - Statistics
  "Stage" : "Stage",
  "Stage 0" : "Stage 0",
  "Stage 1" : "Stage 1",
  "Stage 2" : "Stage 2",
  "Stage 3" : "Stage 3",
  "Select Country" : "Select Country",
  "Select City" : "Select City",
  "Select Municipal Region" : "Select Municipal Region",
  "Select Age" : "Select Age",
  "Select Gender" : "Select Gender",
  "Select Social Aid" : "Select Social Aid",
  "Select Education Level" : "Select Education Level",
  "Scenario Simulation Statistics" : "Scenario Simulation Statistics",
  "Carbon Footprint Calculator Statistics" : "Carbon Footprint Calculator Statistics",
  "Average" : "Average",
  

  // Recommendations
  "Consider replacing old fluorescent lighting with LEDs" : "Consider replacing old fluorescent lighting with LEDs",
  "Consider switching to Energy Efficient appliances" : "Consider switching to Energy Efficient appliances",
  "Consider using smart power strips" : "Consider using smart power strips",
  "Consider turning down the heating by 1 degree Celsius" : "Consider turning down the heating by 1 degree Celsius",
  "Consider unpluging electronical equipment when fully charged" : "Consider unpluging electronical equipment when fully charged",
  "Consider insulating your home" : "Consider insulating your home",
  "Consider increasing cycling by 30% (micromobility)" : "Consider increasing cycling by 30% (micromobility)",
  "Consider using the train for medium length distances (insted of the car)" : "Consider using the train for medium length distances (insted of the car)",
  "Consider carpooling to work" : "Consider carpooling to work",
  "Consider using public transport in your daily routine" : "Consider using public transport in your daily routine",
  "Consider replacing all business and first class trips by economy class trips" : "Consider replacing all business and first class trips by economy class trips",
  "Consider replacing indirect flights with direct flights" : "Consider replacing indirect flights with direct flights",
  "Consider reducing air travel by using trains or ferries" : "Consider reducing air travel by using trains or ferries",
  "Consider using a reusable bad to the market" : "Consider using a reusable bag to the market",
  "Consider shopping at a bulk foods store" : "Consider shopping at a bulk foods store",
  "Good job!" : "Good job!",
  "Consider reducing the use of single plastics!" : "Consider reducing the use of single plastics!",
  "Consider using public transport" : "Consider using public transport",
  "Consider car sharing" : "Consider car sharing",
  "Consider replacing your washing machine with an energy efficient one" : "Consider replacing your washing machine with an energy efficient one",
  "Consider replacing your oven with an energy efficient one" : "Consider replacing your oven with an energy efficient one",
  "Consider replacing your refrigerator with an energy efficient one" : "Consider replacing your refrigerator with an energy efficient one",
  "Consider replacing your dishwasher with an energy efficient one" : "Consider replacing your dishwasher with an energy efficient one",
  "Consider reducing the use of single plastics":"Consider reducing the use of single plastics",
  "Consider using a reusable bag to the market":"Consider using a reusable bag to the market",
  "Consider purchasing preused items and/or materials":"Consider purchasing preused items and/or materials",
  "Consider circular products instead of single use":"Consider circular products instead of single use",
  "Consider products made of regenerated material sources": "Consider products made of regenerated material sources",
  "Consider less use of air-condition": "Consider less use of air-condition",
  "Consider using your own thermo for coffee/tea/water takeaway": "Consider using your own thermo for coffee/tea/water takeaway",
  "Consider using the composting bins of the city or your own": "Consider using the composting bins of the city or your own",
  "Consider performing maintenance on your heating system": "Consider performing maintenance on your heating system",
  "Consider upgrading your heating system": "Consider upgrading your heating system",
  "Consider using a more efficient vehicle": "Consider using a more efficient vehicle",
  "Consider donating surplus production out of your garden or field": "Consider donating surplus production out of your garden or field",
  "Consider sharing formulas instead of purchasing (shared vehicles, machines...)": "Consider sharing formulas instead of purchasing (shared vehicles, machines...)",

  // Actions - Citizen
  "Improve Transportation Habits" : "Improve Transportation Habits",
  "20% increased Carpoolling" : "20% increased Carpoolling",
  "50% increased Carpoolling" : "50% increased Carpoolling",
  "70% increased Carpoolling" : "70% increased Carpoolling",

  "Change Flying Habits" : "Change Flying Habits",
  "20% Replace business class with economy" : "20% Replace business class with economy",
  "50% Replace business class with economy" : "50% Replace business class with economy",
  "90% Replace business class with economy" : "90% Replace business class with economy",

  "Improve Flying Habits" : "Improve Flying Habits",
  "20% Replace indirect flights with direct flights" : "20% Replace indirect flights with direct flights",
  "50% Replace indirect flights with direct flights" : "50% Replace indirect flights with direct flights",
  "90% Replace indirect flights with direct flights" : "90% Replace indirect flights with direct flights",
  
  "Change Travelling Habits" : "Change Travelling Habits",
  "20% Train use for medium length distances" : "20% Train use for medium length distances",
  "50% Train use for medium length distances" : "50% Train use for medium length distances",
  "90% Train use for medium length distances" : "90% Train use for medium length distances",
  
  "Lighting Habits" : "Lighting Habits",
  "20% Replace old fluorescent lighting with LEDs" : "20% Replace old fluorescent lighting with LEDs",
  "50% Replace old fluorescent lighting with LEDs" : "50% Replace old fluorescent lighting with LEDs",
  "100% Replace old fluorescent lighting with LEDs" : "100% Replace old fluorescent lighting with LEDs",
  
  "Appliances Habits" : "Appliances Habits",
  "20% Energy Efficient appliances" : "20% Energy Efficient appliances",
  "50% Energy Efficient appliances" : "50% Energy Efficient appliances",
  "100% Energy Efficient appliances" : "100% Energy Efficient appliances",
  
  "Insulation Habits" : "Insulation Habits",
  "20% Insulation Thickness" : "20% Insulation Thickness",
  "50% Insulation Thickness" : "50% Insulation Thickness",
  "100% Insulation Thickness" : "100% Insulation Thickness",

  "Improve Recycling Habits" : "Improve Recycling Habits", // needs fix
  "20% Increased recycling of plastics + paper + metal cans + glass" : "20% Increased recycling of plastics + paper + metal cans + glass",
  "40% Increased recycling of plastics + paper + metal cans + glass" : "40% Increased recycling of plastics + paper + metal cans + glass",
  "70% Increased recycling of plastics + paper + metal cans + glass" : "70% Increased recycling of plastics + paper + metal cans + glass",

  "Introduce composting in everyday life": "Introduce composting in everyday life",
  "20% compost of organic waste by placing a bin": "20% compost of organic waste by placing a bin",
  "40% compost of organic waste by placing a bin": "40% compost of organic waste by placing a bin",
  "70% compost of organic waste by placing a bin": "70% compost of organic waste by placing a bin",

  "Unplug devices that are not used or when on vacation": "Unplug devices that are not used or when on vacation",
  "20% Unplug devices that are not operating": "20% Unplug devices that are not operating",
  "40% Unplug devices that are not operating": "40% Unplug devices that are not operating",
  "70% Unplug devices that are not operating": "70% Unplug devices that are not operating",

  "Improve everyday mobility": "Improve everyday mobility",
  "20% Increase everyday's cycling": "20% Increase everyday's cycling",
  "40% Increase everyday's cycling": "40% Increase everyday's cycling",
  "70% Increase everyday's cycling": "70% Increase everyday's cycling",

  // Actions - Government
  "Capture and storage of CO2 from fossil fuel combustion" : "Capture and storage of CO2 from fossil fuel combustion",
  "40% Capture and storage of CO2" : "40% Capture and storage of CO2",
  "60% Capture and storage of CO2" : "60% Capture and storage of CO2",
  "100% Capture and storage of CO2" : "100% Capture and storage of CO2",
  
  "Photovoltaic parks at petrol stations" : "Photovoltaic parks at petrol stations",
  "30% Installed photovoltaic parks at petrol stations" : "30% Installed photovoltaic parks at petrol stations",
  "60% Installed photovoltaic parks at petrol stations" : "60% Installed photovoltaic parks at petrol stations",
  "100% Installed photovoltaic parks at petrol stations" : "100% Installed photovoltaic parks at petrol stations",
  
  "Wind Energy" : "Wind Energy",
  "30% penetration from wind energy" : "30% penetration from wind energy",
  "60% penetration from wind energy" : "60% penetration from wind energy",
  "90% penetration from wind energy" : "90% penetration from wind energy",

  "Replacing cars with electric ones": "Replacing cars with electric ones",
  "40% Replacing cars with electric ones": "40% Replacing cars with electric ones",
  "60% Replacing cars with electric ones": "60% Replacing cars with electric ones",
  "100% Replacing cars with electric ones": "100% Replacing cars with electric ones",

  "Establish a speed limit of 30 km/h in cities": "Establish a speed limit of 30 km/h in cities",
  "20% Establish a speed limit of 30 km/h in cities": "20% Establish a speed limit of 30 km/h in cities",
  "60% Establish a speed limit of 30 km/h in cities": "60% Establish a speed limit of 30 km/h in cities",
  "100% Establish a speed limit of 30 km/h in cities": "100% Establish a speed limit of 30 km/h in cities",

  "Ιncreasing bike lanes in the city": "Ιncreasing bike lanes in the city",
  "20% Ιncreasing bike lanes in the city": "20% Ιncreasing bike lanes in the city",
  "40% Ιncreasing bike lanes in the city": "40% Ιncreasing bike lanes in the city",
  "100% Ιncreasing bike lanes in the city": "100% Ιncreasing bike lanes in the city",

  "Create green ports": "Create green ports",
  "20% of cities ports become Green ports": "20% of cities ports become Green ports",
  "40% of cities ports become Green ports": "40% of cities ports become Green ports",
  "100% of cities ports become Green ports": "100% of cities ports become Green ports",

  "Financial Assistance Program for Home Insulation Applications": "Financial Assistance Program for Home Insulation Applications",
  "20% Improve Home Insulation": "20% Improve Home Insulation",
  "50% Improve Home Insulation": "50% Improve Home Insulation",
  "100% Improve Home Insulation": "100% Improve Home Insulation",

  "Financial Support Program for Replacing Domestic Heating Appliances": "Financial Support Program for Replacing Domestic Heating Appliances",
  "20% Replacing fossil fuel boilers with heat pumps": "20% Replacing fossil fuel boilers with heat pumps",
  "40% Replacing fossil fuel boilers with heat pumps": "40% Replacing fossil fuel boilers with heat pumps",
  "100% Replacing fossil fuel boilers with heat pumps": "100% Replacing fossil fuel boilers with heat pumps",

  // Actions - Local Government
  "Improvement in rail service quality (frequency,speed)": "Improvement in rail service quality (frequency,speed)",
  "20% Increase in rail usage": "20% Increase in rail usage",
  "40% Increase in rail usage": "40% Increase in rail usage",
  "90% Increase in rail usage": "90% Increase in rail usage",

  "Improving the energy efficiency of existing buildings (goverment support programme)": "Improving the energy efficiency of existing buildings (goverment support programme)",
  "20% Improving the energy efficiency of existing buildings": "20% Improving the energy efficiency of existing buildings",
  "40% Improving the energy efficiency of existing buildings": "40% Improving the energy efficiency of existing buildings",
  "90% Improving the energy efficiency of existing buildings": "90% Improving the energy efficiency of existing buildings",

  "Increase Compostable stations": "Increase Compostable stations",
  "20% Increase Compostable stations": "20% Increase Compostable stations",
  "40% Increase Compostable stations": "40% Increase Compostable stations",
  "90% Increase Compostable stations": "90% Increase Compostable stations",

  "Bike-sharing programs at convenient locations": "Bike-sharing programs at convenient locations",
  "20% Increase bike usage": "20% Increase bike usage",
  "40% Increase bike usage": "40% Increase bike usage",
  "70% Increase bike usage": "70% Increase bike usage",

  "Start creating bicycle lanes": "Start creating bicycle lanes",
  "20% expand bicycle lanes": "20% expand bicycle lanes",
  "40% expand bicycle lanes": "40% expand bicycle lanes",
  "90% expand bicycle lanes": "90% expand bicycle lanes",

};

export default enJSON;