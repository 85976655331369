import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShoePrints } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useEffect } from "react";
import axios from "axios";


// const Dashboard = ({ carE, trainE, buildingE, wasteE, flightE }) => {
const Dashboard = ({country}) => {

    
  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev/"
  });

  // eslint-disable-next-line
  const [selectedCountryData, setSelectedCountryData] = useState({ data: [] });
  // eslint-disable-next-line
  const [avgTotalCO2, setAvgTotalCO2] = useState(0);
// eslint-disable-next-line
  const [avgTransportationResults, setAvgTransportationResults] = useState(0);
// eslint-disable-next-line
  const [avgFlightResults, setAvgFlightResults] = useState(0);
// eslint-disable-next-line
  const [avgTrainResults, setAvgTrainResults] = useState(0);
// eslint-disable-next-line
  const [avgBuildingResults, setAvgBuildingResults] = useState(0);
  // eslint-disable-next-line
  const [avgWasteResults, setAvgWasteResults] = useState(0);

    
  useEffect(() => {

    if (country === 'BELGIUM') {
      client.get("/belgiumUnique")
          .then((response) => {
            setSelectedCountryData(() => {
              return response.data;
            });
          });
    }

    if (country === 'BULGARIA') {
      client.get("/bulgariaUnique")
      .then((response) => {
        setSelectedCountryData(() => {
            return response.data;
          });
      });
    }

    if (country === 'GERMANY') {
      client.get("/germanyUnique")
      .then((response) => {
        setSelectedCountryData(() => {
            return response.data;
          });
      });
    }

    if (country === 'GREECE') {
      client.get("/greeceUnique")
      .then((response) => {
        setSelectedCountryData(() => {
            return response.data;
          });
      });
    }

}, [country]);


useEffect(() => {

  // let responseData;

  let count = 0;
  let totalCO2 = 0;
  let transportationResults = 0;
  let flightResults = 0;
  let trainResults = 0;
  let buildingResults = 0;
  let wasteResults = 0;
  let avgTotalCO2_;
  let avgTransportationResults_;
  let avgFlightResults_;
  let avgTrainResults_;
  let avgBuildingResults_;
  let avgWasteResults_;


  if (selectedCountryData.length > 0) {
    selectedCountryData.forEach(item => {
      count ++;
      totalCO2 += parseFloat(item.totalCO2);
      transportationResults += item.transportationResults;
      flightResults += item.flightResults;
      trainResults += item.trainResults;
      buildingResults += item.buildingResults;
      wasteResults += item.wasteResults;
    });

    avgTotalCO2_ = (totalCO2/count).toFixed(2);
    avgTransportationResults_ = (transportationResults/count).toFixed(2);
    avgFlightResults_ = (flightResults/count).toFixed(2);
    avgTrainResults_ = (trainResults/count).toFixed(2);
    avgBuildingResults_ = (buildingResults/count).toFixed(2);
    avgWasteResults_ = (wasteResults/count).toFixed(2);

    setAvgTotalCO2(avgTotalCO2_);
    setAvgTransportationResults(avgTransportationResults_);
    setAvgFlightResults(avgFlightResults_);
    setAvgTrainResults(avgTrainResults_);
    setAvgBuildingResults(avgBuildingResults_);
    setAvgWasteResults(avgWasteResults_);

  }

}, [selectedCountryData]);

  const { t } = useTranslation();

  const carPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-primary text-white">
        { t("Vehicles") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {/* { t("Vehicles") + ': ' +
              carD +
              ' / '+ t("Total") + ': ' +
              (carD + buildingE + wasteE + trainE).toFixed(2) +
              t("tons CO2")} */}
            { t("Vehicles") + ': ' +
              avgTransportationResults +
              ' / '+ t("Total") + ': ' +
            //   (avgTotalCO2).toFixed(2) +
              avgTotalCO2 +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((avgTransportationResults * 100) / avgTotalCO2).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const flightPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-danger text-white">
        { t("Flights") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
          { t("Flights") + ': ' +
              avgFlightResults +
              ' / '+ t("Total") + ': ' +
              avgTotalCO2 +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((avgFlightResults * 100) / avgTotalCO2).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const trainPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-success text-white">
      { t("Trains") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Trains") + ': ' +
              avgTrainResults +
              ' / '+ t("Total") + ': ' +
              avgTotalCO2 +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((avgTrainResults * 100) / avgTotalCO2).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const buildingPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-warning text-white">
        { t("Buildings") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Buildings")   + ': ' +
              avgBuildingResults +
              ' / '+ t("Total") + ': ' +
              avgTotalCO2 +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((avgBuildingResults * 100) / avgTotalCO2).toFixed(2)
                 +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const wastePop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-info text-white">
        { t("Waste") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {t("Waste")   + ': ' +
              avgWasteResults +
              ' / '+ t("Total") + ': ' +
              avgTotalCO2 +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((avgWasteResults * 100) / avgTotalCO2).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const progressStyles = {
    width: '20%',
    height: '100%',
  };

  return (
    // <div>
    <Grid container alignItems="flex-end" justify="flex-start" spacing={1}>

      <Grid item style={{ paddingTop: '16px', width: '100px' }}>
          <span style={{ fontSize: '1.5rem', marginLeft: '-35px' }}>{ t("Dashboard") }</span>
      </Grid>

      <Grid item xs className="ml-3">
        <div className="progress" style={{ height: '20px', backgroundColor: '#00000000' }}>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Vehicles")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Flights")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Trains")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Buildings")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Waste")}
          </div>
        </div>

      <div className="progress" style={{ height: '20px' }}>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={carPop}
        >
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>

              <div
                className="progress-bar text-wrap" 
                role="button"
                style={{
                  width:
                    ((avgTransportationResults * 100) / avgTotalCO2) + '%',
                  backgroundColor: '#0D6EFD'
                }}
                
              >
                {/* { t("Cars") } */}
                &nbsp;
              </div>
              </div>          

        </OverlayTrigger>


        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={flightPop}
        >
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                  ((avgFlightResults * 100) / avgTotalCO2) + '%',
                  backgroundColor: '#DC3545'
                }}
              >
                &nbsp;
              </div>          
            </div>

        </OverlayTrigger>


        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={trainPop}
        >
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
            
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                  ((avgTrainResults * 100) / avgTotalCO2) + '%',
                  backgroundColor: '#198754'
                }}
              >
                {/* { t("Trains") } */}
                &nbsp;
              </div>
            </div>  

        </OverlayTrigger>

      
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={buildingPop}
        >

            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                  ((avgBuildingResults * 100) / avgTotalCO2) + '%',
                  backgroundColor: '#FFC107'
                }}
              >
                &nbsp;
              </div>
            </div>
        </OverlayTrigger>
        
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={wastePop}
        >

          <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
            <div
              className="progress-bar text-wrap"
              role="button"
              style={{
                width:
                ((avgWasteResults * 100) / avgTotalCO2) + '%',
                backgroundColor: '#0DCAF0'
              }}
            >
              &nbsp;
            </div>
          </div>
        </OverlayTrigger>
      </div>
      </Grid>
      </Grid>
  );
};

export default Dashboard;
