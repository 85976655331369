import { useState } from 'react';
import Range from "./tuners/Range";
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Card,
  Typography,
  List,
  Button,
  Divider,
  ToggleButtonGroup,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
 
function ActionListUser({ handleChart, sliders, saveUserScenario, saveButtonEnabled  }) {

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: 'primary'
    }
  });

  const { t } = useTranslation();
  const theme = useTheme();

  const [tabs, setTab] = useState('activity');

  const handleViewOrientation = (_event, newValue) => {
    setTab(newValue);
  };

  const citizenSliders = []
  const governmentSliders = []
  const localGovernmentSliders = []

  sliders.forEach(slider => {
    let category = slider["category"]
    if (category === "Citizen") {
      citizenSliders.push(slider)
    } 
    if (category === "Government") {
      governmentSliders.push(slider)
    }
    if (category === "localGov") {
      localGovernmentSliders.push(slider)
    }
  });

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
    ({ theme }) => `
      overflow-x: auto;
      // Add your scrollbar styles here
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-track {
        background-color: ${theme.palette.background.paper};
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.primary.main};
        border-radius: 6px;
        border: 3px solid ${theme.palette.background.paper};
      }
    `
  );
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  return (
    <Card>
      <Box
        p={2.2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('Actions Title Text')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Actions Title Text secondary')}
          </Typography>
        </Box>
        <div style={{ width: '100%', overflowX: 'auto' }}>
        <StyledToggleButtonGroup 
          size="small"
          value={tabs}
          exclusive
          onChange={handleViewOrientation}
          
        >
          
            <ToggleButton
              sx={{
                px: 4,
                py: 0.5,
                lineHeight: 1.5,
                fontSize: `${theme.typography.pxToRem(12)}`,
              }}
              disableRipple
              value="activity"
            >
              <Tooltip title={t('Citizen Tab Text')}>
                <span>{t('Citizen')}</span>
              </Tooltip>
              
            </ToggleButton>
          

          
            <ToggleButton
              sx={{
                px: 2,
                py: 0.5,
                lineHeight: 1.5,
                fontSize: `${theme.typography.pxToRem(12)}`
              }}
              disableRipple
              value="trading"
            >
              <Tooltip title={t('Government Tab Text')}>
                <span>{t('Government')}</span>
              </Tooltip>
            </ToggleButton>


            <ToggleButton
              sx={{
                px: 2,
                py: 0.5,
                lineHeight: 1.5,
                fontSize: `${theme.typography.pxToRem(12)}`
              }}
              disableRipple
              value="localGov"
            >
              <Tooltip title={t('Local Government Tab Text')}>
                <span>{t('Local Government')}</span>
              </Tooltip>
            </ToggleButton>

        </StyledToggleButtonGroup >
        </div>
      </Box>
      <Divider />

      {tabs === 'activity' && (
        <>
          <Box
            sx={{
              height: 460 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>

                {
                  citizenSliders.map((cslider) => (
                    <>
                     
                        <Range
                          id={cslider._id}
                          name={cslider["name"]["en"]}
                          title= {cslider["titles"]["en"][cslider.value]}
                          value={cslider.value}
                          handleChart = {handleChart}
                        />

                        <Divider />
                    </>
                  ))}
              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list!")}
                </Typography>
              </Box>
            </Scrollbar>
          </Box>
          <Divider />

          <Box
            p={2}
            sx={{
              textAlign: 'center'
            }}
          >
            <Button
              sx={{
                textTransform: 'uppercase',
                fontSize: `${theme.typography.pxToRem(12)}`
              }}
              variant="contained"
              endIcon={<KeyboardArrowRightTwoToneIcon />}
              onClick={() => {
                saveUserScenario();
                setSnackbarOpen(true);
              }}
              disabled={!saveButtonEnabled} // Disable the button if saveButtonEnabled is false
              >
              {t('Save Button Label')}
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000} // Adjust the duration as needed
              onClose={() => setSnackbarOpen(false)}
              message={t("Scenario saved successfully")}
              action={
                <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            />

          </Box>
        </>
      )}
      
      {
      /* 
      tabs === 'add' && (
        <>
          <Box
            sx={{
              height: 515
            }}
          >
           <SliderAdder addSlider={addSlider}/>
          </Box>
         
        </>
          )
          */
          }
      {tabs === 'trading' && (
       <>
       <Box
         sx={{
           height: 440
         }}
       >
         <Scrollbar>

           <List disablePadding>

             {
               governmentSliders.map((cslider) => (
                 <>
                  
                     <Range
                       id={cslider._id}
                      name={(() => {
                        const translatedName = t(cslider["name"]["en"]);
                        return translatedName;
                      })()}
                      title={(() => {
                        const translatedTitle = t(cslider["titles"]["en"][cslider.value]);
                        return translatedTitle;
                      })()}
                       value={cslider.value}
                       handleChart = {handleChart}
                     />

                     <Divider />

                 </>
               ))}
           </List>
           <Box
             px={2}
             py={3}
             sx={{
               textAlign: 'center'
             }}
           >
             <Typography variant="subtitle2">
             {t("end of list text")}
             </Typography>
           </Box>
         </Scrollbar>
       </Box>
       <Divider />
       <Box
         p={2}
         sx={{
           textAlign: 'center'
         }}
       >
         <Button
              sx={{
                textTransform: 'uppercase',
                fontSize: `${theme.typography.pxToRem(12)}`
              }}
              variant="contained"
              endIcon={<KeyboardArrowRightTwoToneIcon />}
              onClick={() => {
                saveUserScenario();
                setSnackbarOpen(true);
              }}
              disabled={!saveButtonEnabled} // Disable the button if saveButtonEnabled is false
              >
              {t('Save Button Label')}
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000} // Adjust the duration as needed
              onClose={() => setSnackbarOpen(false)}
              message={t("Scenario saved successfully")}
              action={
                <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            />

       </Box>
     </>
      )}



{tabs === 'localGov' && (
        <>
          <Box
            sx={{
              height: 460 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>

                {
                  localGovernmentSliders.map((cslider) => (
                    <>
                     
                        <Range
                          id={cslider._id}
                          name={cslider["name"]["en"]}
                          title= {cslider["titles"]["en"][cslider.value]}
                          value={cslider.value}
                          handleChart = {handleChart}

                        />

                        <Divider />

                    </>
                  ))}
              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("end of list text")}
                </Typography>
              </Box>
            </Scrollbar>
          </Box>
          <Divider />
          <Box
            p={2}
            sx={{
              textAlign: 'center'
            }}
          >
            <Button
              sx={{
                textTransform: 'uppercase',
                fontSize: `${theme.typography.pxToRem(12)}`
              }}
              variant="contained"
              endIcon={<KeyboardArrowRightTwoToneIcon />}
              onClick={() => {
                saveUserScenario();
                setSnackbarOpen(true);
              }}
              disabled={!saveButtonEnabled} // Disable the button if saveButtonEnabled is false
              >
              {t('Save Button Label')}
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000} // Adjust the duration as needed
              onClose={() => setSnackbarOpen(false)}
              message={t("Scenario saved successfully")}
              action={
                <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            />
          </Box>
        </>
      )}

      {!tabs && (
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: 440,
            textAlign: 'center'
          }}
        >
          <Box>
            <Typography
              align="center"
              variant="h2"
              fontWeight="normal"
              color="text.secondary"
              sx={{
                mt: 3
              }}
              gutterBottom
            >
              {t('Empty Tabs Text')}
            </Typography>

          </Box>
        </Box>
      )}
    </Card>
  );
}

export default ActionListUser;
