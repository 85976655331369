import {
    Typography,
    Box,
    LinearProgress,
    styled,
    linearProgressClasses,
    Card
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const LinearProgressSuccess = styled(LinearProgress)(
    ({ progress }) => `
        height: 15px;
        border-radius: 15px;
        
        &.${linearProgressClasses.colorPrimary} {
            background-color: #C0C0C0; // Default background color
        }
        
        & .${linearProgressClasses.bar} {
            background-color: ${progress < 50 ? '#FFD700' : '#008000'}; // Yellow for progress < 50%, green for progress >= 50%
        }
    `
);


const NewProgressBar = ({ progress }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ m: 2 }}>
            <Card
                sx={{
                    overflow: 'visible'
                }}
            >
                <Box p={1.5} flexGrow={1}>
                    <Box
                        mb={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                color: 'green'
                            }}
                        />
                    </Box>
                    <LinearProgressSuccess variant="determinate" value={progress} progress={progress} />
                    <Box
                        display="flex"
                        sx={{
                            mt: 0.6
                        }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Tooltip title="Choose actions from the list and see how they affect CO2 emissions">
                            <Typography
                                sx={{
                                    color: 'black'
                                }}
                                variant="subtitle2"
                            >
                                {progress}{t('% CO2 reduction')}
                            </Typography>
                        </Tooltip>

                        <Typography
                            sx={{
                                color: 'green'
                            }}
                            variant="subtitle2"
                        >
                            100%
                        </Typography>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};
export default NewProgressBar