import React from "react";
import Chart from 'react-apexcharts';
import {Card} from '@mui/material';

const ChartContent = ({ totalData }) => {


  const MonthlyPowerOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["black","green"],
    dataLabels: {
      enabled: false
    },
    theme: {
      
    },
    fill: {
      opacity: 1,
      colors: ["black","green"]
    },
    stroke: {
      show: true,
      colors:  ["black","green"],
      curve: 'smooth',
      width: 3
    },
    legend: {
      show: false
    },
    labels: [
      '2022',
      '2030',
      '2040',
      '2050'
    ],
    xaxis: {
      axisBorder: {
        show: false
      },
      labels: {
        show: true
      }
    },
    yaxis: {
      min: 0,
      max: 10,
      show: true,
      labels: {
        formatter: (value) => {
          return value.toFixed(1)
        },
        show: true
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      strokeDashArray: 5,
      borderColor: "#DCDCDC"
    },

  };

  return (
    <div className="tab-content" id="v-pills-tabContent">
      <Card>
      <Chart
            options={MonthlyPowerOptions}
            series={[
              {
                name: 'Current',
                data: totalData,
              }, 
              {
                name: 'Target',
                data: [3.5, 3, 2.5, 2],
              }
          ]
            }
            type="area"
            height={500}
          />
          </Card>
    </div>
  );
};

export default ChartContent;
