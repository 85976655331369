import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

const User = ({ car, flight, train, building, waste }) => {
  const { t } = useTranslation();

  const carPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-primary text-white">
      { t("Vehicles") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
          { t("Vehicles") + ': ' +
              car +
              ' / '+ t("Total") + ': ' +
              (car + flight + train + building + waste).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((car * 100) / (car + flight + train + building + waste)).toFixed(
                2
              ) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const flightPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-danger text-white">
        { t("Flights") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Flights") + ': ' +
              flight +
              ' / '+ t("Total") + ': ' +
              (car + flight + train + building + waste).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              (
                (flight * 100) /
                (car + flight + train + building + waste)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const trainPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-success text-white">
        { t("Trains") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Trains") + ': ' +
              train +
              ' / '+ t("Total") + ': ' +
              (car + flight + train + building + waste).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              (
                (train * 100) /
                (car + flight + train + building + waste)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const buildingPop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-warning text-white">
         { t("Buildings") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Buildings")   + ': ' +
              building +
              ' / '+ t("Total") + ': ' +
              (car + flight + train + building + waste).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              (
                (building * 100) /
                (car + flight + train + building + waste)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const wastePop = (
    <Popover id="popover-basic">
      <Popover.Header as="h3" className="bg-info text-white">
      { t("Waste") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {t("Waste")   + ': ' +
              waste +
              ' / '+ t("Total") + ': ' +
              (car + flight + train + building + waste).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {'Percentage: ' +
              (
                (waste * 100) /
                (car + flight + train + building + waste)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

    const progressStyles = {
      width: '20%',
      height: '100%',
    };
  
    return (
      <Grid container alignItems="flex-end" justify="flex-start" spacing={1}>

        <Grid item style={{ paddingTop: '16px', width: '100px' }}>
          <span style={{ fontSize: '1.5rem', marginLeft: '-20px'}}>{ t("User") }</span>
        </Grid>

        <Grid item xs className="ml-3">
        <div className="progress" style={{ height: '20px', backgroundColor: '#00000000' }}>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Vehicles")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Flights")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Trains")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Buildings")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Waste")}
          </div>
        </div>

          <div className="progress" style={{ height: '20px' }}> 
            <OverlayTrigger
                trigger="click"
                rootClose
                placement="top"
                overlay={carPop}
            >     
              <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>

                <div
                  className="progress-bar text-wrap" 
                  role="button"
                  style={{
                    width:
                      (
                        (car * 100) /
                        (car + flight + train + building + waste)
                      ).toFixed(2) + '%',
                      backgroundColor: '#0D6EFD'
                  }}
                  
                >
                  {/* { t("Cars") } */}
                  &nbsp;
                </div>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="top"
                  overlay={flightPop}
                >
              <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
                <div
                  className="progress-bar text-wrap"
                  role="button"
                  style={{
                    width:
                      (
                        (flight * 100) /
                        (car + flight + train + building + waste)
                      ).toFixed(2) + '%',
                      backgroundColor: '#DC3545'
                  }}
                >
                  {/* { t("Flights") } */}
                  &nbsp;
                </div>          
              </div>
            </OverlayTrigger>

            <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="top"
                    overlay={trainPop}
            >
              <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
              
                <div
                  className="progress-bar text-wrap"
                  role="button"
                  style={{
                    width:
                      (
                        (train * 100) /
                        (car + flight + train + building + waste)
                      ).toFixed(2) + '%',
                      backgroundColor: '#198754'
                  }}
                >
                  {/* { t("Trains") } */}
                  &nbsp;
                </div>
              </div>
            </OverlayTrigger> 

            <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="top"
                      overlay={buildingPop}
                    >
              <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
                <div
                  className="progress-bar text-wrap"
                  role="button"
                  style={{
                    width:
                      (
                        (building * 100) /
                        (car + flight + train + building + waste)
                      ).toFixed(2) + '%',
                      backgroundColor: '#FFC107'
                  }}
                >
                  {/* { t("Buildings") } */}
                  &nbsp;
                </div>
              </div>
            </OverlayTrigger> 


            <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="top"
                        overlay={wastePop}
                      >
              <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>

                <div
                  className="progress-bar text-wrap"
                  role="button"
                  style={{
                    width:
                      (
                        (waste * 100) /
                        (car + flight + train + building + waste)
                      ).toFixed(2) + '%',
                      backgroundColor: '#0DCAF0'
                  }}
                >
                  {/* { t("Waste") } */}
                  &nbsp;
                </div>
              </div>
            </OverlayTrigger> 

          </div>
        </Grid>
        </Grid>
      // </div>
    );
};

export default User;
