import React from "react";
import { Grid } from "@mui/material"
import { useState, useEffect } from "react";
import ScenariosListAdmin from "./ScenarioListAdmin";
import ScenarioAdder from "./ScenarioAdder"
import SliderListAdmin2 from "./SliderListAdmin2"
import axios from "axios"

const Scenarios = () => {

  /*
  const loadedScenarios = [{
    id: 0,
    title: 'Max scenario',
    category: 'Government',
    logo: '/static/images/placeholders/logo/discord-icon.svg',
    sliderIDs: ["630a4d043396bc680abe1129"],
    sliderValues: [3],
    description: "max scenario description"
  },
  ]
  loadedScenarios.forEach(function (scenario) {
    scenarioMap.set(scenario.id, scenario)
  });
  */
  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev/"
  });

  const [scenarioMap,setScenarioMap] = useState( new Map());
  
  useEffect(() => {
    client.get("/scenarios")
    .then((response) => {
       response.data.forEach(function (scenario) {
        setScenarioMap(new Map(scenarioMap.set(scenario._id,scenario)))
        // tempSlidersMap.set(slider.id,slider)
      },
      // setSliders(new Map(tempSlidersMap))
      );
    });
 }, []);



  


  const [toUpdate, setToUpdate] = useState('false');


  const [slidersMap, setSliders] = useState(new Map())


  
 

  useEffect(() => {
    client.get("/actions")
      .then((response) => {
        response.data.forEach(function (slider) {
          setSliders(new Map(slidersMap.set(slider._id, slider)))
          // tempSlidersMap.set(slider.id,slider)
        },
          // setSliders(new Map(tempSlidersMap))
        );
      });
  }, []);


  const sliderValueHandler = (newState, sliderID) => {

    let tempSliderObject = slidersMap.get(sliderID)
    tempSliderObject.value = newState

    setSliders(new Map(slidersMap.set(sliderID, tempSliderObject)))

  }


  const [scenarios] = useState(scenarioMap);


  const getCurrentSliderStates = () => {

    let currentStates = []

    slidersMap.forEach(slider => {
      currentStates.push(slider.value)
    })

    return currentStates

  }

  const getCurrentSliderIDs = () => {

    let currentStates = []

    slidersMap.forEach(slider => {
      currentStates.push(slider._id)
    })

    return currentStates

  }

  const clearAllActions = () => {

    slidersMap.forEach(function (slider) {
      let tempSliderObject = slider
      tempSliderObject.value = 0
      setSliders(new Map(slidersMap.set(slider._id, tempSliderObject)))
    });

  }

  const [loadedScenario, setLoadedScenario] = useState("");
  const [scenarioTitle, setScenarioTitle] = useState("");
  const [scenarioCategory, setScenarioCategory] = useState("User");
  const [scenarioDescription, setScenarioDescription] = useState("");
  const [translationLanguage, setTlanguage] = useState("en")
  const [fieldsEmptyError,showEmptyFieldsError] = useState(false)
  const [translations, setTranslations] = useState(new Map())
  const [cancelButton, showCancelButton] = useState(false)
  const userCountry = "Greece"


  const presaveScenario = () => {
    let translatables = []
    translatables.push(scenarioTitle,scenarioDescription)

      setTranslations(translations.set(translationLanguage, translatables))
      console.log(translations)
  }
  
  const loadScenarioData = (newLanguage) => {
    if(translations.has(newLanguage)){
      let data = translations.get(newLanguage)
      setScenarioTitle(data[0])
      setScenarioDescription(data[1])
  }else{
    setScenarioTitle("")
    setScenarioDescription("")
  }
}

  const clear = () => {
    changeTranslation('en')
    setToUpdate("false")
    setTranslations(new Map())
    showEmptyFieldsError(false)
    clearAllActions()
    clearScenarioFields()
    showCancelButton(false)
  }


  const changeTranslation = (newLanguage) => {

    // fields must be correct
    if(translationLanguage === "en"){

      if(scenarioTitle.length === 0 || scenarioDescription.length === 0){
      showEmptyFieldsError(true)
      }
      else{

      // changing from english language handles fields and presaves the english translation
      presaveScenario()
      showEmptyFieldsError(false) 
      loadScenarioData(newLanguage)
      setTlanguage(newLanguage)

    
  }

}else{
    showEmptyFieldsError(false)
    loadScenarioData(newLanguage)
    setTlanguage(newLanguage)
    
}


  }




  const addScenario = async () => {

    if(scenarioTitle.length === 0 || scenarioDescription.length === 0){
      showEmptyFieldsError(true)
      }
      else{
    let currentStates = getCurrentSliderStates()
    let currentIDs = getCurrentSliderIDs()

      presaveScenario()

    let template = {
      "title" : {},
      "countries" : {},
      "description" : {},
    }


    translations.forEach(function (translatables, language) {
      template["title"][language] = translatables[0]
      template["description"][language] = translatables[1]
    })

    template["category"] = "User" // ------------------------------------------
    template["countries"] = userCountry

  

    await client.post("/scenarios",{
      title: template["title"],
      category: template["category"],
      sliderIDs: [...currentIDs],
      sliderValues: [...currentStates],
      description: template["description"],
      countries: template["countries"]
    })

        await client.get("/scenarios")
        .then((response) => {
           response.data.forEach(function (slider) {
            setSliders(new Map(slidersMap.set(slider._id,slider)))
          },
          );
        });

        
        


    clear()
      }

  }

  
  const deleteScenario = (scenarioID) => {

    client.delete("/scenarios/"+scenarioID)
      .then((response) => {
          console.log(response.message)
        },
        ).then(() => {          

          client.get("/scenarios")
          .then((response) => {
             response.data.forEach(function (slider) {
              setSliders(new Map(slidersMap.set(slider._id,slider)))
            },
            );
          });
  
          },
          );

  }

  
  
  const clearScenarioFields = () => {

    setLoadedScenario("")
    setScenarioTitle("")
    setScenarioCategory("")
    setScenarioDescription("")

  }

  const updateScenario = (scenarioID) => {
    if(scenarioTitle.length === 0 || scenarioDescription.length === 0){
      showEmptyFieldsError(true)
      }
      else{
    let currentStates = getCurrentSliderStates()
    let currentIDs = getCurrentSliderIDs()

      presaveScenario()
    
    let template = {
      "title" : {},
      "countries" : {},
      "description" : {},
    }


    translations.forEach(function (translatables, language) {
      template["title"][language] = translatables[0]
      template["description"][language] = translatables[1]
    })

    template["category"] = "User" // ------------------------------------------
    template["countries"] = userCountry


    client.patch("/scenarios/"+scenarioID,{
      title: template["title"],
      category: template["category"],
      sliderIDs: [...currentIDs],
      sliderValues: [...currentStates],
      description: template["description"]
    }).then(() => {          

      client.get("/scenarios")
      .then((response) => {
         response.data.forEach(function (slider) {
          setSliders(new Map(slidersMap.set(slider._id,slider)))
        },
        );
      });

      },
      );

    clear()
  }
}

 

 

  const sliderMapValueHandler = (sliderIDlist, newStateList, scenarioID) => {

    setLoadedScenario(scenarioID)
   

    let currentScenario = scenarioMap.get(scenarioID)

  let translationss = Object.keys(currentScenario["title"])

  translationss.forEach(function(language){

    let translatables = []
    translatables.push(currentScenario["title"][language], currentScenario["description"][language])
    setTranslations(translations.set(language, translatables))
    
  })

  setScenarioTitle(currentScenario["title"]["en"])
  setScenarioDescription(currentScenario["description"]["en"])



    for (let sliderIDIterator = 0; sliderIDIterator < sliderIDlist.length; sliderIDIterator++) {

      if (slidersMap.has(sliderIDlist[sliderIDIterator])){
      let tempSliderObject = slidersMap.get(sliderIDlist[sliderIDIterator])
      tempSliderObject.value = newStateList[sliderIDIterator]
      setSliders(new Map(slidersMap.set(sliderIDlist[sliderIDIterator], tempSliderObject)))
      }
    }

  }


  return (
    <>
      <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4}>
        <Grid item xs={10} md={3.5} lg={3.5}>

          <ScenariosListAdmin sliderMapValueHandler={sliderMapValueHandler} addScenario={addScenario} scenarios={scenarios} deleteScenario={deleteScenario} setToUpdate={setToUpdate} />


        </Grid>

        <Grid item xs={10} md={3.5} lg={3.5}>

          <SliderListAdmin2 sliders={slidersMap} sliderValueHandler={sliderValueHandler} />

        </Grid>

        <Grid item xs={10} md={7.5} lg={3.5}>

          <ScenarioAdder
          cancelButton={cancelButton}
          showCancelButton={showCancelButton}
          presaveScenario={presaveScenario}
          fieldsEmptyError={fieldsEmptyError}
            changeTranslation={changeTranslation}
            translationLanguage={translationLanguage}
            setTlanguage={setTlanguage}
            clear={clear}
            addScenario={addScenario}
            toUpdate={toUpdate}
            setToUpdate={setToUpdate}
            loadedScenario={loadedScenario}
            updateScenario={updateScenario}
            scenarioTitle={scenarioTitle}
            setScenarioTitle={setScenarioTitle}
            scenarioCategory={scenarioCategory}
            setScenarioCategory={setScenarioCategory}
            scenarioDescription={scenarioDescription}
            setScenarioDescription={setScenarioDescription}
          />

        </Grid>
      </Grid>
    </>
  )
}

export default Scenarios;
