import React from "react";
import AccountBalance from "./AccountBalance"
import { Box, Grid } from '@mui/material';
import RecommendationTab from './RecommendationTab'
import { useState,useEffect } from "react";
import axios from 'axios';
import carPhoto from "./recommendationIcons/carEmissionsIcon.png"
import flightsPhoto from "./recommendationIcons/flightEmissionsIcon.png"
import housePhoto from "./recommendationIcons/houseEmissionsIcon.png"
import trainPhoto from "./recommendationIcons/trainEmissionsIcon.png"
import wastePhoto from "./recommendationIcons/wasteEmissionsIcon.png"


const Recommendations = ({ countryCar,countryFlight,countryTrain,countryBuilding,countryWaste,calculatedCar,calculatedFlight,calculatedTrain,calculatedBuilding,calculatedWaste,formData}) => {

formData["transportationResults"] = calculatedCar
formData["flightResults"] = calculatedFlight
formData["trainResults"] = calculatedTrain
formData["buildingResults"] = calculatedBuilding
formData["wasteResults"] = calculatedWaste
formData["transportationCountryAverage"] = countryCar
formData["flightCountryAverage"] = countryFlight
formData["trainCountryAverage"] = countryTrain
formData["buildingCountryAverage"] = countryBuilding
formData["wasteCountryAverage"] = countryWaste

// relation category-results 
const calculationResults = {
  "Transportation": calculatedCar,
  "Flights": calculatedFlight,
  "Trains": calculatedTrain,
  "Buildings": calculatedBuilding,
  "Waste": calculatedWaste 
}

// change flights average for greece ---------------------------
const averages = {
  "Transportation": countryCar,
  "Flights": countryFlight,  
  "Trains": countryTrain,
  "Buildings": countryBuilding,
  "Waste": countryWaste
}

const categories = {
  Transportation: {
    title: "Transportation",
    color: "#0d6efd",
    icon: carPhoto
  },
  Flights: {
    title: "Flights",
    color: "#dc3545",
    icon: flightsPhoto
  },
  Trains: {
    title: "Trains",
    color: "#198754",
    icon: trainPhoto
  },
  Buildings: {
    title: "Buildings",
    color: "#ffc107",
    icon: housePhoto
  },
  Waste: {
    title: "Waste",
    color: "#0dcaf0",
    icon: wastePhoto
  }

}

const gridStyles = {
  paddingBottom: 2,
  marginTop: 2,
  marginLeft: 2,
  marginRight: 0,
};

// datafetching--------------------------------

const client = axios.create({
  // baseURL: "http://localhost:3003/co2apidev"
  baseURL: "https://co2.dev.wecompair.eu/co2apidev"
});

const [loadedcards, setloadedcards] = useState([])

useEffect(() => {
  client.get("/recommendations")
    .then(({data}) => {
      setloadedcards(data)
    });
}, []);

const tempMap = new Map();

loadedcards.forEach(function (card) {
  tempMap.set(card._id, card)
});

const loadRecommendationTabs = () => {

  let recommendationTabs = []
  let dataMap = new Map();
 
  // map initialization per category
  Object.keys(categories).forEach(function (key) {
    dataMap.set(key,[])
   })
  
  // loading map with recommendations
  Object.keys(loadedcards).forEach(function (iterator) {
    let card = loadedcards[iterator]
    let category = loadedcards[iterator].category
    let list = dataMap.get(category)
    list.push(card)
    dataMap.set(category,list)
  })

  Object.keys(categories).forEach(function (category) {
    recommendationTabs.push(
      <>
        <Grid item xs={12} sm={6} md={2.4} > 
          <RecommendationTab userInputData={formData} 
          calcResults={calculationResults} 
          averages={averages} 
          dataArr={dataMap.get(category)} 
          categoryData={categories[category]} />
        </Grid>
      </>
    )
  })
  return recommendationTabs
}

  return (

    <>
      <Box>
        <AccountBalance calculationResults={calculationResults} categories={categories} averages={averages} />
      </Box> 
      <Box sx={gridStyles} style={{ overflowX: 'auto' }}>
        <Grid container>
          {loadRecommendationTabs()}
        </Grid>
      </Box>
    </>
  );
};

export default Recommendations;
