import { Fragment } from 'react';
import {
  Box,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Card,
  Typography,
  styled,
  ListItemButton,
  alpha,

} from '@mui/material';
import { useState } from "react";
import { useTranslation } from 'react-i18next';


const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;

    &:hover {
        border-radius: ${theme.general.borderRadius};
        background: ${alpha("#33cb37", 0.4)};
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.08);
    }
  `
);

function ScenarioListUser({ chartHandler, scenarios }) {
  const { t } = useTranslation();
  const [userCountry] = useState("Greece")

  const sliderHandler = (scenarioID) => {
    chartHandler(scenarios.get(scenarioID).sliderIDs, scenarios.get(scenarioID).sliderValues)
  }

  const createScenariosList = () => {

    let scenariosList = []

    scenarios.forEach(function (value) {

      if (value["countries"].includes(userCountry)) {

        scenariosList.push(
          <Fragment key={value.id}>
            <Divider />
            <ListItemButtonWrapper
              onClick={() => {
                sliderHandler(value._id);
              }}
              sx={{
                display: { xs: 'block', sm: 'flex' },
                py: 1,
                px: 1.5
              }}
            >
              <ListItemAvatar
                sx={{
                  minWidth: 'auto',
                  mr: 1,
                  mb: { xs: 1, sm: 0 }
                }} />
              <ListItemText
                disableTypography
                primary={<Typography color="text.primary" variant="h7">

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="bold" 
                  >                  
                  {t(
                        value["title"]["en"]
                    )}
                  </Box>

                </Typography>}
                secondary={<>
                  <Typography noWrap variant="subtitle2">

                    {/* <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {
                        t(value.category)
                      }
                    </Box> */}

                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography>
                    {
                      t(value["description"]["en"])                        
                    }
                    </Typography>
                  </Box>
                </>} />


            </ListItemButtonWrapper>


          </Fragment>
        );
      }
    })
    return scenariosList
  }


  return (
    <Card
      sx={{
        overflow: 'visible'
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          paddingTop: 2
        }}
        // p={2}
        
      >
        <Typography
          variant="caption"
          fontWeight="bold"
        />
          <Typography variant="h4">{t('Scenarios')}   
          </Typography>        
      </Box>
      <Box
        sx={{
          textAlign: 'center'
        }}
        p={2}
      >
        <Typography
          variant="caption"
          fontWeight="bold"
        />
          <Typography variant="h5">{t("Example Scenarios Tooltip" )} </Typography>
        
      </Box>
      {/*
       <div className="scrollable-div">
      */}
      <List disablePadding>

        {createScenariosList()}

      </List>
      {/*
      </div>  
      */}
    </Card>
  );
}

export default ScenarioListUser;
