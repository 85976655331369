import React from "react";
import { Grid, Box, ToggleButton, useTheme, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Translation from "./Translation"
import Recommendations from "./Recommendations"
import Scenarios from "./Scenarios"
import Actions from "./Actions"
import UsersScenarioStatistics from "./Statistics/UsersScenarioStatistics";
import { Helmet } from 'react-helmet-async';

const AdminPage = () => {



  const theme = useTheme();
  const { t } = useTranslation();
  const [tabs, setTab] = useState('scenarios'); // default load state
  const handleViewOrientation = (_event, newValue) => {
    setTab(newValue);
  };




  return (
    <>

    <Helmet>
      <title>Admin Page - Carbon Footprint Simulation Dashboard - CompAir</title>
    </Helmet>
    
      <Box>

        <Grid container  >

          <Grid item xs={12}>

            <Box
              p={2.2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >

              <ToggleButtonGroup
                size="small"
                value={tabs}
                exclusive
                onChange={handleViewOrientation}

              >
                <ToggleButton
                  sx={{
                    px: 4,
                    py: 0.5,
                    lineHeight: 1.5,
                    fontSize: `${theme.typography.pxToRem(12)}`,
                  }}
                  disableRipple
                  value="scenarios"
                >
                  {t('Scenarios')}
                </ToggleButton>
                <ToggleButton
                  sx={{
                    px: 4,
                    py: 0.5,
                    lineHeight: 1.5,
                    fontSize: `${theme.typography.pxToRem(12)}`
                  }}
                  disableRipple
                  value="actions"
                >
                  {t('Actions')}
                </ToggleButton>
                <ToggleButton
                  sx={{
                    px: 4,
                    py: 0.5,
                    lineHeight: 1.5,
                    fontSize: `${theme.typography.pxToRem(12)}`
                  }}
                  disableRipple
                  value="recommendations"
                >
                  {t("Recommendations")}
                </ToggleButton>

                {/* <ToggleButton
                  sx={{
                    px: 4,
                    py: 0.5,
                    lineHeight: 1.5,
                    fontSize: `${theme.typography.pxToRem(12)}`
                  }}
                  disableRipple
                  value="translation"
                >
                  {t('Translation')}
                </ToggleButton> */}

                <ToggleButton
                  sx={{
                    px: 4,
                    py: 0.5,
                    lineHeight: 1.5,
                    fontSize: `${theme.typography.pxToRem(12)}`
                  }}
                  disableRipple
                  value="Statistics"
                >
                  {t('Statistics')}
                </ToggleButton>


              </ToggleButtonGroup>
            </Box>


          </Grid>

          {tabs === 'scenarios' && (
            <>
              <Scenarios />
            </>
          )}

          {tabs === 'actions' && (
            <>
              <Actions />
            </>
          )}

          {tabs === 'translation' && (
            <>
            <Translation />
            </>
          )}

          {tabs === 'recommendations' && (
            <>
              <Recommendations />
            </>
          )}
          {tabs === 'Statistics' && (
            <>
              <UsersScenarioStatistics />
            </>
          )}



        </Grid>
      </Box>

    </>


  );
}

export default AdminPage;
