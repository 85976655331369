// import { Tooltip, styled } from '@mui/material';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import compairLogo from "./compairLogo.png"

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: 0;
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);


function Logo() {

  /* Hide footer when viewing through the Compair Project portal */
  const currentPath = useLocation();
  const isIntegrated = new URLSearchParams(currentPath.search).get('integration')
  if (isIntegrated) return null;


  return (
    <LogoWrapper to="/home">
      {/* <Tooltip
        arrow
        placement="right"
        title="Compair Project"
      > */}
        
{/* <Typography style={{ color: "#33cb37" }}>
       COMPAIR
</Typography> */}
          <img
            src={compairLogo} // Use the imported variable here without quotes
            alt="Compair Project Logo"
            style={{ width: '100px', height: 'auto' }}
          />
      {/* </Tooltip> */}
    </LogoWrapper>
  );
}

export default Logo;
