// MapComponent.jsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
// , TileLayer, Marker, Popup 
import L from 'leaflet';
import {Box}  from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  // eslint-disable-next-line 
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // eslint-disable-next-line 
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    // eslint-disable-next-line 
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// const potatoman = 'Potatoman'
// const potatowoman = 'Potatowoman'



const MapComponent = () => {
  // eslint-disable-next-line
  const [belgiumUnique, setBelgiumUnique] = useState([])
  // eslint-disable-next-line
  const [germanyUnique, setGermanyUnique] = useState([])
  // eslint-disable-next-line
  const [greeceUnique, setGreeceUnique] = useState([])
  // eslint-disable-next-line
  const [bulgariaUnique, setBulgariaUnique] = useState([])
  // const [municipalCommunityAverages, setMunicipalCommunityAverages] = useState([])
  // eslint-disable-next-line
  const [municipalCommunityAverages, setMunicipalCommunityAverages] = useState({
    athens1: {
      name: 'Athens 1st Municipal Community',
      coordinates: [37.98, 23.733],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    },
    athens2: {
      name: 'Athens 2nd Municipal Community',
      coordinates: [37.962, 23.735],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    },
    athens3: {
      name: 'Athens 3rd Municipal Community',
      coordinates: [37.978, 23.709],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    },
    athens4: {
      name: 'Athens 4th Municipal Community',
      coordinates: [38, 23.715],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    },
    athens5: {
      name: 'Athens 5th Municipal Community',
      coordinates: [38.02, 23.736],
      avgTotalCO2: {},
      avgTransportationResults: {},
      avgFlightResults: {},
      avgTrainResults: {},
      avgBuildingResults: {},
      avgWasteResults: {}
    },
    athens6: {
      name: 'Athens 6th Municipal Community',
      coordinates: [38.002, 23.737],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    },
    athens7: {
      name: 'Athens 7th Municipal Community',
      coordinates: [37.992, 23.766],
      avgTotalCO2: 0,
      avgTransportationResults: 0,
      avgFlightResults: 0,
      avgTrainResults: 0,
      avgBuildingResults: 0,
      avgWasteResults: 0
    }
  });



  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev"
  });
  
  useEffect(() => {
    client.get("/belgiumUnique")
        .then((response) => {
          setBelgiumUnique(() => {
            return response.data;
          });
        });
    client.get("/germanyUnique")
        .then((response) => {
            setGermanyUnique(() => {
              return response.data;
            });
        });
    client.get("/greeceUnique")
        .then((response) => {
            setGreeceUnique(() => {
              return response.data;
            });
        });
    client.get("/bulgariaUnique")
        .then((response) => {
            setBulgariaUnique(() => {
              return response.data;
            });
        });
  }, []);



  useEffect(() => {
    const greeceCO2Averages = {};
    const greeceAvgTransportationResults = {};
    const greeceAvgFlightResults = {};
    const greeceAvgTrainResults = {};
    const greeceAvgBuildingResults = {};
    const greeceAvgWasteResults = {};
    const greeceCounts = {
      'Athens 1st Municipal Community': 0,
      'Athens 2nd Municipal Community': 0,
      'Athens 3rd Municipal Community': 0,
      'Athens 4th Municipal Community': 0,
      'Athens 5th Municipal Community': 0,
      'Athens 6th Municipal Community': 0,
      'Athens 7th Municipal Community': 0,
    };

    greeceUnique.forEach(entry => {
      // const belgiumAverages = {};
      // const belgiumCounts = {};

      // const germanyAverages = {};
      // const germanyCounts = {};

      // const bulgariaAverages = {};
      // const bulgariaCounts = {};

      if(entry.municipalCommunity === "Κολωνάκι" || 
          entry.municipalCommunity === "Νεάπολη" || 
          entry.municipalCommunity === "Πεδίον του Άρεως" || 
          entry.municipalCommunity === "Μοναστηράκι" ||
          entry.municipalCommunity === "Ιλίσια" ||
          entry.municipalCommunity === "Εξάρχεια" ||
          entry.municipalCommunity === "Σύνταγμα" ||
          entry.municipalCommunity === "Ομόνοια" ||
          entry.municipalCommunity === "Μεταξουργείο" ||
          entry.municipalCommunity === "Γκάζι" ||
          entry.municipalCommunity === "Σταθμός Λαρίσης") 
        {
          // eslint-disable-next-line
          greeceCO2Averages['Athens 1st Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 1st Municipal Community']) || 0) + entry.totalCO2;
          // eslint-disable-next-line
          greeceAvgTransportationResults['Athens 1st Municipal Community'] = (greeceAvgTransportationResults['Athens 1st Municipal Community'] || 0) + entry.transportationResults;
          // eslint-disable-next-line
          greeceAvgFlightResults['Athens 1st Municipal Community'] = (greeceAvgFlightResults['Athens 1st Municipal Community'] || 0) + entry.flightResults;
          // eslint-disable-next-line
          greeceAvgTrainResults['Athens 1st Municipal Community'] = (greeceAvgTrainResults['Athens 1st Municipal Community'] || 0) + entry.trainResults;
          // eslint-disable-next-line
          greeceAvgBuildingResults['Athens 1st Municipal Community'] = (greeceAvgBuildingResults['Athens 1st Municipal Community'] || 0) + entry.buildingResults;
          // eslint-disable-next-line
          greeceAvgWasteResults['Athens 1st Municipal Community'] = (greeceAvgWasteResults['Athens 1st Municipal Community'] || 0) + entry.wasteResults;
          // eslint-disable-next-line
          greeceCounts['Athens 1st Municipal Community'] = (greeceCounts['Athens 1st Municipal Community'] || 0) + 1;
      }

      if(entry.municipalCommunity === "Παγκράτι" || 
          entry.municipalCommunity === "Άγιος Αρτέμιος" || 
          entry.municipalCommunity === "Νέος Κόσμος" || 
          entry.municipalCommunity === "Μετς") 
        {
          greeceCO2Averages['Athens 2nd Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 2nd Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 2nd Municipal Community'] = (greeceAvgTransportationResults['Athens 2nd Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 2nd Municipal Community'] = (greeceAvgFlightResults['Athens 2nd Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 2nd Municipal Community'] = (greeceAvgTrainResults['Athens 2nd Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 2nd Municipal Community'] = (greeceAvgBuildingResults['Athens 2nd Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 2nd Municipal Community'] = (greeceAvgWasteResults['Athens 2nd Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 2nd Municipal Community'] = (greeceCounts['Athens 2nd Municipal Community'] || 0) + 1;
      }

      if(entry.municipalCommunity === "Βοτανικός" || 
          entry.municipalCommunity === "Πετράλωνα" || 
          entry.municipalCommunity === "Θησείο" || 
          entry.municipalCommunity === "Ρουφ") 
        {
          greeceCO2Averages['Athens 3rd Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 3rd Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 3rd Municipal Community'] = (greeceAvgTransportationResults['Athens 3rd Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 3rd Municipal Community'] = (greeceAvgFlightResults['Athens 3rd Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 3rd Municipal Community'] = (greeceAvgTrainResults['Athens 3rd Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 3rd Municipal Community'] = (greeceAvgBuildingResults['Athens 3rd Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 3rd Municipal Community'] = (greeceAvgWasteResults['Athens 3rd Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 3rd Municipal Community'] = (greeceCounts['Athens 3rd Municipal Community'] || 0) + 1;
      }

      if(entry.municipalCommunity === "Ακαδημία Πλάτωνος" || 
          entry.municipalCommunity === "Κολοκυνθού" || 
          entry.municipalCommunity === "Σεπόλια" || 
          entry.municipalCommunity === "Κολωνός") 
        {
          greeceCO2Averages['Athens 4th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 4th Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 4th Municipal Community'] = (greeceAvgTransportationResults['Athens 4th Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 4th Municipal Community'] = (greeceAvgFlightResults['Athens 4th Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 4th Municipal Community'] = (greeceAvgTrainResults['Athens 4th Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 4th Municipal Community'] = (greeceAvgBuildingResults['Athens 4th Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 4th Municipal Community'] = (greeceAvgWasteResults['Athens 4th Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 4th Municipal Community'] = (greeceCounts['Athens 4th Municipal Community'] || 0) + 1;
      }     
      
      if(entry.municipalCommunity === "Άγιος Ελευθέριος" || 
          entry.municipalCommunity === "Πατήσια" || 
          entry.municipalCommunity === "Ριζούπολη" || 
          entry.municipalCommunity === "Πλατεία Κολιάτσου") 
        {
          greeceCO2Averages['Athens 5th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 5th Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 5th Municipal Community'] = (greeceAvgTransportationResults['Athens 5th Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 5th Municipal Community'] = (greeceAvgFlightResults['Athens 5th Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 5th Municipal Community'] = (greeceAvgTrainResults['Athens 5th Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 5th Municipal Community'] = (greeceAvgBuildingResults['Athens 5th Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 5th Municipal Community'] = (greeceAvgWasteResults['Athens 5th Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 5th Municipal Community'] = (greeceCounts['Athens 5th Municipal Community'] || 0) + 1;
      }  

      if(entry.municipalCommunity === "Πλατεία Αττικής" || 
          entry.municipalCommunity === "Κυψέλη" || 
          entry.municipalCommunity === "Πλατεία Βικτωρίας" || 
          entry.municipalCommunity === "Άγιος Νικόλαος") 
        {
          greeceCO2Averages['Athens 6th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 6th Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 6th Municipal Community'] = (greeceAvgTransportationResults['Athens 6th Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 6th Municipal Community'] = (greeceAvgFlightResults['Athens 6th Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 6th Municipal Community'] = (greeceAvgTrainResults['Athens 6th Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 6th Municipal Community'] = (greeceAvgBuildingResults['Athens 6th Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 6th Municipal Community'] = (greeceAvgWasteResults['Athens 6th Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 6th Municipal Community'] = (greeceCounts['Athens 6th Municipal Community'] || 0) + 1;
      } 
      
      if(entry.municipalCommunity === "Γκύζη" || 
          entry.municipalCommunity === "Πολύγωνο" || 
          entry.municipalCommunity === "Ελληνορώσων" || 
          entry.municipalCommunity === "Αμπελόκηπο" || 
          entry.municipalCommunity === "Νέα φιλοθέη") 
        {
          greeceCO2Averages['Athens 7th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 7th Municipal Community']) || 0) + entry.totalCO2;
          greeceAvgTransportationResults['Athens 7th Municipal Community'] = (greeceAvgTransportationResults['Athens 7th Municipal Community'] || 0) + entry.transportationResults;
          greeceAvgFlightResults['Athens 7th Municipal Community'] = (greeceAvgFlightResults['Athens 7th Municipal Community'] || 0) + entry.flightResults;
          greeceAvgTrainResults['Athens 7th Municipal Community'] = (greeceAvgTrainResults['Athens 7th Municipal Community'] || 0) + entry.trainResults;
          greeceAvgBuildingResults['Athens 7th Municipal Community'] = (greeceAvgBuildingResults['Athens 7th Municipal Community'] || 0) + entry.buildingResults;
          greeceAvgWasteResults['Athens 7th Municipal Community'] = (greeceAvgWasteResults['Athens 7th Municipal Community'] || 0) + entry.wasteResults;
          greeceCounts['Athens 7th Municipal Community'] = (greeceCounts['Athens 7th Municipal Community'] || 0) + 1;
      } 
    });


    if (greeceCounts['Athens 1st Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 1st Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 1st Municipal Community']) / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 1st Municipal Community'] = (greeceAvgTransportationResults['Athens 1st Municipal Community'] / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 1st Municipal Community'] = (greeceAvgFlightResults['Athens 1st Municipal Community'] / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 1st Municipal Community'] = (greeceAvgTrainResults['Athens 1st Municipal Community'] / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 1st Municipal Community'] = (greeceAvgBuildingResults['Athens 1st Municipal Community'] / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 1st Municipal Community'] = (greeceAvgWasteResults['Athens 1st Municipal Community'] / greeceCounts['Athens 1st Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 1st Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 1st Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 1st Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 1st Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 1st Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 1st Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 2nd Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 2nd Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 2nd Municipal Community']) / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 2nd Municipal Community'] = (greeceAvgTransportationResults['Athens 2nd Municipal Community'] / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 2nd Municipal Community'] = (greeceAvgFlightResults['Athens 2nd Municipal Community'] / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 2nd Municipal Community'] = (greeceAvgTrainResults['Athens 2nd Municipal Community'] / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 2nd Municipal Community'] = (greeceAvgBuildingResults['Athens 2nd Municipal Community'] / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 2nd Municipal Community'] = (greeceAvgWasteResults['Athens 2nd Municipal Community'] / greeceCounts['Athens 2nd Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 2nd Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 2nd Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 2nd Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 2nd Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 2nd Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 2nd Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 3rd Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 3rd Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 3rd Municipal Community']) / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 3rd Municipal Community'] = (greeceAvgTransportationResults['Athens 3rd Municipal Community'] / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 3rd Municipal Community'] = (greeceAvgFlightResults['Athens 3rd Municipal Community'] / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 3rd Municipal Community'] = (greeceAvgTrainResults['Athens 3rd Municipal Community'] / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 3rd Municipal Community'] = (greeceAvgBuildingResults['Athens 3rd Municipal Community'] / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 3rd Municipal Community'] = (greeceAvgWasteResults['Athens 3rd Municipal Community'] / greeceCounts['Athens 3rd Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 3rd Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 3rd Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 3rd Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 3rd Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 3rd Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 3rd Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 4th Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 4th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 4th Municipal Community']) / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 4th Municipal Community'] = (greeceAvgTransportationResults['Athens 4th Municipal Community'] / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 4th Municipal Community'] = (greeceAvgFlightResults['Athens 4th Municipal Community'] / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 4th Municipal Community'] = (greeceAvgTrainResults['Athens 4th Municipal Community'] / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 4th Municipal Community'] = (greeceAvgBuildingResults['Athens 4th Municipal Community'] / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 4th Municipal Community'] = (greeceAvgWasteResults['Athens 4th Municipal Community'] / greeceCounts['Athens 4th Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 4th Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 4th Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 4th Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 4th Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 4th Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 4th Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 5th Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 5th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 5th Municipal Community']) / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 5th Municipal Community'] = (greeceAvgTransportationResults['Athens 5th Municipal Community'] / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 5th Municipal Community'] = (greeceAvgFlightResults['Athens 5th Municipal Community'] / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 5th Municipal Community'] = (greeceAvgTrainResults['Athens 5th Municipal Community'] / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 5th Municipal Community'] = (greeceAvgBuildingResults['Athens 5th Municipal Community'] / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 5th Municipal Community'] = (greeceAvgWasteResults['Athens 5th Municipal Community'] / greeceCounts['Athens 5th Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 5th Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 5th Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 5th Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 5th Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 5th Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 5th Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 6th Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 6th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 6th Municipal Community']) / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 6th Municipal Community'] = (greeceAvgTransportationResults['Athens 6th Municipal Community'] / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 6th Municipal Community'] = (greeceAvgFlightResults['Athens 6th Municipal Community'] / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 6th Municipal Community'] = (greeceAvgTrainResults['Athens 6th Municipal Community'] / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 6th Municipal Community'] = (greeceAvgBuildingResults['Athens 6th Municipal Community'] / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 6th Municipal Community'] = (greeceAvgWasteResults['Athens 6th Municipal Community'] / greeceCounts['Athens 6th Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 6th Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 6th Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 6th Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 6th Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 6th Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 6th Municipal Community'] = 'NaN'
    }

    if (greeceCounts['Athens 7th Municipal Community'] !== 0) {
      // eslint-disable-next-line
      greeceCO2Averages['Athens 7th Municipal Community'] = (parseFloat(greeceCO2Averages['Athens 7th Municipal Community']) / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTransportationResults['Athens 7th Municipal Community'] = (greeceAvgTransportationResults['Athens 7th Municipal Community'] / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgFlightResults['Athens 7th Municipal Community'] = (greeceAvgFlightResults['Athens 7th Municipal Community'] / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgTrainResults['Athens 7th Municipal Community'] = (greeceAvgTrainResults['Athens 7th Municipal Community'] / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgBuildingResults['Athens 7th Municipal Community'] = (greeceAvgBuildingResults['Athens 7th Municipal Community'] / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
      // eslint-disable-next-line
      greeceAvgWasteResults['Athens 7th Municipal Community'] = (greeceAvgWasteResults['Athens 7th Municipal Community'] / greeceCounts['Athens 7th Municipal Community']).toFixed(2);
    } else {
      greeceCO2Averages['Athens 7th Municipal Community'] = 'NaN'
      greeceAvgTransportationResults['Athens 7th Municipal Community'] = 'NaN'
      greeceAvgFlightResults['Athens 7th Municipal Community'] = 'NaN'
      greeceAvgTrainResults['Athens 7th Municipal Community'] = 'NaN'
      greeceAvgBuildingResults['Athens 7th Municipal Community'] = 'NaN'
      greeceAvgWasteResults['Athens 7th Municipal Community'] = 'NaN'
    }

    setMunicipalCommunityAverages({
      athens1: {
        name: 'Athens 1st Municipal Community',
        coordinates: [37.98, 23.733],
        avgTotalCO2: (greeceCO2Averages['Athens 1st Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 1st Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 1st Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 1st Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 1st Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 1st Municipal Community'] || 0)
      },
      athens2: {
        name: 'Athens 2nd Municipal Community',
        coordinates: [37.962, 23.735],
        avgTotalCO2: (greeceCO2Averages['Athens 2nd Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 2nd Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 2nd Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 2nd Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 2nd Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 2nd Municipal Community'] || 0)
      },
      athens3: {
        name: 'Athens 3rd Municipal Community',
        coordinates: [37.978, 23.709],
        avgTotalCO2: (greeceCO2Averages['Athens 3rd Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 3rd Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 3rd Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 3rd Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 3rd Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 3rd Municipal Community'] || 0)
      },
      athens4: {
        name: 'Athens 4th Municipal Community',
        coordinates: [38, 23.715],
        avgTotalCO2: (greeceCO2Averages['Athens 4th Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 4th Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 4th Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 4th Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 4th Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 4th Municipal Community'] || 0)
      },
      athens5: {
        name: 'Athens 5th Municipal Community',
        coordinates: [38.02, 23.736],
        avgTotalCO2: (greeceCO2Averages['Athens 5th Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 5th Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 5th Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 5th Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 5th Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 5th Municipal Community'] || 0)
      },
      athens6: {
        name: 'Athens 6th Municipal Community',
        coordinates: [38.002, 23.737],
        avgTotalCO2: (greeceCO2Averages['Athens 6th Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 6th Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 6th Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 6th Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 6th Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 6th Municipal Community'] || 0)
      },
      athens7: {
        name: 'Athens 7th Municipal Community',
        coordinates: [37.992, 23.766],
        avgTotalCO2: (greeceCO2Averages['Athens 7th Municipal Community'] || 0),
        avgTransportationResults: (greeceAvgTransportationResults['Athens 7th Municipal Community'] || 0),
        avgFlightResults: (greeceAvgFlightResults['Athens 7th Municipal Community'] || 0),
        avgTrainResults: (greeceAvgTrainResults['Athens 7th Municipal Community'] || 0),
        avgBuildingResults: (greeceAvgBuildingResults['Athens 7th Municipal Community'] || 0),
        avgWasteResults: (greeceAvgWasteResults['Athens 7th Municipal Community'] || 0)
      } 
    },
  
  
  // Similar code for the other 3 pilots if they ever provide municipal community data
  )

  }, [greeceUnique]);

  

// // eslint-disable-next-line
// const municipalC =[
//   { coordinates: [38.98, 23.733], info: '1st Municipal Community', additionalInfo: ['potatoman', 'potatowoman'] },
//   { coordinates: [38.962, 23.735], info: '2nd Municipal Community', additionalInfo: ['Data3', 'Data4'] },
//   { coordinates: [38.978, 23.709], info: '3rd Municipal Community', additionalInfo: ['Data5', 'Data6'] },
//   { coordinates: [39, 23.715], info: '4th Municipal Community', additionalInfo: ['Data7', 'Data8'] },
//   { coordinates: [39.02, 23.736], info: '5th Municipal Community', additionalInfo: ['Data9', 'Data10'] },
//   { coordinates: [39.002, 23.737], info: '6th Municipal Community', additionalInfo: ['Data11', 'Data12'] },
//   { coordinates: [38.992, 23.766], info: '7th Municipal Community', additionalInfo: ['Data13', 'Data14'] }
//   // Add more communities as needed
// ];

  const municipalCommunities = [
    { coordinates: [
      municipalCommunityAverages.athens1.coordinates[0], 
      municipalCommunityAverages.athens1.coordinates[1]], 
      info: municipalCommunityAverages.athens1.name, 
      additionalInfo: [municipalCommunityAverages.athens1.avgTotalCO2, 
                        municipalCommunityAverages.athens1.avgTransportationResults, 
                        municipalCommunityAverages.athens1.avgFlightResults, 
                        municipalCommunityAverages.athens1.avgTrainResults, 
                        municipalCommunityAverages.athens1.avgBuildingResults, 
                        municipalCommunityAverages.athens1.avgWasteResults] 
      },
    { coordinates: [
      municipalCommunityAverages.athens2.coordinates[0], 
      municipalCommunityAverages.athens2.coordinates[1]], 
      info: municipalCommunityAverages.athens2.name, 
      additionalInfo: [municipalCommunityAverages.athens2.avgTotalCO2, 
                        municipalCommunityAverages.athens2.avgTransportationResults, 
                        municipalCommunityAverages.athens2.avgFlightResults, 
                        municipalCommunityAverages.athens2.avgTrainResults, 
                        municipalCommunityAverages.athens2.avgBuildingResults, 
                        municipalCommunityAverages.athens2.avgWasteResults] 
    },
    { coordinates: [
      municipalCommunityAverages.athens3.coordinates[0], 
      municipalCommunityAverages.athens3.coordinates[1]], 
      info: municipalCommunityAverages.athens3.name, 
      additionalInfo: [municipalCommunityAverages.athens3.avgTotalCO2, 
                        municipalCommunityAverages.athens3.avgTransportationResults, 
                        municipalCommunityAverages.athens3.avgFlightResults, 
                        municipalCommunityAverages.athens3.avgTrainResults, 
                        municipalCommunityAverages.athens3.avgBuildingResults, 
                        municipalCommunityAverages.athens3.avgWasteResults] 
    },
    { coordinates: [
      municipalCommunityAverages.athens4.coordinates[0], 
      municipalCommunityAverages.athens4.coordinates[1]], 
      info: municipalCommunityAverages.athens4.name, 
      additionalInfo: [municipalCommunityAverages.athens4.avgTotalCO2, 
                        municipalCommunityAverages.athens4.avgTransportationResults, 
                        municipalCommunityAverages.athens4.avgFlightResults, 
                        municipalCommunityAverages.athens4.avgTrainResults, 
                        municipalCommunityAverages.athens4.avgBuildingResults, 
                        municipalCommunityAverages.athens4.avgWasteResults]
    },
    { coordinates: [
      municipalCommunityAverages.athens5.coordinates[0], 
      municipalCommunityAverages.athens5.coordinates[1]], 
      info: municipalCommunityAverages.athens5.name, 
      additionalInfo: [municipalCommunityAverages.athens5.avgTotalCO2, 
                        municipalCommunityAverages.athens5.avgTransportationResults, 
                        municipalCommunityAverages.athens5.avgFlightResults, 
                        municipalCommunityAverages.athens5.avgTrainResults, 
                        municipalCommunityAverages.athens5.avgBuildingResults, 
                        municipalCommunityAverages.athens5.avgWasteResults] 
    },
    { coordinates: [
      municipalCommunityAverages.athens6.coordinates[0], 
      municipalCommunityAverages.athens6.coordinates[1]], 
      info: municipalCommunityAverages.athens6.name, 
      additionalInfo: [municipalCommunityAverages.athens6.avgTotalCO2, 
                        municipalCommunityAverages.athens6.avgTransportationResults, 
                        municipalCommunityAverages.athens6.avgFlightResults, 
                        municipalCommunityAverages.athens6.avgTrainResults, 
                        municipalCommunityAverages.athens6.avgBuildingResults, 
                        municipalCommunityAverages.athens6.avgWasteResults] 
    },
    { coordinates: [
      municipalCommunityAverages.athens7.coordinates[0], 
      municipalCommunityAverages.athens7.coordinates[1]], 
      info: municipalCommunityAverages.athens7.name, 
      additionalInfo: [municipalCommunityAverages.athens7.avgTotalCO2, 
                        municipalCommunityAverages.athens7.avgTransportationResults, 
                        municipalCommunityAverages.athens7.avgFlightResults, 
                        municipalCommunityAverages.athens7.avgTrainResults, 
                        municipalCommunityAverages.athens7.avgBuildingResults, 
                        municipalCommunityAverages.athens7.avgWasteResults] 
    },
      // add more for other cities if needed
];


  const position= [37.98, 23.733]

  return (
    <> 
    {/* <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css" integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossOrigin="" />

    <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js" integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossOrigin=""></script> */}
{/* eslint-disable-next-line */}

    <Box>
      {/* eslint-disable-next-line */}
    <MapContainer center={position} zoom={5} scrollWheelZoom={true} 
          style={{ height:"800px",backgroundColor:"red",marginTop:"80px", marginBottom:'90px'
              }} 
              // bounds={coordinates}
              // boundsOptions={{ padding: [1, 1] }}
              >
                
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {/* <Polygon positions={coordinates} /> */}
    {municipalCommunities.map((community, index) => (
      // eslint-disable-next-line
      <Marker key={index} position={community.coordinates}>
          <Popup>
              <div>
                  {/* <p>{`Test: ${test}`}</p> */}
                  <p>{`Community Info: ${community.info}`}</p>
                  <p>{`Average Total CO2: ${community.additionalInfo[0]}`}</p>
                  <p>{`Average CO2 from Transporation: ${community.additionalInfo[1]}`}</p>
                  <p>{`Average CO2 from Flights: ${community.additionalInfo[2]}`}</p>
                  <p>{`Average CO2 from Trains: ${community.additionalInfo[3]}`}</p>
                  <p>{`Average CO2 from Buildings: ${community.additionalInfo[4]}`}</p>
                  <p>{`Average CO2 from Waste: ${community.additionalInfo[5]}`}</p>
              </div>
          </Popup>
      </Marker>
    ))}


  </MapContainer>
    </Box>
  </>
  );
};

export default MapComponent;