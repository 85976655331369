import {
  Card,
  Box,
  Grid,
  Typography,
  useTheme,
  styled,
  ListItem,
  ListItemText,
  List,
  ListItemAvatar
} from '@mui/material';
import Text from 'src/components/Text';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

// import { PieChart } from '@mui/x-charts/PieChart';



const ListItemAvatarWrapper = styled(ListItemAvatar)(
  ({ theme, color }) => `
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing(1)};
  padding: ${theme.spacing(0.5)};
  border-radius: 60px;
  background: ${color};

  img {
    background: ${theme.colors.alpha.trueWhite[100]};
    padding: ${theme.spacing(0.5)};
    display: block;
    border-radius: inherit;
    height: ${theme.spacing(4.5)};
    width: ${theme.spacing(4.5)};
  }
`
);


function AccountBalance({ calculationResults, categories, averages }) {
  const { t } = useTranslation();

  let sum = 0;
  const calculationResultsCopy = []

  // json copy while calculating sum
  Object.keys(calculationResults).forEach(function (value) {
    sum += calculationResults[value]
    calculationResultsCopy[value] = calculationResults[value]
    // console.log(calculationResultsCopy[value])
  });

  const percentages = []

  //
  Object.keys(calculationResults).forEach(function (value) {
    calculationResults[value] = ((calculationResults[value] / sum) * 100);
    percentages.push(calculationResults[value])
    });
    
  const colorPercentage = (category,percentage, average) => {
    if ( calculationResultsCopy[category] <= parseFloat(average)) {
      // console.log(category);
      // console.log(calculationResultsCopy[category])
      // console.log("Average");
      // console.log(parseFloat(average));
      return (<Text color="success">{percentage.toFixed(2)}%</Text>)
    }
    return (<Text color="error">{percentage.toFixed(2)}%</Text>)

  }

  const loadCategoriesList = () => {

    let categoriesList = []

    Object.keys(categories).forEach(function (key) {

      categoriesList.push(
        <>
          <ListItem disableGutters>
            <ListItemAvatarWrapper color={categories[key].color}>
              <img
                alt={key}
                src={categories[key].icon}
              />
            </ListItemAvatarWrapper>
            <ListItemText
              primary={t(categories[key].title)}
              primaryTypographyProps={{ variant: 'h5', noWrap: true }}
            />
            <Box>
              <Typography align="right" variant="h4" noWrap>
                {colorPercentage(key,calculationResults[key], averages[key])}
              </Typography>

            </Box>
          </ListItem>
        </>)
    })
    return categoriesList

  }

  const labels = []
  const colors = []

  Object.keys(categories).forEach(function (key) {
    labels.push(t(categories[key].title))
    colors.push(categories[key].color)
  })

  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },

    plotOptions: {
      pie: {
        donut: {
          size: '60%'
        }
      }
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val.toFixed(2)}%`;
      },
      style: {
        colors: [theme.colors.alpha.trueWhite[100]]
      },
      background: {
        enabled: true,
        foreColor: theme.colors.alpha.trueWhite[100],
        padding: 8,
        borderRadius: 4,
        borderWidth: 0,
        opacity: 0.3,
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: theme.colors.alpha.black[70],
          opacity: 0.5
        }
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: theme.colors.alpha.black[50],
        opacity: 0.5
      }
    },
    fill: {
      opacity: 1
    },
    labels: labels,
    legend: {
      labels: {
        colors: theme.colors.alpha.trueWhite[100]
      },
      show: false
    },
    stroke: {
      width: 0
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  const gridStyles = {
    paddingBottom: 2,
    paddingRight: 2,
    marginTop: 2,
    marginLeft: 2,
    marginRight: 2,
  };


  return (

    <Card sx={gridStyles}>
      <h5 className="card-title text-center">{t("Your results compared to your country's average")}</h5>
      <Grid
        container
        display="flex"
        alignItems="center"
      >

        <Grid
          item
          xs={12}
          md={6}


          display="flex"
          justifyContent="right"
        >
          <Chart

            options={chartOptions}
            series={percentages}
            type="donut" 
          />
        </Grid>


        <Grid item xs={12}
          md={2.5}
          display="flex"
          justifyContent="center"
        >
          <List
            sx={{
              width: '100%'
            }}
          >
            {loadCategoriesList()}
          </List>
        </Grid>



      </Grid>
    </Card>

  );
}

export default AccountBalance;
