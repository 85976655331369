import { Card, Slider, Grid, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material"
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';




// let  marks = [
//     {
//         value: 0,
//         label: t('No action'),
//     },
//     {
//         value: 1,
//         label: t('First action'),
//     },
//     {
//         value: 2,
//         label: t('Second action'),
//     },
//     {
//         value: 3,
//         label: t('Third action'),
//     },
// ];




function ActionAdder({
    hideClearButton,
    reset,
    clearButton,
    presaveAction,
    switchLanguage,
    emptyFieldsError,
    errorPercentages,
    setPercentageError,
    disabledCategoryField,
    addAction,
    prefilledValues,
    handlePrefilledValues,
    toUpdate,
    setToUpdate,
    updateAction,
    translationLanguage
}) {


    const [actionValue, setActionValue] = useState(0);

    const { t } = useTranslation();

    const checkIfFloat = (string, errorArrayPosition) => {

        let currentErrors = [...errorPercentages]

        if (string.length === 0) {
            currentErrors[errorArrayPosition] = false
            setPercentageError(currentErrors)
            return true;
        }


        if (string.charAt(0) === "0" && string.charAt(1) === ".") {

            const num = Number(string.substring(2));

            if (Number.isInteger(num) && num > 0) {
                currentErrors[errorArrayPosition] = false
                setPercentageError(currentErrors)
                return true;
            }
        }

        currentErrors[errorArrayPosition] = true
        setPercentageError(currentErrors)
        return false

    }


    return (
        <>
            <Card >

                <Box>
                    <Grid direction="column" container xs={12} sx={{ ml: 2, mt: 1 }}>

                        <Grid direction="row" container sx={{ mt: 1 }} >

                            <Grid item >
                                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Action Language</InputLabel>
                                    <Select
                                        label="Translation selector"
                                        variant="standard"
                                        value={translationLanguage}
                                        onChange={(e) => {
                                            switchLanguage(e.target.value)
                                        }}
                                    >
                                        <MenuItem value={"en"}>English</MenuItem>
                                        <MenuItem value={"gr"}>Greek</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item sx={{ ml: 10 }}>
                                <Tooltip title={t('Please add the title of the new action.')}>
                                    <TextField id="standard-basic"
                                        label={t('Action Label')}
                                        variant="standard"
                                        value={prefilledValues[0]}
                                        onChange={(e) => {
                                            handlePrefilledValues(e.target.value, 0)
                                            hideClearButton(false)
                                        }}
                                        onClick={() => setActionValue(0)}
                                    />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>


                <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mt: 2 }}
                >
                    <Slider
                        style={{ width: "80%", height: 7, color: "primary" }}
                        step={1}
                        min={0}
                        max={3}
                        marks={[
                            {
                                value: 0,
                                label: t('No Action'),
                            },
                            {
                                value: 1,
                                label: t('First Action'),
                            },
                            {
                                value: 2,
                                label: t('Second Action'),
                            },
                            {
                                value: 3,
                                label: t('Third Action'),
                            }
                        ]}
                        value={actionValue}

                    />
                </Box>

                <Box sx={{ ml: 2 }}>

                    <Grid direction="column">

                        <Grid item>
                            <Grid direction="row" container xs={12} >
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                {/* <Tooltip disableFocusListener disableTouchListener   title="Is this action a Citizen or a Government action?"> */}
                                        <FormControl variant="standard" style={{ minWidth: 190 }}>
                                            
                                            <InputLabel id="demo-simple-select-standard-label"> {t('Category')} </InputLabel>
                                            
                                            <Select
                                                disabled={disabledCategoryField}
                                                id="standard-basic"
                                                label='Category'
                                                variant="standard"
                                                value={prefilledValues[1]}
                                                onChange={(e) => { handlePrefilledValues(e.target.value, 1) }}
                                                onClick={() => setActionValue(0)}
                                            >
                                                <MenuItem value={"Citizen"}> {t('Citizen')} </MenuItem>
                                                <MenuItem value={"Government"}> {t('Government')} </MenuItem>
                                                <MenuItem value={"localGov"}> {t('Local Government')} </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* </Tooltip> */}
                                    
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Contribution Admin Page Text")} </Typography>
                                </Grid>

                                <Tooltip title={t('Please choose the percentage of the implementation of your action')}>
                                    <TextField id="standard-basic"                                    
                                        label={t('Contribution')}                                   
                                        variant="standard"
                                        disabled={disabledCategoryField}
                                        value={prefilledValues[2]}
                                        onChange={(e) => {
                                            handlePrefilledValues(e.target.value, 2)
                                            checkIfFloat(e.target.value, 0)
                                        }}
                                        onClick={() => setActionValue(0)}
                                    />
                                </Tooltip>

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    {(errorPercentages[0] === true) ? (
                                        // <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage Error Text")} </Typography>
                                        <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("You have to enter a number between 0 and 1 that represents a percentage")} </Typography>
                                    ) : (null)}
                                </Grid>



                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Title 1 Admin Page Text")} </Typography>
                                </Grid>

                                <TextField id="standard-basic"
                                    label={t('Title') + " 1"}
                                    // label="Title"
                                    variant="standard"
                                    value={prefilledValues[3]}
                                    onChange={(e) => { handlePrefilledValues(e.target.value, 3) }}
                                    onClick={() => setActionValue(1)}
                                />


                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >
                                <Grid item xs={4} sx={{ mb: 0 }}>                                   
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage 1 Admin Page Text")} </Typography>
                                </Grid>

                                <Tooltip title={t('Please choose the percentage of the implementation of the first stage of your action')}>
                                    <TextField id="standard-basic"
                                        label={t('Percentage') + " 1"}
                                        disabled={disabledCategoryField}
                                        variant="standard"
                                        value={prefilledValues[4]}
                                        onChange={(e) => {
                                            handlePrefilledValues(e.target.value, 4)
                                            checkIfFloat(e.target.value, 1)
                                        }}
                                        onClick={() => setActionValue(1)}
                                    />
                                </Tooltip>

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    {(errorPercentages[1] === true) ? (
                                        // <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage Error Text")} </Typography>
                                        <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("You have to enter a number between 0 and 1 that represents a percentage")} </Typography>
                                    ) : (null)}
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Title 2 Admin Page Text")} </Typography>
                                </Grid>
    
                                <TextField id="standard-basic"
                                    label={t('Title') + " 2"}
                                    variant="standard"
                                    value={prefilledValues[5]}
                                    onChange={(e) => { handlePrefilledValues(e.target.value, 5) }}
                                    onClick={() => setActionValue(2)}

                                />

                            </Grid>
                        </Grid>


                        <Grid item xs={3} >
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage 2 Admin Page Text")} </Typography>
                                </Grid>

                                <Tooltip title="Please choose the percentage of implementation of the second stage of your action">
                                    <TextField id="standard-basic"
                                        label={t('Percentage') + " 2"}
                                        disabled={disabledCategoryField}
                                        variant="standard"
                                        value={prefilledValues[6]}
                                        onChange={(e) => {
                                            handlePrefilledValues(e.target.value, 6)
                                            checkIfFloat(e.target.value, 2)

                                        }}
                                        onClick={() => setActionValue(2)}
                                    />
                                </Tooltip>

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    {(errorPercentages[2] === true) ? (
                                        // <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage Error Text")} </Typography>
                                        <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("You have to enter a number between 0 and 1 that represents a percentage")} </Typography>
                                    ) : (null)}
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Title 3 Admin Page Text")} </Typography>
                                </Grid>

                                <TextField id="standard-basic"
                                    label={t('Title') + " 3"}
                                    variant="standard"
                                    value={prefilledValues[7]}
                                    onChange={(e) => { handlePrefilledValues(e.target.value, 7) }}
                                    onClick={() => setActionValue(3)}

                                />


                            </Grid>
                        </Grid>


                        <Grid item>
                            <Grid direction="row" container >

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    <Typography align="center" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage 3 Admin Page Text")} </Typography>
                                </Grid>

                                <Tooltip title="Please choose the percentage of implementation of the third stage of your action">
                                    <TextField id="standard-basic"
                                        label={t('Percentage') + " 3"}
                                        variant="standard"
                                        disabled={disabledCategoryField}
                                        value={prefilledValues[8]}
                                        onChange={(e) => {
                                            handlePrefilledValues(e.target.value, 8)
                                            checkIfFloat(e.target.value, 3)

                                        }}
                                        onClick={() => setActionValue(3)}
                                    />
                                </Tooltip>

                                <Grid item xs={4} sx={{ mb: 0 }}>
                                    {(errorPercentages[3] === true) ? (
                                        // <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Action Percentage Error Text")} </Typography>
                                        <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("You have to enter a number between 0 and 1 that represents a percentage")} </Typography>
                                    ) : (null)}
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Box>

                {(clearButton === false) ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: 1.5, mb: 1.5 }}
                    >
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => {
                                reset()
                            }}
                        >
                            {t('Clear')}
                        </Button>
                    </Box>
                ) : (null)}


                {(toUpdate === 'false') ? (

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: 1.5, mb: 1.5 }}
                    >
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => {
                                addAction()
                                setActionValue(0)
                            }}
                        >
                            {t('Add action')}
                        </Button>
                        {(disabledCategoryField === true) ? (

                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    presaveAction()
                                }}
                            >
                                add translation
                            </Button>

                        ) : (null)}



                    </Box>
                ) : (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ mt: 1.5, mb: 1.5 }}
                        >
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    reset()
                                    setToUpdate("false")
                                    setActionValue(0)
                                }}          >
                                {t('Cancel')}
                            </Button>
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    updateAction()
                                    setToUpdate("false")
                                    setActionValue(0)
                                }}
                            >
                                {t('Update')}
                            </Button>
                            {(disabledCategoryField === true) ? (

                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => {
                                        presaveAction()
                                    }}
                                >
                                    add translation
                                </Button>

                            ) : (null)}


                        </Box>
                    </>

                )}

                {(emptyFieldsError === true) ? (
                    <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Empty Fields Error Text")} </Typography>
                ) : (null)}




            </Card>


        </>


    );
}

export default ActionAdder;
