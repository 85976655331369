import React from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  // Typography,
  Button,
  Grid,
  CardContent,
  CardHeader,
  Card,
  useTheme,
} from '@mui/material';
import { useState, useEffect } from "react";
import axios from "axios";
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';


const GraphWithFilter = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  let labels = [t('Vehicles'), t('Flights'), t('Trains'), t('Buildings'), t('Waste'), t('Total')]
 
  const [belgiumUnique, setBelgiumUnique] = useState([])
  const [germanyUnique, setGermanyUnique] = useState([])
  const [greeceUnique, setGreeceUnique] = useState([])
  const [bulgariaUnique, setBulgariaUnique] = useState([])

  // eslint-disable-next-line
  const [selectedCountryData, setSelectedCountryData] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]) 

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const [municipalRegions, setMunicipalRegions] = useState([]); // Add this line
  const [selectedMunicipalRegion, setSelectedMunicipalRegion] = useState(''); // Add this line

  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedSocialAid, setSelectedSocialAid] = useState([]);
  const [selectedEducationLevel, setSelectedEducationLevel] = useState([]);
  
  const [dataToPresent, setDataToPresent] = useState([
    {
      name: t('Average'),
      data: [0, 0, 0, 0, 0, 0], // or any initial values you prefer
    },
  ]);

  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev/"
  });

  useEffect(() => {
    client.get("/belgiumUnique")
        .then((response) => {
          setBelgiumUnique(() => {
            return response.data;
          });
        });
    client.get("/germanyUnique")
        .then((response) => {
            setGermanyUnique(() => {
              return response.data;
            });
        });
    client.get("/greeceUnique")
        .then((response) => {
            setGreeceUnique(() => {
              return response.data;
            });
        });
    client.get("/bulgariaUnique")
        .then((response) => {
            setBulgariaUnique(() => {
              return response.data;
            });
        });
}, []);

  const handleCountryChange = async (event) => {
    const country = event.target.value;
    // setSelectedCountry(value); 

    if (country === 'BELGIUM') {
      setSelectedCountryData(belgiumUnique);
      setSelectedCountry('BELGIUM');
    }
    if (country === 'BULGARIA') {
      setSelectedCountryData(bulgariaUnique);
      setSelectedCountry('BULGARIA');
    }
    if (country === 'GERMANY') {
      setSelectedCountryData(germanyUnique);
      setSelectedCountry('GERMANY');
    }
    if (country === 'GREECE') {
      setSelectedCountryData(greeceUnique);
      setSelectedCountry('GREECE');
    }
    setSelectedCity(''); // Reset city when country changes
    setSelectedMunicipalRegion(''); // Reset municipal region when country changes
    setSelectedAge(''); // Reset age when country changes
    setSelectedGender(''); // Reset gender when country changes
    setSelectedEducationLevel(''); // Reset education level when country changes
    setSelectedSocialAid(''); // Reset social aid when country changes
    
    await applyFilters();
  };

  useEffect(() => {
    applyFilters(); // Call applyFilters when the component mounts
  }, [selectedCountry, selectedCity, selectedMunicipalRegion, selectedAge, selectedGender, selectedSocialAid, selectedEducationLevel]);

  const handleCityChange = async (event) => {
    const city = event.target.value;
    setSelectedCity(city);
    setSelectedMunicipalRegion(''); // Reset selected municipal region

    await applyFilters();
  };

  const countryCityMapping = {
    BELGIUM: ['brussels', 'Other city or village'],
    BULGARIA: ['plovdiv', 'sofia', 'Other city or village'],
    GERMANY: ['berlin', 'Other city or village'],
    GREECE: ['athens', 'Other city or village'],
  };

const municipalRegionMapping = {
  BELGIUM: {
    brussels: [],
  },
  BULGARIA: {
    plovdiv: [],
    sofia: [],
  },
  GERMANY: {
    berlin: [],
  },
  GREECE: {
    athens: [
      'Πλάκα', 
      'Κολωνάκι',
      'Σύνταγμα',
      'Ομόνοια',
      'Μοναστηράκι',
      'Εξάρχεια',
      'Ιλίσια',
      'Νεάπολη',
      'Μετς',
      'Νέος Κόσμος',
      'Άγιος Αρτέμιος',
      'Παγκράτι',
      'Βοτανικός',
      'Μεταξουργείο',
      'Γκάζι',
      'Πετράλωνα',
      'Ρουφ',
      'Θησείο',
      'Ακαδημία Πλάτωνος',
      'Κολοκυνθού',
      'Κολωνός',
      'Σεπόλια',
      'Άγιος Νικόλαος',
      'Πλατεία Αττικής',
      'Ριζούπολη',
      'Πατήσια',
      'Άγιος Ελευθέριος',
      'Πλατεία Κολιάτσου',
      'Πλατεία Βικτωρίας',
      'Σταθμός Λαρίσης',
      'Πεδίον του Άρεως',
      'Πατήσια',
      'Κυψέλη',
      'Πολύγωνο',
      'Γκύζη',
      'Νέα φιλοθέη',
      'Αμπελόκηποι',
      'Ελληνορώσων',
    ],
  },
};

const handleMunicipalRegionChange = async (event) => { // Add this function
  const municipalRegion = event.target.value;
  setSelectedMunicipalRegion(municipalRegion);
  await applyFilters();
};

useEffect(() => {
  // Update municipal regions when the selected city changes
  setMunicipalRegions(municipalRegionMapping[selectedCountry]?.[selectedCity] || []);
}, [selectedCity, selectedCountry]);

useEffect(() => {
  // Update cities when the selected country changes
  setCities(countryCityMapping[selectedCountry] || []);
}, [selectedCountry]);


const handleAgeChange = async (event) => {
  setSelectedAge(event.target.value);
  await applyFilters()
};

const handleGenderChange = async (event) => {
  setSelectedGender(event.target.value);
  await applyFilters()
};

const handleSocialAidChange = async (event) => {
  setSelectedSocialAid(event.target.value);
  await applyFilters()
};

const handleEducationLevelChange = async (event) => {
  setSelectedEducationLevel(event.target.value);
  await applyFilters()
};

const applyFilters = async () => {
  try {
    let response;

    let count = 0;
    let totalCO2 = 0;
    let transportationResults = 0;
    let flightResults = 0;
    let trainResults = 0;
    let buildingResults = 0;
    let wasteResults = 0;
    let avgTotalCO2;
    let avgTransportationResults;
    let avgFlightResults;
    let avgTrainResults;
    let avgBuildingResults;
    let avgWasteResults;

    if (selectedCountry === 'BELGIUM') {
      response = await client.get("/belgiumUnique/query", {
        params: {
          city: selectedCity,
          age: selectedAge, 
          gender: selectedGender, 
          socialAid: selectedSocialAid, 
          educationLevel: selectedEducationLevel, 
          municipalCommunity: selectedMunicipalRegion, 
        },
      });

      response.data.forEach(item => {
        count ++;
        totalCO2 += parseFloat(item.totalCO2);
        transportationResults += item.transportationResults;
        flightResults += item.flightResults;
        trainResults += item.trainResults;
        buildingResults += item.buildingResults;
        wasteResults += item.wasteResults;
      });
   
      avgTotalCO2 = (totalCO2/count).toFixed(2);
      avgTransportationResults = (transportationResults/count).toFixed(2);
      avgFlightResults = (flightResults/count).toFixed(2);
      avgTrainResults = (trainResults/count).toFixed(2);
      avgBuildingResults = (buildingResults/count).toFixed(2);
      avgWasteResults = (wasteResults/count).toFixed(2);

      setDataToPresent([
        {
          name: t('Average'),
          data: [avgTransportationResults, avgFlightResults, avgTrainResults, avgBuildingResults, avgWasteResults, avgTotalCO2],
        },
      ]);
    }
    else if (selectedCountry === 'BULGARIA') {
      response = await client.get("/bulgariaUnique/query", {
        params: {
          city: selectedCity,
          age: selectedAge, 
          gender: selectedGender, 
          socialAid: selectedSocialAid, 
          educationLevel: selectedEducationLevel, 
          municipalCommunity: selectedMunicipalRegion,
        },
      });

      if (response.data != null) {
        response.data.forEach(item => {
          count ++;
          totalCO2 += parseFloat(item.totalCO2);
          transportationResults += item.transportationResults;
          flightResults += item.flightResults;
          trainResults += item.trainResults;
          buildingResults += item.buildingResults;
          wasteResults += item.wasteResults;
        });
     
        avgTotalCO2 = (totalCO2/count).toFixed(2);
        avgTransportationResults = (transportationResults/count).toFixed(2);
        avgFlightResults = (flightResults/count).toFixed(2);
        avgTrainResults = (trainResults/count).toFixed(2);
        avgBuildingResults = (buildingResults/count).toFixed(2);
        avgWasteResults = (wasteResults/count).toFixed(2);
  
        setDataToPresent([
          { name: t('Average'), data: [transportationResults, flightResults, avgTrainResults, avgBuildingResults, avgWasteResults, avgTotalCO2] },
        ])
      }
      else {
        setDataToPresent([
          { name: t('Average'), data: [0, 0, 0, 0, 0, 0] },
        ])
      }
    }
    else if (selectedCountry === 'GERMANY') {
      response = await client.get("/germanyUnique/query", {
        params: {
          city: selectedCity,
          age: selectedAge, 
          gender: selectedGender, 
          socialAid: selectedSocialAid, 
          educationLevel: selectedEducationLevel,
          municipalCommunity: selectedMunicipalRegion, 
        },
      });

      response.data.forEach(item => {
        count ++;
        totalCO2 += parseFloat(item.totalCO2);
        transportationResults += item.transportationResults;
        flightResults += item.flightResults;
        trainResults += item.trainResults;
        buildingResults += item.buildingResults;
        wasteResults += item.wasteResults;
      });
   
      avgTotalCO2 = (totalCO2/count).toFixed(2);
      avgTransportationResults = (transportationResults/count).toFixed(2);
      avgFlightResults = (flightResults/count).toFixed(2);
      avgTrainResults = (trainResults/count).toFixed(2);
      avgBuildingResults = (buildingResults/count).toFixed(2);
      avgWasteResults = (wasteResults/count).toFixed(2);

      setDataToPresent([
        { name: t('Average'), data: [transportationResults, flightResults, avgTrainResults, avgBuildingResults, avgWasteResults, avgTotalCO2] },
      ])
    }
    else if (selectedCountry === 'GREECE') {
      response = await client.get("/greeceUnique/query", {
        params: {
          city: selectedCity,
          age: selectedAge, 
          gender: selectedGender, 
          socialAid: selectedSocialAid, 
          educationLevel: selectedEducationLevel, 
          municipalCommunity: selectedMunicipalRegion, 
        },
      });

      response.data.forEach(item => {
        count ++;
        totalCO2 += parseFloat(item.totalCO2);
        transportationResults += item.transportationResults;
        flightResults += item.flightResults;
        trainResults += item.trainResults;
        buildingResults += item.buildingResults;
        wasteResults += item.wasteResults;
      });
   
      avgTotalCO2 = (totalCO2/count).toFixed(2);
      avgTransportationResults = (transportationResults/count).toFixed(2);
      avgFlightResults = (flightResults/count).toFixed(2);
      avgTrainResults = (trainResults/count).toFixed(2);
      avgBuildingResults = (buildingResults/count).toFixed(2);
      avgWasteResults = (wasteResults/count).toFixed(2);

      setDataToPresent([
        { name: t('Average'), data: [avgTransportationResults, avgFlightResults, avgTrainResults, avgBuildingResults, avgWasteResults, avgTotalCO2] },
      ])
    }
    else {
      setDataToPresent([
        { name: t('Average'), data: [0, 0, 0, 0, 0, 0] },
      ])
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  } 
}

function capitalizeFirstLetter(string) {
  return t(string.charAt(0).toUpperCase() + string.slice(1));
}


const state = {
  chart: {
    id: "basic-bar",
    background: 'transparent',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 5,
      columnWidth: '50%',
      distributed: true,
    }
  },
  colors: ['#0D6EFD', '#DC3545', '#198754', '#FFC107', '#0DCAF0', '#BCC1CB'],  
  theme: {
    mode: theme.palette.mode
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  legend: {
    show: true
  },
  markers: {
    hover: {
      sizeOffset: 2
    },
    shape: 'circle',
    size: 7,
    strokeWidth: 2,
    strokeOpacity: 1,
    strokeColors: theme.colors.alpha.white[100],
    colors: theme.colors.success.main
  },
  grid: {
    strokeDashArray: 5,
    borderColor: theme.palette.divider
  },
  yaxis: {
    show: false,
    min: 0,
    axisBorder: {
      show: false
    },
    categories: labels,
  },
  labels: labels,  
  xaxis: {
    categories: labels,
    labels: {
      show: true,
      style: {
        fontSize: '11px'
    }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
  },
};

const capitalizeOnlyFirstLetter = (inputString) => {
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString; // Return unchanged if input is not a string or is an empty string
  }

  const lowercasedString = inputString.toLowerCase();
  const capitalizedString = lowercasedString.charAt(0).toUpperCase() + lowercasedString.slice(1);

  return capitalizedString;
};

  // Function to format data into CSV
  const convertDataToCSV = () => {
    const headers = ['Category', 'Transportation', 'Flight', 'Train', 'Building', 'Waste', 'Total CO2'];
    const rows = dataToPresent.map((item) => {
      return [item.name, ...item.data];
    });

    const fixedCity = capitalizeFirstLetter(selectedCity);
    const fixedCountry = capitalizeOnlyFirstLetter(selectedCountry);
    const fixeEducationLevel = capitalizeFirstLetter(selectedEducationLevel);

    // Custom first row
    const customFirstRow1 = ['Selected Filters'];
    const customFirstRow2 = ['Country', fixedCountry, '', 'City', fixedCity, '', 'Municipal Region', selectedMunicipalRegion];
    const customFirstRow3 = ['Age', selectedAge, '', 'Gender', selectedGender];
    const customFirstRow4 = ['Social Aid', selectedSocialAid, '', 'Education Level', fixeEducationLevel];
    const customFirstRow5 = [''];

    const csvContent = [customFirstRow1.join(','),customFirstRow2.join(','),customFirstRow3.join(','),customFirstRow4.join(','),customFirstRow5.join(','),headers.join(','), ...rows.map(row => row.join(','))].join('\n');

    const bom = '\uFEFF';
    const csvDataWithBOM = bom + csvContent;

    return csvDataWithBOM;
  };

  // Function to trigger the download
  const handleExportCSV = () => {
    const csvData = convertDataToCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'carbon_footprint_data.csv');
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };


return (
  <Container>

    { /* Choose country */ }
    <Grid container spacing={3} style={{ paddingTop: '20px' }}>
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
          <InputLabel id="filter-country"> {t('Select Country')} </InputLabel>
          <Select
            labelId="filter-country"
            id="filterCountry"
            // value={filters.filter}
            onChange={handleCountryChange}
            label="Select Country"
          >
            <MenuItem value=""> {t('Select Country')} </MenuItem>
            <MenuItem value="BELGIUM"> {t('Belgium')} </MenuItem>
            <MenuItem value="BULGARIA"> {t('Bulgaria')} </MenuItem>
            <MenuItem value="GERMANY"> {t('Germany')} </MenuItem>
            <MenuItem value="GREECE"> {t('Greece')} </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>

    { /* Choose city, municipal region, age */ }
    <Grid container spacing={3} style={{ paddingTop: '20px' }}>

    { /* Choose city */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
        <InputLabel id="city-label"> {t('Select City')} </InputLabel>
        <Select
          labelId="city-label"
          id="city"
          value={selectedCity}
          onChange={handleCityChange}
        >
          <MenuItem value=""> {t('Select City')} </MenuItem>
          {cities.map((city) => (
            <MenuItem key={city} value={city}>
              {capitalizeFirstLetter(city)}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>

      { /* Choose Municipal Community */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
        <InputLabel id="municipal-label"> {t('Select Municipal Region')} </InputLabel>
        <Select
          labelId="municipal-label"
          id="municipal"
          value={selectedMunicipalRegion}
          onChange={handleMunicipalRegionChange}
        >
          <MenuItem value=""> {t('Select Municipal Region')} </MenuItem>
          {municipalRegions.map((municipalRegion) => (
            <MenuItem key={municipalRegion} value={municipalRegion}>
              {municipalRegion}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>

      { /* Choose age */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
          <InputLabel id="filter-label"> {t('Select Age')} </InputLabel>
          <Select
            labelId="age-label"
            id="age"
            value={selectedAge}
            onChange={handleAgeChange}
            label="Select Age"
          >
            <MenuItem value=""> {t('Select Age')} </MenuItem>
              <MenuItem value={"Below 18"}> {t('Below 18')} </MenuItem>
              <MenuItem value={"18-24"}>18-24</MenuItem>
              <MenuItem value={"25-34"}>25-34</MenuItem>
              <MenuItem value={"35-44"}>35-44</MenuItem>
              <MenuItem value={"45-54"}>45-54</MenuItem>
              <MenuItem value={"55-65"}>55-65</MenuItem>
              <MenuItem value={"Above 65"}> {t('Above 65')} </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      </Grid>


    { /* Choose gender, Social Aid, Education Level */ }
    <Grid container spacing={3} style={{ paddingTop: '20px' }}>

    { /* Choose gender */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
          <InputLabel id="filter-label"> {t('Select Gender')} </InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            value={selectedGender}
            onChange={handleGenderChange}
            label="Select Gender"
          >
              <MenuItem value=""> {t('Select Gender')} </MenuItem>
              <MenuItem value={"Male"}> {t('Male')}</MenuItem>
              <MenuItem value={"Female"}> {t('Female')}</MenuItem>
              <MenuItem value={"Non-binary"}> {t('Non-binary')}</MenuItem>
              <MenuItem value={"Do not wish to specify"}> {t('Do not wish to specify')}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    

    { /* Choose Social Aid */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
          <InputLabel id="filter-label"> {t('Select Social Aid')} </InputLabel>
          <Select
            labelId="social-aid-label"
            id="social-aid"
            value={selectedSocialAid}
            onChange={handleSocialAidChange}
            label="Select Social Aid"
          >
              <MenuItem value=""> {t('Select Social Aid')} </MenuItem>
              <MenuItem value={"Yes"}>{t("Yes")}</MenuItem>
              <MenuItem value={"No"}>{t("No")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      { /* Choose Education Level */ }
      <Grid item xs={12} md={4} >
        <FormControl fullWidth>
          <InputLabel id="filter-label"> {t('Select Education Level')} </InputLabel>
          <Select
            labelId="education-label"
            id="education"
            value={selectedEducationLevel}
            onChange={handleEducationLevelChange}
            label="Select Education Level"
          >
            <MenuItem value=""> {t('Select Education Level')} </MenuItem>
            <MenuItem value={"primary"}>{t("Primary Education")}</MenuItem>
            <MenuItem value={"secondary"}>{t("Secondary Education")}</MenuItem>
            <MenuItem value={"university"}>{t("University Education")}</MenuItem>
            <MenuItem value={"postgraduate"}>{t("Postgraduate Education")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

        {/* Export Button */}
        <Grid item xs={12} md={4}>
          <Button variant="contained" onClick={handleExportCSV}>
            {t('Export')}
          </Button>
        </Grid>            
      </Grid>

    <Card>
    <CardHeader
      sx={{ p: 3 }}
      titleTypographyProps={{
        component: 'h5',
        variant: 'h6',
        fontWeight: 'bold',
        sx: {
          textTransform: 'uppercase',
          textAlign: 'center'
        }
      }}
      title={t('Carbon Footprint Calculator Statistics')}
    />
    <CardContent>
      <Chart
          options={state}
          series={dataToPresent}
        type="bar"
        height={500}
      />
    </CardContent>
  </Card>
  </Container>

);

};

export default GraphWithFilter;