import React from "react";
import { useState, useEffect} from "react";
import NewProgressBar from "./NewProgressBar";
import ChartContent from "./ChartContent";
import ScenarioListUser from './ScenarioListUser';
import { Helmet } from 'react-helmet-async';
import { Grid, Box, Typography } from '@mui/material';
import ActionListUser from './ActionListUser';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import jwtDecode  from 'jwt-decode';


const Simulation = () => {

  let userCountry = "Greece"

  const { i18n, t } = useTranslation();

  useEffect(() => {
  //  console.log(i18n.language)
  }, [i18n.language]);

  const initialValues = [6.39, 6.39, 6.39, 6.39]
  const [totalData, setTotalData] = useState([6.39, 6.39, 6.39, 6.39]);
  const [progress, setProgress] = useState(0);

  const [slidersMap, setSliders] = useState(new Map())

  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  useEffect(() => {
    // Calculate saveButtonEnabled based on progress
    const calculatedSaveButtonEnabled = progress >= 55;
    setSaveButtonEnabled(calculatedSaveButtonEnabled);
  }, [progress]);

  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev"
  });

  useEffect(() => {
    client.get("/actions")
      .then((response) => {
        response.data.forEach(function (slider) {
          setSliders(new Map(slidersMap.set(slider._id, slider)))
        },
        );
      });
  }, []);



  const [scenarioMap, setScenarioMap] = useState(new Map());

  useEffect(() => {
    client.get("/scenarios")
      .then((response) => {
        response.data.forEach(function (scenario) {
          setScenarioMap(new Map(scenarioMap.set(scenario._id, scenario)))
        },
        );
      });
  }, []);


  const [scenarios, manageScenarios] = useState(scenarioMap);


  const wtf = (percentageSum, dataIterator) => {

    let datadiffsum = 0
    slidersMap.forEach((slider) => {

      if (dataIterator === 0) {
        percentageSum += slider["countriesData"][userCountry]["percentages"][slider.value] * slider["countriesData"][userCountry]["contribution"]
      }
    let datadiff = initialValues[dataIterator] * slider["countriesData"][userCountry]["percentages"][slider.value] * slider["countriesData"][userCountry]["contribution"]
      datadiffsum += datadiff

    })

    return [datadiffsum, percentageSum]
  }

  const calculateChart = () => {

    let newData = []
    let percentageSum = 0
    let datadiffsum = [0, 0]

    for (let dataIterator = 0; dataIterator < initialValues.length; dataIterator++) {

      datadiffsum = wtf(datadiffsum[1], dataIterator)

      newData.push(initialValues[dataIterator] - datadiffsum[0])

    }
    percentageSum = datadiffsum[1]
    percentageSum *= 100
    setProgress(((percentageSum * 10) / 10).toFixed(2))
    console.log('percentage')
    console.log(progress)
    setTotalData(newData)
  }


  const handleChart = (newState, sliderID) => {

    let tempSliderObject = slidersMap.get(sliderID)
    tempSliderObject.value = newState

    setSliders(slidersMap.set(sliderID, tempSliderObject))

    calculateChart()
  };

  const chartHandler = (sliderIDlist, newStateList) => {

    for (let sliderIDIterator = 0; sliderIDIterator < sliderIDlist.length; sliderIDIterator++) {

      if (slidersMap.has(sliderIDlist[sliderIDIterator])) {
        let tempSliderObject = slidersMap.get(sliderIDlist[sliderIDIterator])
        tempSliderObject.value = newStateList[sliderIDIterator]
        setSliders(slidersMap.set(sliderIDlist[sliderIDIterator], tempSliderObject))
      }

    }
    calculateChart()
  }


  const getCurrentSliderStates = () => {
    let currentStates = []

    slidersMap.forEach(slider => {
      currentStates.push(slider.value)
    })

    return currentStates
  }

  const getCurrentSliderIDs = () => {
    let currentStates = []

    slidersMap.forEach(slider => {
      currentStates.push(slider._id)
    })

    return currentStates
  }

  const addScenario = (scenarioName) => {
    let newid = Math.floor(Math.random() * (1000 - 5) + 5)
    let currentStates = getCurrentSliderStates()
    let currentIDs = getCurrentSliderIDs()

    manageScenarios(new Map(scenarios.set(newid, {
      id: newid,
      title: scenarioName,
      category: 'User',
      logo: '/static/images/placeholders/logo/discord-icon.svg',
      sliderIDs: [...currentIDs],
      sliderValues: [...currentStates]
    })))
  }

  const deleteScenario = (scenarioID) => {
    let temp = new Map(scenarios)

    temp.delete(scenarioID)

    manageScenarios(new Map(temp))
  }

  const addSlider = (id, category, name, title1, title2, title3, title4, id2, contribution, percentage1, percentage2, percentage3, percentage4) => {
    setSliders(new Map(slidersMap.set(id2, {
      id: id,
      category: category,
      name: name,
      titles: [title1, title2, title3, title4],
      id2: id2,
      value: 0,
      contribution: contribution,
      percentages: [percentage1, percentage2, percentage3, percentage4]
    })))

  }
  //      

  const saveUserScenario = () => {
    let labels = []
    let texts = []
    let stages = []

    const accessToken = localStorage.getItem('accessToken');   
    let sub = null;
    if (accessToken != null) {
      const decoded = jwtDecode(accessToken);
       sub = decoded.sub;
    }

    slidersMap.forEach(slider => {

      let text = slider.titles["en"][slider.value]
      // if(text.length !== 0){
      labels.push(slider["name"]["en"])
      texts.push(text)
      stages.push(slider.value)
      // }
    })


    // console.log(
      client.post("/userScenario", {
      userID: sub,
      actionNames: [...labels],
      actionsSelectedTitles: [...texts],
      stages: [...stages]
    })
    // )

    updateUniqueUserScenarios();
  }

  const [scenarioCountry, setScenarioCountry] = useState();
  const [calculation, setCalculation] = useState();


  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem('accessToken');
      let sub = null;

      if (accessToken != null) {
        const decoded = jwtDecode(accessToken);
        sub = decoded.sub;

        client.get(`/belgiumUnique/${sub}`)
        .then(response => {
          if (response.data) {
            setCalculation(response.data[0]);
            setScenarioCountry('belgium');
          }
        });

        client.get(`/greeceUnique/${sub}`)
        .then(response => {
          if (response.data) {
            setCalculation(response.data[0]);
            setScenarioCountry('greece');
          }
        });
  
        client.get(`/germanyUnique/${sub}`)
          .then(response => {
            if (response.data) {
              setCalculation(response.data[0]);
              setScenarioCountry('germany');
            }
        });
  
        client.get(`/bulgariaUnique/${sub}`)
          .then(response => {
            if (response.data) {
              setCalculation(response.data[0]);
              setScenarioCountry('bulgaria');
            }
        });
      }
    };

    
    fetchData();
  }, []); 

  useEffect(() => {
    // console.log(scenarioCountry); 
    // console.log(calculation.city)
  }, [scenarioCountry]); // This effect will run whenever scenarioCountry changes
  
  const updateUniqueUserScenarios = () =>  {

    const accessToken = localStorage.getItem('accessToken');   
    let sub = null;
    if (accessToken != null) {
      const decoded = jwtDecode(accessToken);
       sub = decoded.sub;
    }

    // let country = null;
    // console.log(country);
    // console.log(scenarioCountry);
    // console.log(sub);
    
    let labels = []
    let texts = []
    let stages = []

    slidersMap.forEach(slider => {

      let text = slider.titles["en"][slider.value]
      labels.push(slider["name"]["en"])
      texts.push(text)
      stages.push(slider.value)
      // }
    })

    if (sub != null) {
      if (scenarioCountry === 'belgium') {
        client.post("/belgiumUniqueScenario", {
          userID: sub,
          city: calculation.city,
          age: calculation.age,
          gender: calculation.gender,
          socialAid: calculation.socialAid,
          educationLevel: calculation.educationLevel,
          municipalCommunity: calculation.municipalCommunity,
          actionNames: [...labels],
          actionsSelectedTitles: [...texts],
          stages: [...stages]
        })
      }

      if (scenarioCountry === 'bulgaria') {
        client.post("/bulgariaUniqueScenario", {
          userID: sub,
          city: calculation.city,
          age: calculation.age,
          gender: calculation.gender,
          socialAid: calculation.socialAid,
          educationLevel: calculation.educationLevel,
          municipalCommunity: calculation.municipalCommunity,
          actionNames: [...labels],
          actionsSelectedTitles: [...texts],
          stages: [...stages]
        })
      }

      if (scenarioCountry === 'greece') {
        client.post("/greeceUniqueScenario", {
          userID: sub,
          city: calculation.city,
          age: calculation.age,
          gender: calculation.gender,
          socialAid: calculation.socialAid,
          educationLevel: calculation.educationLevel,
          municipalCommunity: calculation.municipalCommunity,
          actionNames: [...labels],
          actionsSelectedTitles: [...texts],
          stages: [...stages]
        })
      }

      if (scenarioCountry === 'germany') {
        client.post("/germanyUniqueScenario", {
          userID: sub,
          city: calculation.city,
          age: calculation.age,
          gender: calculation.gender,
          socialAid: calculation.socialAid,
          educationLevel: calculation.educationLevel,
          municipalCommunity: calculation.municipalCommunity,
          actionNames: [...labels],
          actionsSelectedTitles: [...texts],
          stages: [...stages]
        })
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Scenario Simulation - Carbon Footprint Simulation Dashboard - CompAir</title>
      </Helmet>

      <Grid container justifyContent="center" spacing={3} marginTop={1} >

        <Grid item xs={10} md={4} lg={2} marginTop={8.7} marginBottom={2} order={{ xs: 2, md: 2, lg: 0 }}>
          <ScenarioListUser chartHandler={chartHandler} addScenario={addScenario} scenarios={scenarios} deleteScenario={deleteScenario} />
        </Grid>

        <Grid item xs={10} md={10} lg={6} order={{ xs: 0, md: 0, lg: 1 }}>
 
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '85%', t: 1.5, border: 1, borderColor: "green", borderRadius: "10px" }}
              >
                  <Typography textAlign="center" variant="h3">
                    {t("Emission text")}
                  </Typography>        
              </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              paddingTop: 2, // Add your desired padding value
            }}
          >

            
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '85%', t: 1.5,  borderRadius: "10px" }}
            >    
                <Typography textAlign="center" variant="h4">
                  {t("Emission Tooltip")}
                </Typography>    
            </Box>
          </Box>
          
          <NewProgressBar progress={progress} />
          <ChartContent totalData={totalData} />

        </Grid>

        <Grid item xs={10} md={6} lg={3} marginTop={8.5} order={{ xs: 1, md: 1, lg: 2 }}>

          <ActionListUser 
            handleChart={handleChart} 
            sliders={slidersMap} 
            addSlider={addSlider} 
            saveUserScenario={saveUserScenario} 
            saveButtonEnabled={saveButtonEnabled}
            />


        </Grid>


      </Grid>

    </>
  );
};

export default Simulation;
