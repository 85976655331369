import React from "react";
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next';


const Range = ({ id, name, title, handleChart, value }) => {
  const { t } = useTranslation();



  return (
    <>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 1.5 }}
      >
        <Typography id="sliderLabel" sx={{ textAlign: 'center' }}>
          {t(name)}
          {/* {console.log("range name:", name)} */}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mb: 2 }}
        >
        <Slider
          style={{ height: 7, color: "primary", width: "80%"}}
          id={id}
          value={value}
          step={1}
          min={0}
          max={3}
          marks
          onChange={(e) => {

            if (e.target.value !== value) {
              console.log(e.target.value)
              handleChart(e.target.value, id)
            }
          }}


        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: -1. , mb: 1}}
      >
        <Typography id="sliderTitle" >
          {t(title)}
          {console.log("range title:", title)}
        </Typography>
      </Box>

    </>
  );
};

export default Range;
