import React from 'react';
// import Tooltip from '@mui/material/Tooltip';
import {Box, Grid, TextField} from "@mui/material"
import {Button} from "@mui/material"
import { useTranslation } from 'react-i18next';
// import { useState, useEffect } from 'react';
// import { useEffect } from 'react';


const Flights = ({
  toNextTab,
  toPrevTab,
  // start,
  // end,
  handleFlight,
  formData,
  setFormData
}) => {
  const flightsForm = (e) => {
    e.preventDefault();
    handleFlight();
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray" }} backgroundColor="white">
      <form onSubmit={(e) => { flightsForm(e) }}>
        <Grid container justifyContent="center" alignContent="center">
  
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
            <h5>{t("CO2 from Flights")}</h5>
          </Grid>
  
          <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <h6>{t("Flights Info Text")}</h6>
          </Grid>
  
          <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <p>{t("Flights Info Text 2")}</p>
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("From")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("To")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.startDate}
              defaultValue={formData.startDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.endDate}
              defaultValue={formData.endDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
  
          <Grid item xs={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("Flight Description")}
          </Grid>
  
          <Grid item xs={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("Number of Flights")}
          </Grid>
  
          <Grid item xs={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("Avg Flight Distance")}
          </Grid>
  
          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField value={t("Domestic")} variant="filled" InputProps={{ readOnly: true }} fullWidth multiline rows={1} />
          </Grid>
  
          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              required
              label={t("Required")}
              value={formData.domestic}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              onChange={(e) => setFormData({ ...formData, domestic: e.target.value })}
              fullWidth
            />
          </Grid>
  
          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField defaultValue="500" variant="filled" InputProps={{readOnly: true}} fullWidth/>
          </Grid>

          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField value={t("Short Haul Flight")} variant="filled" InputProps={{readOnly: true}} fullWidth multiline rows={1}/>
          </Grid>

          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField 
              required 
              label={t("Required")}
              value={formData.short} 
              type="number" 
              InputProps={{
                inputProps: { 
                    min: 0
                }}}
              onChange={(e) => setFormData({ ...formData, short: e.target.value }) } 
              fullWidth
            />
          </Grid>

          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField defaultValue="1100" variant="filled" InputProps={{readOnly: true}} fullWidth/>
          </Grid>

          <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField value={t("Medium Haul Flight")} variant="filled" InputProps={{readOnly: true}} fullWidth multiline rows={1}/>
          </Grid>

        <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
          <TextField 
            required 
            label={t("Required")}
            value={formData.medium} 
            type="number" 
            InputProps={{
              inputProps: { 
                  min: 0
              }}}
            onChange={(e) => setFormData({ ...formData, medium: e.target.value }) } 
            fullWidth
          />
        </Grid>

        <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
        <TextField defaultValue="3000" variant="filled" InputProps={{readOnly: true}} fullWidth/>
      </Grid>

      <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
        <TextField value={t("Long Haul Flight")} variant="filled" InputProps={{readOnly: true}} fullWidth multiline rows={1}/>
      </Grid>

      <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
        <TextField 
          required 
          label={t("Required")}      
          value={formData.long} 
          type="number" 
          InputProps={{
            inputProps: { 
                min: 0
            }}}
          onChange={(e) => setFormData({ ...formData, long: e.target.value }) } 
          fullWidth
        />
      </Grid>

      <Grid item xs={4} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
        <TextField value="6000" variant="filled" InputProps={{readOnly: true}} fullWidth/>
      </Grid>
  
      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toPrevTab(e)} fullWidth>
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toNextTab(e)} fullWidth>
          {t("Next")}
        </Button>
      </Grid>
  
        </Grid>
      </form>
    </Box>
  );
  
};

export default Flights;