import * as React from 'react';
import {
  Box,
  Card,
  Grid,
  Divider,
  TextField,
  Button,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import RecommendationListAdmin from "./RecommendationListAdmin"
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

// for link validation
// import { useFormik } from 'formik';
// import * as yup from 'yup';


export default function ScrollableTabsButtonAuto() {
  const { t } = useTranslation();

  const loadedComponentIDlist = [
    "Age",
    "City",
    "Country",
    "Gender",
    "Start Date",
    "End Date",
    "Marital Status",
    "Home - Total Result",
    "Home - Eelctricity",
    "Home - Fuel Amount",
    "Home - Fuel Type",
    "Home - Grid",
    "Home - Solar",
    "Home - Heat Pump",
    "Home - Refrigirator",
    "Home - Washing Machine",
    "Home - Dishwasher",
    "Home - Oven",
    "Cars - Total Result",
    "Cars - Distance",
    "Cars - Electric Car",
    "Cars - Fuel Type",
    "Cars - Litres ",
    "Cars - Public Transportation",
    "Flights - Total Result",
    "Flights - Domestic ",
    "Flights - Long Haul",
    "Flights - Medium Haul",
    "Flights - Short Haul",
    "Trains - Total Result",
    "Trains - Distance",
    "Train - Number of Trips",
    "Waste Management - Total Result",
    "Waste Management - Recycling",
    "Waste Management - Composting"
  ]


  const [cardMap, setCardMap] = useState(new Map())

  const client = axios.create({
    baseURL: "https://co2.dev.wecompair.eu/co2apidev"
  });


  useEffect(() => {
    client.get("/recommendations")
      .then((response) => {
        response.data.forEach(function (recommendation) {
          setCardMap(new Map(cardMap.set(recommendation._id, recommendation)))
        });
      });
  }, []);


  const preLoadedRecommendation = {
    category: "",
    _id: "",
    text: "",
    country: "",
    // links: "",
  }

  const [emptyRecommendation, setEmptyRecommendation] = useState(preLoadedRecommendation)

  const [cancelButton, toggleCancelButton] = useState(false)
  const [removeLastCaseButton, toggleRemoveLastCaseButton] = useState(false)
  const [updateButton, toggleUpdateButton] = useState(false)

  const handleRecommendation = (e) => {
    setEmptyRecommendation(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  // LINK STUFF BEGINS HERE

  const greeceL = new Map()
  // eslint-disable-next-line
  const [greeceLinks, setGreeceLinks] = useState(greeceL)
  // eslint-disable-next-line
  const [greeceLinkCounter, setGreeceLinkCounter] = useState(0)  

  const germanyL = new Map()
  // eslint-disable-next-line
  const [germanyLinks, setGermanyLinks] = useState(germanyL)
  // eslint-disable-next-line
  const [germanyLinkCounter, setGermanyLinkCounter] = useState(0)  

  const belgiumL = new Map()
  // eslint-disable-next-line
  const [belgiumLinks, setBelgiumLinks] = useState(belgiumL)
  // eslint-disable-next-line
  const [belgiumLinkCounter, setBelgiumLinkCounter] = useState(0)  

  const bulgariaL = new Map()
  // eslint-disable-next-line
  const [bulgariaLinks, setBulgariaLinks] = useState(bulgariaL)
  // eslint-disable-next-line
  const [bulgariaLinkCounter, setBulgariaLinkCounter] = useState(0)  

  const [linkCountry, setLinkCountry] = useState(preLoadedRecommendation.country)
  const [removeLastLinkButton, toggleRemoveLastLinkButton] = useState(false)
  // eslint-disable-next-line
  const [linkCounter, setLinkCounter] = useState(1)
  // eslint-disable-next-line
  const [lastCountryLink, setLastCountryLink] = useState("")

  const handleLinkCountry = (e) => {
    const { name, value } = e.target;
    setLinkCountry({ ...linkCountry, [name]: value });
  
    if (updateButton) {
      loadLinks({ ...linkCountry, [name]: value });
    }
  };

  const removeLastGreeceLink = () => {
      let tempLinkMap = new Map(greeceLinks)
      tempLinkMap.delete(greeceLinkCounter)
      setGreeceLinkCounter(greeceLinkCounter - 1)
      setGreeceLinks(tempLinkMap)
  }

  const removeLastGermanyLink = () => {
    let tempLinkMap = new Map(germanyLinks)
    tempLinkMap.delete(germanyLinkCounter)
    setGermanyLinkCounter(germanyLinkCounter - 1)
    setGermanyLinks(tempLinkMap)
  }

  const removeLastBelgiumLink = () => {
    let tempLinkMap = new Map(belgiumLinks)
    tempLinkMap.delete(belgiumLinkCounter)
    setBelgiumLinkCounter(belgiumLinkCounter - 1)
    setBelgiumLinks(tempLinkMap)
  }

  const removeLastBulgariaLink = () => {
    let tempLinkMap = new Map(bulgariaLinks)
    tempLinkMap.delete(bulgariaLinkCounter)
    setBulgariaLinkCounter(bulgariaLinkCounter - 1)
    setBulgariaLinks(tempLinkMap)
  }

  const clearAllLinks = () => {

    setGreeceLinks(new Map())
    setGreeceLinkCounter(0)

    setGermanyLinks(new Map())
    setGermanyLinkCounter(0)

    setBelgiumLinks(new Map())
    setBelgiumLinkCounter(0)

    setBulgariaLinks(new Map())
    setBulgariaLinkCounter(0)
  }

  const loadLinkField = () => {

    let tempLinkList = []
    if (linkCountry.linkCountry === "greece") {
          // eslint-disable-next-line
      greeceLinks.forEach(function (currentLink, key) {

        tempLinkList.push(
          <>
            <Box>
              <Grid item xs={10}>
                {/* <Tooltip title="Please enter the text for your new recommendation."> */}
                <TextField 
                  label={t('Link')}
                  variant="standard"
                  name="link"
                  id={key}
                  value={currentLink.link}
                  onChange={(e) => { handleLink(key, e.target.value) }}
                />
                {/* </Tooltip> */}
              </Grid>
            </Box>
          </>
        )
      })
    }

    if (linkCountry.linkCountry ===  "germany") {
      // eslint-disable-next-line
      germanyLinks.forEach(function (currentLink, key) {

        tempLinkList.push(
          <>
            <Box>
              <Grid item xs={10}>
                {/* <Tooltip title="Please enter the text for your new recommendation."> */}
                <TextField 
                  label={t('Link')}
                  variant="standard"
                  name="link"
                  id={key}
                  value={germanyLinks.get(key).link}
                  onChange={(e) => { handleLink(key, e.target.value) }}
                />
                {/* </Tooltip> */}
              </Grid>
            </Box>
          </>
        )
      })
    }

    if (linkCountry.linkCountry === "belgium") {
      // eslint-disable-next-line
      belgiumLinks.forEach(function (currentLink, key) {

        tempLinkList.push(
          <>
            <Box>
              <Grid item xs={10}>
                {/* <Tooltip title="Please enter the text for your new recommendation."> */}
                <TextField 
                  label={t('Link')}
                  variant="standard"
                  name="link"
                  id={key}
                  value={belgiumLinks.get(key).link}
                  onChange={(e) => { handleLink(key, e.target.value) }}
                />
                {/* </Tooltip> */}
              </Grid>
            </Box>
          </>
        )
      })
    }    

    if (linkCountry.linkCountry === "bulgaria") {
      // eslint-disable-next-line
      bulgariaLinks.forEach(function (currentLink, key) {

        tempLinkList.push(
          <>
            <Box>
              <Grid item xs={10}>
                {/* <Tooltip title="Please enter the text for your new recommendation."> */}
                <TextField 
                  label={t('Link')}
                  variant="standard"
                  name="link"
                  id={key}
                  value={bulgariaLinks.get(key).link}
                  onChange={(e) => { handleLink(key, e.target.value) }}
                />
                {/* </Tooltip> */}
              </Grid>
            </Box>
          </>
        )
      })
    }   


    return tempLinkList

  }

  const addLink = () => {

    if (linkCountry.linkCountry === "greece") {
      const newLink = { link: "" };
      const newMap = new Map(greeceLinks); // make a copy of the original map
      newMap.set(greeceLinkCounter + 1, newLink); // add the new link to the copied map
      setGreeceLinks(newMap); // set the state to the new map
      setGreeceLinkCounter(greeceLinkCounter + 1); // update the link counter
    }

    if (linkCountry.linkCountry === "germany") {
      const newLink = { link: "" };
      const newMap = new Map(germanyLinks); // make a copy of the original map
      newMap.set(germanyLinkCounter + 1, newLink); // add the new link to the copied map
      setGermanyLinks(newMap); // set the state to the new map
      setGermanyLinkCounter(germanyLinkCounter + 1); // update the link counter
    }

    if (linkCountry.linkCountry === "belgium") {
      const newLink = { link: "" };
      const newMap = new Map(belgiumLinks); // make a copy of the original map
      newMap.set(belgiumLinkCounter + 1, newLink); // add the new link to the copied map
      setBelgiumLinks(newMap); // set the state to the new map
      setBelgiumLinkCounter(belgiumLinkCounter + 1); // update the link counter
    }

    if (linkCountry.linkCountry === "bulgaria") {
      const newLink = { link: "" };
      const newMap = new Map(bulgariaLinks); // make a copy of the original map
      newMap.set(bulgariaLinkCounter + 1, newLink); // add the new link to the copied map
      setBulgariaLinks(newMap); // set the state to the new map
      setBulgariaLinkCounter(bulgariaLinkCounter + 1); // update the link counter
    }

    setLastCountryLink(linkCountry.linkCountry)
    toggleRemoveLastLinkButton(true)
  }

// eslint-disable-next-line
const loadLinks = (country) => {
  loadLinkField()
}


  const handleLink = (key, value) => {
    if (linkCountry.linkCountry === "greece") {
      setGreeceLinks(new Map(greeceLinks.set(key, { link: value })));
    } else if (linkCountry.linkCountry === "germany") {
      setGermanyLinks(new Map(germanyLinks.set(key, { link: value })));
    } else if (linkCountry.linkCountry === "belgium") {
      setBelgiumLinks(new Map(belgiumLinks.set(key, { link: value })));
    } else if (linkCountry.linkCountry === "bulgaria") {
      setBulgariaLinks(new Map(bulgariaLinks.set(key, { link: value })));
    }
  };

// LINK STUFF ENDS HERE

  const deleteRecommendation = async (recommendationID) => {

    await client.delete("/recommendations/" + recommendationID)
    // eslint-disable-next-line
      .then((response) => {
      });
    await client.get("/recommendations")
      .then((response) => {
        response.data.forEach(function (recommendation) {
          setCardMap(new Map(cardMap.set(recommendation._id, recommendation)))

        });
      });
  }
  const [caseCounter, setCaseCounter] = useState(1)


  const preLoadedMap = new Map()
  preLoadedMap.set("1", {
    caseID: "",
    neededValueID: "",
    operator: "",
    neededValue: ""
  })

  const [caseMap, setCaseMap] = useState(preLoadedMap)

  const removeLastCase = () => {
    let tempMap = new Map(caseMap)
    tempMap.delete(caseCounter)
    setCaseCounter(caseCounter - 1)
    setCaseMap(tempMap)
  }

  const clearAllFields = () => {
    setCaseMap(preLoadedMap)
    setEmptyRecommendation(preLoadedRecommendation)
    setCaseCounter(1)

  }


  const loadAction = (recommendationID) => {

    let currentRecommendation = cardMap.get(recommendationID)

    setEmptyRecommendation({
      category: currentRecommendation.category,
      _id: currentRecommendation._id,
      text: currentRecommendation.text
    }
    )

    let newCaseMap = new Map()

    let action = cardMap.get(recommendationID)

    // links 
      if (action.links["greece"]) {
        const tempLinkMap = new Map();
        let countryLinks = action.links["greece"];

        countryLinks.forEach(function (newLinkValue, key) {
          tempLinkMap.set(key, { link: newLinkValue });
        });

        let numEntries = Object.keys(countryLinks).length;
        setGreeceLinks(tempLinkMap)
        setGreeceLinkCounter(numEntries)
      } 

      if (action.links["germany"]) {
        const tempLinkMap = new Map();
        let countryLinks = action.links["germany"];

        countryLinks.forEach(function (newLinkValue, key) {
          tempLinkMap.set(key, { link: newLinkValue });
        });

        let numEntries = Object.keys(countryLinks).length;
        setGermanyLinks(tempLinkMap)
        setGermanyLinkCounter(numEntries)
      } 

      if (action.links["belgium"]) {
        const tempLinkMap = new Map();
        let countryLinks = action.links["belgium"];

        countryLinks.forEach(function (newLinkValue, key) {
          tempLinkMap.set(key, { link: newLinkValue });
        });

        let numEntries = Object.keys(countryLinks).length;
        setBelgiumLinks(tempLinkMap)
        setBelgiumLinkCounter(numEntries)
      } 

      if (action.links["bulgaria"]) {
        const tempLinkMap = new Map();
        let countryLinks = action.links["bulgaria"];

        countryLinks.forEach(function (newLinkValue, key) {
          tempLinkMap.set(key, { link: newLinkValue });
        });
        
        let numEntries = Object.keys(countryLinks).length;
        setBulgariaLinks(tempLinkMap)
        setBulgariaLinkCounter(numEntries)
      } 

    // links

    action.cases.map(({ operator, neededValue, neededValueID, caseID }) => {
      setCaseMap(new Map(newCaseMap.set(caseID, {
        caseID: caseID,
        neededValueID: neededValueID,
        operator: operator,
        neededValue: neededValue
      })))
      return true
    })

  }


  const updateRecommendation = () => {

    let recommendation = emptyRecommendation
    let cases = []

    caseMap.forEach(function (currentCase) {
      cases.push(currentCase)
    })

    const result = {
      greece: [],
      germany: [],
      belgium: [],
      bulgaria: [],
    };
    // eslint-disable-next-line
    for (const [key, value] of greeceLinks.entries()) {
      result.greece.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of germanyLinks.entries()) {
      result.germany.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of belgiumLinks.entries()) {
      result.belgium.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of bulgariaLinks.entries()) {
      result.bulgaria.push(value.link);
    }

    client.patch("/recommendations/" + recommendation._id, {
      category: recommendation.category,
      text: recommendation.text,
      cases: cases,
      links: result
    // eslint-disable-next-line
    }).then((response) => {
    })

    client.get("/recommendations")
      .then((response) => {
        response.data.forEach(function (recommendation) {
          setCardMap(new Map(cardMap.set(recommendation._id, recommendation)))
        });
      });


    clearAllFields()
    clearAllLinks()

  }


  const addRecommendation = async () => {

    let recommendation = emptyRecommendation
    let cases = []

    caseMap.forEach(function (currentCase) {
      cases.push(currentCase)
    })

    const result = {
      greece: [],
      germany: [],
      belgium: [],
      bulgaria: [],
    };
    // eslint-disable-next-line
    for (const [key, value] of greeceLinks.entries()) {
      result.greece.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of germanyLinks.entries()) {
      result.germany.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of belgiumLinks.entries()) {
      result.belgium.push(value.link);
    }
    // eslint-disable-next-line
    for (const [key, value] of bulgariaLinks.entries()) {
      result.bulgaria.push(value.link);
    }

    await client.post("/recommendations", {
      category: recommendation.category,
      text: recommendation.text,
      cases: cases,
      links: result
    })
      

    await client.get("/recommendations")
      .then((response) => {
        response.data.forEach(function (recommendation) {
          setCardMap(new Map(cardMap.set(recommendation._id, recommendation)))
        });
      });

    clearAllFields()
    clearAllLinks()
  }


  const loadComponentIDs = () => {
    let componentIDsList = []

    loadedComponentIDlist.forEach(function (component) {
      componentIDsList.push(

        <MenuItem value={component}>{component}</MenuItem>

      )
    })
    return componentIDsList
  }


  const addCase = () => {

    let tempid = caseCounter + 1
    setCaseCounter(tempid)

    setCaseMap(new Map(caseMap.set(tempid, {
      caseID: "",
      neededValueID: "",
      operator: "",
      neededValue: ""
    })))

    toggleRemoveLastCaseButton(true)

  }

  const handleCase = (key, caseValue, value) => {

    let tempcase = caseMap.get(key)

    switch (caseValue) {

      case "caseID":
        setCaseMap(new Map(caseMap.set(key, {
          caseID: value,
          neededValueID: tempcase.neededValueID,
          operator: tempcase.operator,
          neededValue: tempcase.neededValue
        })))
        break

      case "neededValueID":
        setCaseMap(new Map(caseMap.set(key, {
          caseID: tempcase.caseID,
          neededValueID: value,
          operator: tempcase.operator,
          neededValue: tempcase.neededValue
        })))
        break
      case "operator":
        setCaseMap(new Map(caseMap.set(key, {
          caseID: tempcase.caseID,
          neededValueID: tempcase.neededValueID,
          operator: value,
          neededValue: tempcase.neededValue
        })))
        break
      case "neededValue":
        setCaseMap(new Map(caseMap.set(key, {
          caseID: tempcase.caseID,
          neededValueID: tempcase.neededValueID,
          operator: tempcase.operator,
          neededValue: value
        })))
        break
      default:
    }

  }


  const loadCaseFields = () => {

    let tempCaseList = []

    caseMap.forEach(function (currentCase, key) {

      tempCaseList.push(
        <>
          <Box>
            <TextField
              label="caseID"
              variant="standard"
              name="caseID"
              id={key}
              value={caseMap.get(key).caseID}
              onChange={(e) => { handleCase(key, "caseID", e.target.value) }}
            />
          </Box>
          <Box>
            <FormControl variant="standard" sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-standard-label">ComponentID</InputLabel>
              <Select
                labelId="neededValueID"
                id={key}
                name="neededValueIDd"
                value={caseMap.get(key).neededValueID}
                onChange={(e) => { handleCase(key, "neededValueID", e.target.value) }}
                label="neededValueID"
              >
                {loadComponentIDs()}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-standard-label"> {t('Operator')} </InputLabel>
              <Select
                labelId="operator"
                id={key}
                name="operator"
                value={caseMap.get(key).operator}
                onChange={(e) => { handleCase(key, "operator", e.target.value) }}
                label="operator"
              >
                <MenuItem value={"="}>=</MenuItem>
                <MenuItem value={">"}> {">"} </MenuItem>
                <MenuItem value={"<"}> {"<"} </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>

            <TextField
              label={t('Value')}
              variant="standard"
              name="neededValue"
              id={key}
              value={caseMap.get(key).neededValue}
              onChange={(e) => { handleCase(key, "neededValue", e.target.value) }}
            />
          </Box>
        </>
      )
    })

    return tempCaseList

  }


  

  return (
    <>
      <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4} >

        <Grid item xs={12} sm={4}>

          <RecommendationListAdmin cardMap={cardMap} deleteRecommendation={deleteRecommendation} toggleCancelButton={toggleCancelButton} toggleUpdateButton={toggleUpdateButton} loadAction={loadAction} />

        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4} >

              <Grid item xs={10}>
                <Box>
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label"> {t('Category')} </InputLabel>
                    <Select
                      label="Recommendation Category"
                      variant="standard"
                      name="category"
                      value={emptyRecommendation.category}
                      onChange={(e) => { handleRecommendation(e) }}
                    >
                      <MenuItem value={"Transportation"}> {t('Transportation')} </MenuItem>
                      <MenuItem value={"Flights"}> {t('Flights')} </MenuItem>
                      <MenuItem value={"Trains"}> {t('Trains')} </MenuItem>
                      <MenuItem value={"Buildings"}> {t('Buildings')} </MenuItem>
                      <MenuItem value={"Waste"}> {t('Waste')} </MenuItem>

                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={10}>
                <Tooltip title="Please enter the text for your new recommendation.">
                    <TextField id="standard-basic"
                      label={t('Recommendation Text')}
                      variant="standard"
                      name="text"
                      value={emptyRecommendation.text}
                      onChange={(e) => { handleRecommendation(e) }}
                    />
                  </Tooltip>
              </Grid>

              <Grid item xs={10}>

                {loadCaseFields()}
                <Divider />

                {(cancelButton) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        toggleCancelButton(false)
                        clearAllFields()
                        clearAllLinks()
                        toggleUpdateButton(false)
                      }}          >
                      {t('Cancel')}
                    </Button>
                  </>
                }

                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    loadCaseFields()
                    addCase()
                    toggleCancelButton(true)
                  }}          >
                  {t('Add Case')}
                </Button>

                {(removeLastCaseButton) && (caseCounter > 1) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        removeLastCase()
                      }}          >
                      {t('Remove Last Case')}
                    </Button>
                  </>
                }

                {(updateButton) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        updateRecommendation("lol")
                        toggleUpdateButton(false)
                        toggleCancelButton(false)

                      }}          >
                      {t('Update')}
                    </Button>
                  </>
                }

                {!(updateButton) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        addRecommendation()
                      }}          >
                      {t('Add Recommendation')}
                    </Button>
                  </>
                }

              </Grid>

            </Grid>

          </Card>

        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <Card>
              <Grid container justifyContent="center" spacing={2} marginTop={1} marginBottom={4} >

                <Grid item xs={10}>
                  <Box>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label"> {t('Country')} </InputLabel>
                      <Select
                        label="Link Country"
                        variant="standard"
                        name="linkCountry"
                        value={linkCountry.country}
                        onChange={(e) => { handleLinkCountry(e) }}
                      >
                        <MenuItem value={"belgium"}> {t('Belgium')} </MenuItem>
                        <MenuItem value={"bulgaria"}> {t('Bulgaria')} </MenuItem>
                        <MenuItem value={"germany"}> {t('Germany')} </MenuItem>
                        <MenuItem value={"greece"}> {t('Greece')} </MenuItem>

                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                  <Grid item xs={10}>
                  

                      {/* <Tooltip title="Please enter the text for your new recommendation."> */}
                      {/* <TextField 
                        // id="standard-basic"
                        // label="Link"
                        // variant="standard"
                        // name="link"
                        // value={emptyRecommendation.text}
                        // onChange={(e) => { handleRecommendation(e) }}
                        id="link"
                        name="link"
                        label="Link"
                        variant="outlined"
                        fullWidth
                        value={formik.values.link}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.link && Boolean(formik.errors.link)}
                        helperText={formik.touched.link && formik.errors.link}
                      /> */}
                      {/* </Tooltip> */}

                  </Grid>

                  <Grid item xs={10}>

                {loadLinkField()}
                <Divider />

                {/* {(cancelButton) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        toggleCancelButton(false)
                        clearAllFields()
                        toggleUpdateButton(false)
                      }}          >
                      {t('Cancel')}
                    </Button>
                  </>
                } */}



                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    loadLinkField()
                    addLink()
                    // addCase()
                    // toggleCancelButton(true)
                  }}          >
                  {t('Add Link')}
                </Button>

                {(removeLastLinkButton) && ( (greeceLinkCounter > 1) && (linkCountry.linkCountry === "greece") ) &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        removeLastGreeceLink()
                      }}          >
                      {t('Remove Last Link')}
                    </Button>
                  </>
                }
                {(removeLastLinkButton) &&  (germanyLinkCounter > 1)  && (linkCountry.linkCountry === "germany") &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        removeLastGermanyLink()
                      }}          >
                      {t('Remove Last Link')}
                    </Button>
                  </>
                }
                {(removeLastLinkButton) &&  (belgiumLinkCounter > 1)  && (linkCountry.linkCountry === "belgium") &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        removeLastBelgiumLink()
                      }}          >
                      {t('Remove Last Link')}
                    </Button>
                  </>
                }
                {(removeLastLinkButton) &&  (bulgariaLinkCounter > 1)  && (linkCountry.linkCountry === "bulgaria") &&
                  <>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        removeLastBulgariaLink()
                      }}          >
                      {t('Remove Last Link')}
                    </Button>
                  </>
                }

              </Grid>


              </Grid>
            </Card>
          </Box>
        </Grid>



      </Grid>
    </>
  );

}